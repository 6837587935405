import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;

@Component({
  selector: 'app-alta-colaborador',
  templateUrl: './alta-colaborador.component.html'
})
export class AltaColaboradorComponent implements OnInit {
  public titulo:string = 'Alta de Colaborador';
  public key:string = this.globals.GetQueryString('key');
  public anio:string = moment().format('YYYY');
  public data:any = {id_ubicacion: 1};
  public enviado:any = false;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    if (!this.key || this.key == '' || this.key != 'zLety19DH3rl') {
      this.ngZone.run(() => this.router.navigateByUrl('/')).then();
      return;
    }
    this.title.setTitle(this.titulo);
    this.meta.updateTag({name: 'description', content: this.titulo});
    if (this.globals.GetQueryString('lang')) {
      this.globals.idioma = this.globals.GetQueryString('lang');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
      this.titulo = this.globals.Literal('Alta de Colaborador');
      this.title.setTitle(this.titulo);
    } else {
      this.globals.idioma = localStorage.getItem('idioma');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
      this.titulo = this.globals.Literal('Alta de Colaborador');
      this.title.setTitle(this.titulo);
    }
  }
  DatosLocalidad(event:any = null, idtxt:string = null) {
    let codpostal = '';
    codpostal = (event ? event.target.value : this.data.codpostal);
    if (!codpostal || codpostal == '') return;
    this.globals.Api('/localidad', {codpostal: codpostal}).subscribe(data => {
      if (!data || !data.localidad) {
        this.data.localidad = '';
        this.data.provincia = '';
        this.appref.tick();
        return;
      }
      if (idtxt && idtxt != '') {
        $('#'+idtxt).html(data.localidad+', '+data.provincia);
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
      this.appref.tick();
    });
  }
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.data.img = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar logo?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    }, () => {
      delete this.data.img;
      delete this.data.imagen;
    });
  }
  EnviarSolicitud() {
    if (
      !this.data.empresa
      || this.data.empresa == ''
      || !this.data.nombre
      || this.data.nombre == ''
      || !this.data.apellidos
      || this.data.apellidos == ''
      || !this.data.img
      || this.data.img == ''
      || !this.data.codpostal
      || this.data.codpostal == ''
      || !this.data.localidad
      || this.data.localidad == ''
      || !this.data.provincia
      || this.data.provincia == ''
      || !this.data.telefono
      || this.data.telefono == ''
      || !this.data.email
      || this.data.email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    // if (!this.data.email || this.data.email == '') this.data.email = this.globals.GenerateRandomString(6).toLowerCase();
    // this.data.clave = ' ';
    this.data.acceso = 0;
    this.data.id_rol = 4;

    $.buttonloading('btn-enviar-solicitud', 'Enviando');
    this.globals.Api('/crear-usuario', this.data).subscribe(data => {
      $.buttoncancelloading('btn-enviar-solicitud');
      if (!data || data.error) {
        if (data.value == 'Email repetido') {
          swal('Email existente', 'Existe un colaborador con este email', 'error');return;
        }
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.enviado = true;
    }, error => {
      $.buttoncancelloading('btn-enviar-solicitud');
    });
  }

}
