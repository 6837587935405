<div class="top-navigation">
    <div id="wrapper">
      <div id="page-wrapper" class="gray-bg">
        
        <app-cabecera></app-cabecera>

        <div class="wrapper wrapper-content animated fadeInDown" *ngIf="id_colaborador && id_colaborador != ''">
          <div class="container" *ngIf="!disponible">
            <h2 class="font-bold">La reserva previa de actividades está desactivada temporalmente.</h2>
          </div>
          <div class="container" *ngIf="disponible">
            <h2 class="font-bold">{{globals.Literal('Actividades')}}</h2>
            <p>{{globals.Literal('Selecciona tu actividad')}}</p>
            <div class="row m-t-30">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let item of actividades" (click)="IrActividad(item)">
                  <div class="actividad-card {{item.pasado ? 'actividad-no-disponible-vip' : null}}" [style.background-image]="item.imagen ? 'url('+item.imagen+')' : null">
                    <div class="fecha">
                      <div>{{item.fecha_mes}} {{item.fecha_dia}}</div>
                    </div>
                    <div class="actividad-card-body">
                      <h2 [innerHTML]="item.titulo" class="titulo" style="min-height: 58px;"></h2>
                      <div>{{item.categoria}}</div>
                      <div><i class="icofont-google-map text-white"></i> {{item.ubicacion}}</div>
                      <div><i class="fa fa-user"></i> {{globals.Literal('Aforo')}}: {{item.aforo}} {{globals.Literal('personas')}}</div>
                      <div class="row">
                        <div class="col-sm-12 col-xs-12">
                          <div class="btn-reservar btn-publica" *ngIf="!item.disponible_vip">
                            {{globals.Literal('Reservar')}}
                          </div>
                          <div class="btn-reservar" *ngIf="item.disponible_vip">
                            {{globals.Literal('Reserva anticipada')}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="wrapper wrapper-content animated fadeInDown text-center" *ngIf="!id_colaborador || id_colaborador == ''">
          <h2>{{globals.Literal('Error')}}</h2>
        </div>
    
      </div>
    </div>
</div>