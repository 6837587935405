import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { SwPush, SwUpdate } from '@angular/service-worker';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let Chart: any;
declare let XLSX: any;
declare let saveAs: any;
declare let jsPDF: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  readonly VAPID_PUBLIC_KEY = "BITIPUQahyLBb46ugyg_3eOwMJztBie1jxfiXkTvsS8y6-YgOC6cXNvnl5tU_MvCXwxoERtPOcF-l_0kGctt-gI";
  public datos = null;
  public cargandomapalocalidades:boolean = true;
  public localizaciones = [];
  public localizaciones_monfrague = [];
  public localizaciones_tajo = [];
  public cargandomapalocalidadespt:boolean = true;
  public localizaciones_pt = [];
  public localizaciones_monfrague_pt = [];
  public localizaciones_tajo_pt = [];
  public colaborador:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    public swPush: SwPush,
    public swUpdate: SwUpdate
  ) { }

  ngOnInit() {
    $.ComprobarLicencia();
    if (localStorage.getItem('token') == null) {
      this.ngZone.run(() => this.router.navigateByUrl('/')).then();
      return;
    }
    $('body').removeClass('bg-body-login');
    $.btnmenu();
    $.disableinputnumberwheel();
    switch(this.globals.me.id_rol) {
      case 0:
      case 1:
      case 2:
        if (this.globals.config && this.globals.config.tipoapp == 'actividades') {
          this.globals.Api('/act-datos-generales').subscribe(data => {
            if (!data) return;
            this.datos = data;
            if (this.datos.aforo_disponible && parseInt(this.datos.aforo_disponible) < 0) this.datos.aforo_disponible = '0';
            if (this.datos.aforo_disponible_monfrague && parseInt(this.datos.aforo_disponible_monfrague) < 0) this.datos.aforo_disponible_monfrague = '0';
            if (this.datos.aforo_disponible_tajo && parseInt(this.datos.aforo_disponible_tajo) < 0) this.datos.aforo_disponible_tajo = '0';
            this.CargarCharts();
          });
        }
      break;
      case 3:
      break;
    };
    for (let i = 0; i < 50; i++) {
      clearInterval(i);
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          // unregister service worker
          console.log('serviceWorker unregistered');
          registration.unregister();
        }
      });
    }
    if (this.globals.me && this.globals.me.id && [4].includes(this.globals.me.id_rol)) {
      this.globals.Api('/usuarios', {id_rol: 4, id: this.globals.me.id}).subscribe((data:any) => {
        if (!data || !data.data || !data.data.length) return;
        this.colaborador = data.data[0];
        if (this.colaborador.email.toString().indexOf('@') == -1) this.colaborador.email = null;
        let url = 'https://app.mesdelareservabiosfera.es/checkpoint?v='+this.colaborador.id;
        this.colaborador.qr = 'https://quickchart.io/chart?cht=qr&chs=220x220&chld=M%7C0&chl='+url;
      });
    }
  }

  CargarCharts() {
    $('#chart-reservas').parent().html(`<canvas id="chart-reservas" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>`);
    $('#chart-aforo').parent().html(`<canvas id="chart-aforo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>`);
    $('#chart-mapa').parent().html(`<div id="chart-mapa" style="height:230px;"></div>`);
    $('#chart-localizaciones').parent().html(`<div id="chart-localizaciones" style="height:300px;"></div>`);
    setTimeout(() => {
      switch(this.globals.me.id_rol) {
        case 0:
        case 1:
          var mapData = {
              "ES-CC": this.datos.total_caceres,
              "ES-BA": this.datos.total_badajoz
          };
          $.mapageneral = $('#chart-mapa').vectorMap({
              map: 'es_merc',
              focusOn: {
                lat: 39.2,
                lng: -6.155078983139426,
                scale: 5,
                animate: true
              },
              backgroundColor: "transparent",
              regionStyle: {
                  initial: {
                      fill: '#e4e4e4',
                      "fill-opacity": 0.9,
                      stroke: 'none',
                      "stroke-width": 0,
                      "stroke-opacity": 0
                  }
              },
              series: {
                  regions: [{
                      values: mapData,
                      scale: ["#5BB0E3", "#94C11E"],
                      normalizeFunction: 'polynomial'
                  }]
              },
              onRegionTipShow: function(e, el, code){
                if (!mapData || !mapData[code]) return;
                el.html(el.html()+' ('+mapData[code]+' Personas)');
              }
          });

          var doughnutData = {
            labels: ["Bajas", "En Espera", "Confirmadas"],
            datasets: [{
                data: [this.datos.total_bajas, this.datos.enespera, this.datos.confirmadas],
                backgroundColor: ["#ed5565", "#f8ac59", "#94C11E"]
            }]
          };
          var doughnutOptions = {
              responsive: true,
              legend: {
                  display: true
              }
          };
          if ($("#chart-reservas").length) {
            var ctx4 = $("#chart-reservas")[0].getContext("2d");
            new Chart(ctx4, {type: 'doughnut', data: doughnutData, options:doughnutOptions});
          }

          var doughnutData = {
            labels: ["Inscripciones", "Disponible"],
            datasets: [{
                data: [this.datos.total_inscripciones, this.datos.aforo_disponible],
                backgroundColor: ["#e0e0e0", "#23c6c8"]
            }]
          };
          var doughnutOptions = {
              responsive: true,
              legend: {
                  display: true
              }
          };
          if ($("#chart-aforo").length) {
            var ctx4 = $("#chart-aforo")[0].getContext("2d");
            new Chart(ctx4, {type: 'doughnut', data: doughnutData, optexions:doughnutOptions});
          }

          this.cargandomapalocalidades = true;
          this.globals.Api('/act-datos-localizacion', {geodata: true}).subscribe(data => {
            if (!data || !data.length) {
              this.cargandomapalocalidades = false;
              $('#chart-localizaciones').html('No disponemos de datos aún');
              return;
            }
            var map,
                markers = [],
                cityAreaData = [];
            for (let item of data) {
              markers.push({
                latLng: [item.lat, item.lon],
                name: item.localidad
              });
              cityAreaData.push(item.total);
            }
            $.mapalocalizaciones = $('#chart-localizaciones').vectorMap({
              map: 'es_merc',
              focusOn: {
                lat: 39.2,
                lng: -6.155078983139426,
                scale: 7,
                animate: true
              },
              markersSelectable: true,
              markers: markers,
              markerStyle: {
                initial: {
                  fill: '#94C11E'
                },
                selected: {
                  fill: '#6e9605'
                }
              },
              backgroundColor: "transparent",
              regionStyle: {
                initial: {
                    fill: '#e4e4e4',
                    "fill-opacity": 0.9,
                    stroke: 'none',
                    "stroke-width": 0,
                    "stroke-opacity": 0
                }
              },
              series: {
                markers: [{
                  attribute: 'r',
                  scale: [5, 15],
                  values: cityAreaData
                }]
              },
              onMarkerTipShow: function(e, el, code){
                if (!cityAreaData || !cityAreaData[code]) return;
                el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
              }
            });
            this.cargandomapalocalidades = false;
          }, error => {
            this.cargandomapalocalidades = false;
          });
          this.globals.Api('/act-datos-localizacion').subscribe(data => {
            this.localizaciones = data;
          });

          this.cargandomapalocalidadespt = true;
          this.globals.Api('/act-datos-localizacion', {geodata: true, portugal: true}).subscribe(data => {
            if (!data || !data.length) {
              this.cargandomapalocalidadespt = false;
              $('#chart-localizaciones-pt').html('No disponemos de datos aún');
              return;
            }
            var map,
                markers = [],
                cityAreaData = [];
            for (let item of data) {
              markers.push({
                latLng: [item.lat, item.lon],
                name: item.localidad
              });
              cityAreaData.push(item.total);
            }
            $.mapalocalizaciones_pt = $('#chart-localizaciones-pt').vectorMap({
              map: 'pt_merc',
              focusOn: {
                lat: 39.5651600420688,
                lng: -8.020377737364566,
                scale: 1,
                animate: true
              },
              markersSelectable: true,
              markers: markers,
              markerStyle: {
                initial: {
                  fill: '#94C11E'
                },
                selected: {
                  fill: '#6e9605'
                }
              },
              backgroundColor: "transparent",
              regionStyle: {
                initial: {
                    fill: '#e4e4e4',
                    "fill-opacity": 0.9,
                    stroke: 'none',
                    "stroke-width": 0,
                    "stroke-opacity": 0
                }
              },
              series: {
                markers: [{
                  attribute: 'r',
                  scale: [5, 15],
                  values: cityAreaData
                }]
              },
              onMarkerTipShow: function(e, el, code){
                if (!cityAreaData || !cityAreaData[code]) return;
                el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
              }
            });
            this.cargandomapalocalidadespt = false;
          }, error => {
            this.cargandomapalocalidadespt = false;
          });
          this.globals.Api('/act-datos-localizacion', {portugal: true}).subscribe(data => {
            this.localizaciones_pt = data;
          });

          this.CargarChartsMonfrague();
          this.CargarChartsTajo();
        break;
        case 2:
          var mapData = {
              "ES-CC": this.datos.total_caceres,
              "ES-BA": this.datos.total_badajoz
          };
          $.mapageneral = $('#chart-mapa').vectorMap({
              map: 'es_merc',
              focusOn: {
                x: 0.53,
                y: 0.31,
                scale: 5
              },
              backgroundColor: "transparent",
              regionStyle: {
                  initial: {
                      fill: '#e4e4e4',
                      "fill-opacity": 0.9,
                      stroke: 'none',
                      "stroke-width": 0,
                      "stroke-opacity": 0
                  }
              },
              series: {
                  regions: [{
                      values: mapData,
                      scale: ["#5BB0E3", "#94C11E"],
                      normalizeFunction: 'polynomial'
                  }]
              },
              onRegionTipShow: function(e, el, code){
                if (!mapData || !mapData[code]) return;
                el.html(el.html()+' ('+mapData[code]+' Personas)');
              }
          });

          var doughnutData = {
            labels: ["Bajas", "En Espera", "Confirmadas"],
            datasets: [{
                data: [this.datos.total_bajas, this.datos.enespera, this.datos.confirmadas],
                backgroundColor: ["#ed5565", "#f8ac59", "#94C11E"]
            }]
          };
          var doughnutOptions = {
              responsive: true,
              legend: {
                  display: true
              }
          };
          if ($("#chart-reservas").length) {
            var ctx4 = $("#chart-reservas")[0].getContext("2d");
            new Chart(ctx4, {type: 'doughnut', data: doughnutData, options:doughnutOptions});
          }

          var doughnutData = {
            labels: ["Inscripciones", "Disponible"],
            datasets: [{
                data: [this.datos.total_inscripciones, this.datos.aforo_disponible],
                backgroundColor: ["#e0e0e0", "#23c6c8"]
            }]
          };
          var doughnutOptions = {
              responsive: true,
              legend: {
                  display: true
              }
          };
          if ($("#chart-aforo").length) {
            var ctx4 = $("#chart-aforo")[0].getContext("2d");
            new Chart(ctx4, {type: 'doughnut', data: doughnutData, optexions:doughnutOptions});
          }

          this.cargandomapalocalidades = true;
          this.globals.Api('/act-datos-localizacion', {geodata: true}).subscribe(data => {
            if (!data || !data.length) return;
            var map,
                markers = [],
                cityAreaData = [];
            for (let item of data) {
              markers.push({
                latLng: [item.lat, item.lon],
                name: item.localidad
              });
              cityAreaData.push(item.total);
            }
            $.mapalocalizaciones = $('#chart-localizaciones').vectorMap({
              map: 'es_merc',
              focusOn: {
                x: 0.7,
                y: 0.1,
                scale: 2
              },
              markersSelectable: true,
              markers: markers,
              markerStyle: {
                initial: {
                  fill: '#94C11E'
                },
                selected: {
                  fill: '#6e9605'
                }
              },
              backgroundColor: "transparent",
              regionStyle: {
                initial: {
                    fill: '#e4e4e4',
                    "fill-opacity": 0.9,
                    stroke: 'none',
                    "stroke-width": 0,
                    "stroke-opacity": 0
                }
              },
              series: {
                markers: [{
                  attribute: 'r',
                  scale: [5, 15],
                  values: cityAreaData
                }]
              },
              onMarkerTipShow: function(e, el, code){
                if (!cityAreaData || !cityAreaData[code]) return;
                el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
              }
            });
            this.cargandomapalocalidades = false;
          }, error => {
            this.cargandomapalocalidades = false;
          });
          this.globals.Api('/act-datos-localizacion').subscribe(data => {
            this.localizaciones = data;
          });
        break;
      };
    }, 300);
  }
  CargarChartsMonfrague() {
    $('#chart-reservas-monfrague').parent().html(`<canvas id="chart-reservas-monfrague" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>`);
    $('#chart-aforo-monfrague').parent().html(`<canvas id="chart-aforo-monfrague" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>`);
    $('#chart-mapa-monfrague').parent().html(`<div id="chart-mapa-monfrague" style="height:230px;"></div>`);
    $('#chart-localizaciones-monfrague').parent().html(`<div id="chart-localizaciones-monfrague" style="height:300px;"></div>`);
    var mapData = {
      "ES-CC": this.datos.total_caceres_monfrague,
      "ES-BA": this.datos.total_badajoz_monfrague
    };
    $.mapageneral_monfrague = $('#chart-mapa-monfrague').vectorMap({
        map: 'es_merc',
        focusOn: {
          lat: 39.2,
          lng: -6.155078983139426,
          scale: 5,
          animate: true
        },
        backgroundColor: "transparent",
        regionStyle: {
            initial: {
                fill: '#e4e4e4',
                "fill-opacity": 0.9,
                stroke: 'none',
                "stroke-width": 0,
                "stroke-opacity": 0
            }
        },
        series: {
            regions: [{
                values: mapData,
                scale: ["#5BB0E3", "#94C11E"],
                normalizeFunction: 'polynomial'
            }]
        },
        onRegionTipShow: function(e, el, code){
          if (!mapData || !mapData[code]) return;
          el.html(el.html()+' ('+mapData[code]+' Personas)');
        }
    });

    var doughnutData = {
      labels: ["Bajas", "En Espera", "Confirmadas"],
      datasets: [{
          data: [this.datos.total_bajas, this.datos.enespera, this.datos.confirmadas],
          backgroundColor: ["#e0e0e0", "#f8ac59", "#94C11E"]
      }]
    };
    var doughnutOptions = {
        responsive: true,
        legend: {
            display: true
        }
    };
    if ($("#chart-reservas-monfrague").length) {
      var ctx4 = $("#chart-reservas-monfrague")[0].getContext("2d");
      new Chart(ctx4, {type: 'doughnut', data: doughnutData, options:doughnutOptions});
    }

    var doughnutData = {
      labels: ["Inscripciones", "Disponible"],
      datasets: [{
          data: [this.datos.total_inscripciones, this.datos.aforo_disponible],
          backgroundColor: ["#e0e0e0", "#23c6c8"]
      }]
    };
    var doughnutOptions = {
        responsive: true,
        legend: {
            display: true
        }
    };
    if ($("#chart-aforo-monfrague").length) {
      var ctx4 = $("#chart-aforo-monfrague")[0].getContext("2d");
      new Chart(ctx4, {type: 'doughnut', data: doughnutData, optexions:doughnutOptions});
    }
    this.globals.Api('/act-datos-localizacion', {geodata: true, id_ubicacion: 1}).subscribe(data => {
      if (!data || !data.length) return;
      var map,
          markers = [],
          cityAreaData = [];
      for (let item of data) {
        markers.push({
          latLng: [item.lat, item.lon],
          name: item.localidad
        });
        cityAreaData.push(item.total);
      }
      $.mapalocalizaciones_monfrague = $('#chart-localizaciones-monfrague').vectorMap({
        map: 'es_merc',
        focusOn: {
          lat: 39.2,
          lng: -6.155078983139426,
          scale: 7,
          animate: true
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {
            fill: '#94C11E'
          },
          selected: {
            fill: '#6e9605'
          }
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
        }
      });
    }, error => {
    });
    this.globals.Api('/act-datos-localizacion', {id_ubicacion: 1}).subscribe(data => {
      this.localizaciones_monfrague = data;
    });
    this.globals.Api('/act-datos-localizacion', {geodata: true, id_ubicacion: 1, portugal: true}).subscribe(data => {
      if (!data || !data.length) return;
      var map,
          markers = [],
          cityAreaData = [];
      for (let item of data) {
        markers.push({
          latLng: [item.lat, item.lon],
          name: item.localidad
        });
        cityAreaData.push(item.total);
      }
      $.mapalocalizaciones_monfrague_pt = $('#chart-localizaciones-monfrague-pt').vectorMap({
        map: 'pt_merc',
        focusOn: {
          lat: 39.5651600420688,
          lng: -8.020377737364566,
          scale: 1,
          animate: true
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {
            fill: '#94C11E'
          },
          selected: {
            fill: '#6e9605'
          }
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
        }
      });
    }, error => {
    });
    this.globals.Api('/act-datos-localizacion', {id_ubicacion: 1, portugal: true}).subscribe(data => {
      this.localizaciones_monfrague_pt = data;
    });
  }
  CargarChartsTajo() {
    $('#chart-reservas-tajo').parent().html(`<canvas id="chart-reservas-tajo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>`);
    $('#chart-aforo-tajo').parent().html(`<canvas id="chart-aforo-tajo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>`);
    $('#chart-mapa-tajo').parent().html(`<div id="chart-mapa-tajo" style="height:230px;"></div>`);
    $('#chart-localizaciones-tajo').parent().html(`<div id="chart-localizaciones-tajo" style="height:300px;"></div>`);
    var mapData = {
      "ES-CC": this.datos.total_caceres_tajo,
      "ES-BA": this.datos.total_badajoz_tajo
    };
    $.mapageneral_tajo = $('#chart-mapa-tajo').vectorMap({
        map: 'es_merc',
        focusOn: {
          lat: 39.2,
          lng: -6.155078983139426,
          scale: 5,
          animate: true
        },
        backgroundColor: "transparent",
        regionStyle: {
            initial: {
                fill: '#e4e4e4',
                "fill-opacity": 0.9,
                stroke: 'none',
                "stroke-width": 0,
                "stroke-opacity": 0
            }
        },
        series: {
            regions: [{
                values: mapData,
                scale: ["#5BB0E3", "#94C11E"],
                normalizeFunction: 'polynomial'
            }]
        },
        onRegionTipShow: function(e, el, code){
          if (!mapData || !mapData[code]) return;
          el.html(el.html()+' ('+mapData[code]+' Personas)');
        }
    });

    var doughnutData = {
      labels: ["Bajas", "En Espera", "Confirmadas"],
      datasets: [{
          data: [this.datos.total_bajas, this.datos.enespera, this.datos.confirmadas],
          backgroundColor: ["#ed5565", "#f8ac59", "#94C11E"]
      }]
    };
    var doughnutOptions = {
        responsive: true,
        legend: {
            display: true
        }
    };
    if ($("#chart-reservas-tajo").length) {
      var ctx4 = $("#chart-reservas-tajo")[0].getContext("2d");
      new Chart(ctx4, {type: 'doughnut', data: doughnutData, options:doughnutOptions});
    }

    var doughnutData = {
      labels: ["Inscripciones", "Disponible"],
      datasets: [{
          data: [this.datos.total_inscripciones, this.datos.aforo_disponible],
          backgroundColor: ["#e0e0e0", "#23c6c8"]
      }]
    };
    var doughnutOptions = {
        responsive: true,
        legend: {
            display: true
        }
    };
    if ($("#chart-aforo-tajo").length) {
      var ctx4 = $("#chart-aforo-tajo")[0].getContext("2d");
      new Chart(ctx4, {type: 'doughnut', data: doughnutData, optexions:doughnutOptions});
    }
    this.globals.Api('/act-datos-localizacion', {geodata: true, id_ubicacion: 2}).subscribe(data => {
      if (!data || !data.length) return;
      var map,
          markers = [],
          cityAreaData = [];
      for (let item of data) {
        markers.push({
          latLng: [item.lat, item.lon],
          name: item.localidad
        });
        cityAreaData.push(item.total);
      }
      $.mapalocalizaciones_tajo = $('#chart-localizaciones-tajo').vectorMap({
        map: 'es_merc',
        focusOn: {
          lat: 39.2,
          lng: -6.155078983139426,
          scale: 7,
          animate: true
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {
            fill: '#94C11E'
          },
          selected: {
            fill: '#6e9605'
          }
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
        }
      });
    }, error => {
    });
    this.globals.Api('/act-datos-localizacion', {id_ubicacion: 2}).subscribe(data => {
      this.localizaciones_tajo = data;
    });
    this.globals.Api('/act-datos-localizacion', {geodata: true, id_ubicacion: 2, portugal: true}).subscribe(data => {
      if (!data || !data.length) return;
      var map,
          markers = [],
          cityAreaData = [];
      for (let item of data) {
        markers.push({
          latLng: [item.lat, item.lon],
          name: item.localidad
        });
        cityAreaData.push(item.total);
      }
      $.mapalocalizaciones_tajo_pt = $('#chart-localizaciones-tajo-pt').vectorMap({
        map: 'pt_merc',
        focusOn: {
          lat: 39.5651600420688,
          lng: -8.020377737364566,
          scale: 1,
          animate: true
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {
            fill: '#94C11E'
          },
          selected: {
            fill: '#6e9605'
          }
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html('<span style="text-transform:capitalize">'+el.html()+'</span> ('+cityAreaData[code]+' Personas)');
        }
      });
    }, error => {
    });
    this.globals.Api('/act-datos-localizacion', {id_ubicacion: 2, portugal: true}).subscribe(data => {
      this.localizaciones_tajo_pt = data;
    });
  }
  ExportarExcel(tipo:string = '') {
    let year = new Date().getFullYear();
    let archivo = '';
    let id_ubicacion = null;
    switch(tipo) {
      case 'localizaciones': {
        archivo = 'Localidades MRB ' + year;
        id_ubicacion = null;
      } break;
      case 'localizaciones-monfrague': {
        archivo = 'Localidades Monfragüe MRB ' + year;
        id_ubicacion = 1;
      } break;
      case 'localizaciones-tajo': {
        archivo = 'Localidades Tajo MRB ' + year;
        id_ubicacion = 2;
      } break;
    };
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5
    });
    this.globals.Api('/act-datos-localizacion', {id_ubicacion: id_ubicacion}).subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data || !data.length) {
        swal('Lista vacía', 'La lista que quieres exportar está vacía', 'error');
        return;
      }
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: archivo,
        Subject: "Inscripciones por localización",
        // Author: "Peces Gordos Estudio"
      };
      let hoja = archivo;
      wb.SheetNames.push(hoja);
      var ws_data = [];
      ws_data.push(['Localidad', 'Total personas']);
      for (let item of data) {
        ws_data.push([
          item.localidad,
          item.total,
        ]);
      }
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        {wch:40},
        {wch:20},
      ];
      ws['!cols'] = wscols;
      wb.Sheets[hoja] = ws;
      var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      // saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), exportName+'.xlsx');
      var blob = new Blob([s2ab(wbout)], {type: "octet/stream"});
      var url = window.URL.createObjectURL(blob);
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     url);
      downloadAnchorNode.setAttribute("download", archivo + ".xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      window.URL.revokeObjectURL(url);
      downloadAnchorNode.remove();
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
  Tab(tab:string = '') {
    setTimeout(() => {
      switch(tab) {
        case 'general': {
          $("#chart-mapa").vectorMap('get','mapObject').updateSize();
          $("#chart-mapa").vectorMap('get','mapObject').setFocus({
            lat: 39.2,
            lng: -6.155078983139426,
            scale: 5,
            animate: true
          });
          $("#chart-localizaciones").vectorMap('get','mapObject').updateSize();
          $("#chart-localizaciones-pt").vectorMap('get','mapObject').updateSize();
          $("#chart-localizaciones").vectorMap('get','mapObject').setFocus({
            lat: 39.2,
            lng: -6.155078983139426,
            scale: 7,
            animate: true
          });
          $("#chart-localizaciones-pt").vectorMap('get','mapObject').setFocus({
            lat: 39.5651600420688,
            lng: -8.020377737364566,
            scale: 1,
            animate: true
          });
        } break;
        case 'monfrague': {
          $("#chart-mapa-monfrague").vectorMap('get','mapObject').updateSize();
          $("#chart-mapa-monfrague").vectorMap('get','mapObject').setFocus({
            lat: 39.2,
            lng: -6.155078983139426,
            scale: 5,
            animate: true
          });
          $("#chart-localizaciones-monfrague").vectorMap('get','mapObject').updateSize();
          $("#chart-localizaciones-monfrague-pt").vectorMap('get','mapObject').updateSize();
          $("#chart-localizaciones-monfrague").vectorMap('get','mapObject').setFocus({
            lat: 39.2,
            lng: -6.155078983139426,
            scale: 7,
            animate: true
          });
          $("#chart-localizaciones-monfrague-pt").vectorMap('get','mapObject').setFocus({
            lat: 39.5651600420688,
            lng: -8.020377737364566,
            scale: 1,
            animate: true
          });
        } break;
        case 'tajo': {
          $("#chart-mapa-tajo").vectorMap('get','mapObject').updateSize();
          $("#chart-mapa-tajo").vectorMap('get','mapObject').setFocus({
            lat: 39.2,
            lng: -6.155078983139426,
            scale: 5,
            animate: true
          });
          $("#chart-localizaciones-tajo").vectorMap('get','mapObject').updateSize();
          $("#chart-localizaciones-tajo-pt").vectorMap('get','mapObject').updateSize();
          $("#chart-localizaciones-tajo").vectorMap('get','mapObject').setFocus({
            lat: 39.2,
            lng: -6.155078983139426,
            scale: 7,
            animate: true
          });
          $("#chart-localizaciones-tajo-pt").vectorMap('get','mapObject').setFocus({
            lat: 39.5651600420688,
            lng: -8.020377737364566,
            scale: 1,
            animate: true
          });
        } break;
      }
    }, 200);
  }
  ImprimirCartelQR() {
    if (!this.colaborador || !this.colaborador.id || this.colaborador.id == '') return;
    let url = 'https://app.mesdelareservabiosfera.es/api/qr-colaborador/' + this.colaborador.id;
    let img = null;
    getDataUri(url, function(dataUri) {
      img = dataUri;
      var doc = new jsPDF();
      let left = 15;
      let top = 15;
      const imgWidth = 180;
      const imgHeight = 1754 * (180 / 1241);
      doc.addImage(img, 'PNG', left, top, imgWidth, imgHeight);
      doc.output('dataurlnewwindow');
    });
    function getDataUri(url, cb) {
      let image = new Image();
      image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain
      image.onload = function (event:any) {
        let canvas = document.createElement('canvas');
        canvas.width = event.target.naturalWidth;
        canvas.height = event.target.naturalHeight;
        let ctx = canvas.getContext('2d');
        ctx.fillStyle = '#fff';  /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        canvas.getContext('2d').drawImage(event.target, 0, 0);
        cb(canvas.toDataURL('image/jpeg'));
      };
      image.src = url;
    }
  }

}
