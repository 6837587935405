<div id="wrapper">
    <app-menu></app-menu>
    
    <div id="page-wrapper" class="gray-bg dashbard-1">
      <app-header></app-header>
  
      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-md-8 col-sm-6 col-xs-12">
            <h2>
              <i class="icofont-opposite"></i>
              {{title}}
            </h2>
            <ol class="breadcrumb m-l-10">
                <li class="breadcrumb-item">
                    <a routerLink="/dashboard">Inicio</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/actividades/admin/actividades">Actividades</a>
                </li>
                <li class="breadcrumb-item active">
                    <strong>{{title}}</strong>
                </li>
            </ol>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 col-botones">
            <ng-container *ngIf="data && data.id != ''">
                <a class="btn btn-default" target="_blank" [routerLink]="['/actividad/'+data.seo_url]">
                    <i class="fa fa-eye"></i>
                    Ver
                </a>
                <div class="separador-vertical"></div>
            </ng-container>
            <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
            <a id="btnGuardar" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
        </div>
      </div>
      <div class="animated fadeInRight">
                      
        <div class="row m-t-10">
          <div class="col-lg-4 col-md-12">
            <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
              <i class="icofont-camera-alt"></i>
            </div>
            <div class="img-preview-container cursor relative" (click)="SelecImagen()">
              <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
              <small class="block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
              <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
            </div>
            <div class="ibox border">
              <a class="ibox-title block" data-toggle="collapse" href="#galeria-imagenes">
                  <i class="fa fa-picture-o"></i> Galería de imágenes
                  <a id="btn-add-imagen" class="btn btn-xs btn-primary pull-right m-r-20" (click)="AddImagen($event)"><i class="fa fa-plus"></i> Añadir imagen</a>
                  <div class="ibox-tools">
                    <a><i class="fa fa-chevron-up"></i></a>
                  </div>
              </a>
              <div id="galeria-imagenes" class="ibox-content collapse show">
                <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                  <h4>No hay imágenes aún</h4>
                </div>
                <div class="lightBoxGallery" *ngIf="data && data.imagenes" style="padding: 0 10px;">
                  <div class="row galeria-imagenes">
                    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of data.imagenes" style="padding-left:5px;padding-right:5px;"  [attr.itemid]="item.id">
                      <a href="{{item.imagen}}" class="link-gallery" title="Imagen actividad" [attr.data-gallery]="data.title">
                        <img class="img-fluid" src="{{item.imagen}}">
                        <a class="btn btn-xs btn-danger cursor absolute" style="top:5px;right:0" (click)="DelImagen($event, item)"><i class="fa fa-trash text-white"></i></a>
                      </a>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <div class="ibox border">
              <a class="ibox-title block" data-toggle="collapse" href="#galeria-imagenes-comolopasamos">
                  <i class="fa fa-picture-o"></i> Galería de cómo lo pasamos
                  <a id="btn-add-imagen-clp" class="btn btn-xs btn-primary pull-right m-r-20" (click)="AddImagenComoLoPasamos($event)"><i class="fa fa-plus"></i> Añadir imagen</a>
                  <div class="ibox-tools">
                    <a><i class="fa fa-chevron-up"></i></a>
                  </div>
              </a>
              <div id="galeria-imagenes-comolopasamos" class="ibox-content collapse show">
                <div class="text-center" *ngIf="!data || !data.imagenes_comolopasamos || !data.imagenes_comolopasamos.length">
                  <h4>No hay imágenes aún</h4>
                </div>
                <div class="lightBoxGallery" *ngIf="data && data.imagenes_comolopasamos" style="padding: 0 10px;">
                  <div class="row galeria-imagenes-comolopasamos">
                    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of data.imagenes_comolopasamos" style="padding-left:5px;padding-right:5px;" [attr.itemid]="item.id">
                      <a href="{{item.imagen}}" class="link-gallery" title="Imagen actividad" [attr.data-gallery]="data.title">
                        <img class="img-fluid" src="{{item.imagen}}">
                        <a class="btn btn-xs btn-danger cursor absolute" style="top:5px;right:0" (click)="DelImagenComoLoPasamos($event, item)"><i class="fa fa-trash text-white"></i></a>
                      </a>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
                <hr>
                <ng-container *ngIf="data && data.video">
                  <video width="100%" height="240" controls style="max-height:240px;">
                    <source [src]="data.video" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                  <a class="btn btn-xs btn-danger btn-block text-white cursor" (click)="DelVideoComoLoPasamos()"><i class="fa fa-trash text-white"></i> Borrar video</a>
                </ng-container>
                <ng-container *ngIf="data && data.id && data.id != '' && !data.video">
                  <input type="url" placeholder="Url del video de cómo lo pasamos" class="form-control" [(ngModel)]="data.url_video">
                  <!-- <a id="btn-add-video" class="btn btn-xs btn-primary btn-block m-t-20" (click)="AddVideoComoLoPasamos($event)"><i class="fa fa-plus"></i> Añadir video cómo lo pasamos</a> -->
                </ng-container>
              </div>
            </div>
            <!-- <div class="form-group">
              <label>URL imagen</label>
              <input type="text" class="form-control" [(ngModel)]="data.url_imagen" maxlength="400">
            </div> -->
            
            <div class="ibox border" style="display:none">
              <a class="ibox-title block" data-toggle="collapse" href="#actividades-complementarias">
                  <i class="icofont-opposite"></i> Actividades complementarias
                  <div class="ibox-tools"><a><i class="fa fa-chevron-up"></i></a></div>
              </a>
              <div id="actividades-complementarias" class="ibox-content collapse show">
                <select class="form-control" [(ngModel)]="id_complementaria">
                  <option *ngFor="let item of complementarias" [value]="item.id">{{item.titulo}}</option>
                </select>
                <a id="btn-add-complementaria" class="btn btn-xs btn-default btn-block" (click)="AddComplementaria()"><i class="fa fa-plus"></i> Añadir actividad</a>
                <ng-container *ngIf="data.complementarias && data.complementarias.length">
                  <hr>
                  <h6 class="font-10">Act. Complementarias a&ntilde;adidas</h6>
                  <div class="m-t-10">
                    <div class="relative p-t-5 p-b-5" *ngFor="let item of data.complementarias" style="border-bottom: 1px solid #EEE;">{{item.titulo}}
                      <a class="cursor m-l-10 absolute" (click)="DelComplementaria(item)">
                        <i class="fa fa-close text-danger"></i>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="ibox border">
              <a class="ibox-title block" data-toggle="collapse" href="#promociones-complementarias">
                  <i class="fa fa-user"></i> Adjuntar PDF promociones complementarias
                  <div class="ibox-tools"><a><i class="fa fa-chevron-up"></i></a></div>
              </a>
              <div id="promociones-complementarias" class="ibox-content collapse show">
                <div class="alert alert-info" *ngIf="!data || data.id == ''">
                  Para adjuntar debe guardar esta actividad
                </div>
                <div class="tabs-container" *ngIf="data && data.id != ''">
                  <ul class="nav nav-tabs" role="tablist">
                      <li><a class="nav-link active" data-toggle="tab" href="#tab-es-adjuntos"><img src="assets/img/flags/16/Spain.png" alt=""> ES</a></li>
                      <li><a class="nav-link" data-toggle="tab" href="#tab-pt-adjuntos"><img src="assets/img/flags/16/Portugal.png" alt=""> PT</a></li>
                  </ul>
                  <div class="tab-content">
                    <div role="tabpanel" id="tab-es-adjuntos" class="tab-pane active">
                        <div class="panel-body">
                          <div class="row">
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>Nombre</label>
                                <input type="text" class="form-control" [(ngModel)]="docdata_es.nombre" maxlength="80">
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 my-auto">
                              <a class="btn btn-block btn-default wrap" (click)="SelectDocES()"><i class="fa fa-file-text"></i> Seleccionar archivo</a>
                              <p class="text-center" *ngIf="docdata_es.filepath != ''">
                                  <i *ngIf="docdata_es.extension == '.pdf'" class="fa fa-file-pdf-o"></i>
                                  <i *ngIf="docdata_es.extension == '.doc' || docdata_es.extension == '.docx'" class="fa fa-file-word-o"></i>
                                  <i *ngIf="docdata_es.extension == '.zip' || docdata_es.extension == '.rar'" class="fa fa-file-archive-o"></i>
                                  <i *ngIf="docdata_es.extension == '.xls' || docdata_es.extension == '.xlsx'  || docdata_es.extension == '.csv'" class="fa fa-file-excel-o"></i>
                                  <i *ngIf="docdata_es.extension == '.txt'" class="fa fa-file-text-o"></i>
                                  <i *ngIf="docdata_es.extension == '.jpg' || docdata_es.extension == '.jpeg' || docdata_es.extension == '.gif' || docdata_es.extension == '.png'" class="fa fa-file-image-o"></i>
                                  {{docdata_es.filepath}}
                              </p>
                            </div>
                          </div>
                          <a id="btn-adjuntar-doc-es" class="btn btn-primary btn-block" (click)="GuardarDocES()">Adjuntar <i class="fa fa-upload"></i></a>
                          <hr>
                          <div class="row">
                            <div *ngFor="let doc of docs_es" class="col-xl-6 col-lg-12 flex-vertical">
                              <div *ngIf="doc.extension == '.jpg' || doc.extension == '.jpeg' || doc.extension == '.gif' || doc.extension == '.png'">
                                  <div class="relative" style="height:72%;min-height:155px">
                                      <a [href]="doc.documento" [title]="doc.nombre" [attr.data-gallery]="'Adjuntos español'" class="box-content-center" style="height:100%">
                                          <img alt="image" class="feed-photo" [src]="doc.documento" style="width: 100%;max-height:100%;margin:0;">
                                      </a>
                                      <a class="btn btn-sm btn-danger absolute" style="top:0;right:0" (click)="BorrarDoc(doc)"><i class="fa fa-trash text-white"></i></a>
                                  </div>
                                  <h3 class="text-center" contenteditable (blur)="EditarDocNombre(doc, $event)">{{doc.nombre}}</h3>
                              </div>
                              <div *ngIf="doc.extension != '.jpg' && doc.extension != '.jpeg' && doc.extension != '.gif' && doc.extension != '.png'" class="relative" style="height:72%;min-height:155px">
                                  <div class="relative" style="height:72%;min-height:155px">
                                      <a target="_blank" [href]="doc.documento" class="btn btn-block btn-default box-content-center">
                                          <i *ngIf="doc.extension == '.pdf'" class="fa fa-file-pdf-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.doc' || doc.extension == '.docx'" class="fa fa-file-word-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.zip' || doc.extension == '.rar'" class="fa fa-file-archive-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.xls' || doc.extension == '.xlsx'  || doc.extension == '.csv'" class="fa fa-file-excel-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.txt'" class="fa fa-file-text-o" style="font-size:50px;"></i>
                                      </a>
                                      <a class="btn btn-sm btn-danger absolute" style="top:0;right:0" (click)="BorrarDoc(doc)"><i class="fa fa-trash text-white"></i></a>
                                  </div>
                                  <h3 class="text-center" contenteditable (blur)="EditarDocNombre(doc, $event)">{{doc.nombre}}</h3>
                              </div>
                          </div>
                          </div>
                        </div>
                    </div>
                    <div role="tabpanel" id="tab-pt-adjuntos" class="tab-pane">
                        <div class="panel-body">
                          <div class="row">
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>Nombre</label>
                                <input type="text" class="form-control" [(ngModel)]="docdata_pt.nombre" maxlength="80">
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 my-auto">
                              <a class="btn btn-block btn-default wrap" (click)="SelectDocPT()"><i class="fa fa-file-text"></i> Seleccionar archivo</a>
                              <p class="text-center" *ngIf="docdata_pt.filepath != ''">
                                  <i *ngIf="docdata_pt.extension == '.pdf'" class="fa fa-file-pdf-o"></i>
                                  <i *ngIf="docdata_pt.extension == '.doc' || docdata_pt.extension == '.docx'" class="fa fa-file-word-o"></i>
                                  <i *ngIf="docdata_pt.extension == '.zip' || docdata_pt.extension == '.rar'" class="fa fa-file-archive-o"></i>
                                  <i *ngIf="docdata_pt.extension == '.xls' || docdata_pt.extension == '.xlsx'  || docdata_pt.extension == '.csv'" class="fa fa-file-excel-o"></i>
                                  <i *ngIf="docdata_pt.extension == '.txt'" class="fa fa-file-text-o"></i>
                                  <i *ngIf="docdata_pt.extension == '.jpg' || docdata_pt.extension == '.jpeg' || docdata_pt.extension == '.gif' || docdata_pt.extension == '.png'" class="fa fa-file-image-o"></i>
                                  {{docdata_pt.filepath}}
                              </p>
                            </div>
                          </div>
                          <a id="btn-adjuntar-doc-pt" class="btn btn-primary btn-block" (click)="GuardarDocPT()">Adjuntar <i class="fa fa-upload"></i></a>
                          <hr>
                          <div class="row">
                            <div *ngFor="let doc of docs_pt" class="col-xl-6 col-lg-12 flex-vertical">
                              <div *ngIf="doc.extension == '.jpg' || doc.extension == '.jpeg' || doc.extension == '.gif' || doc.extension == '.png'">
                                  <div class="relative" style="height:72%;min-height:155px">
                                      <a [href]="doc.documento" [title]="doc.nombre" [attr.data-gallery]="'Adjuntos portugués'" class="box-content-center" style="height:100%">
                                          <img alt="image" class="feed-photo" [src]="doc.documento" style="width: 100%;max-height:100%;margin:0;">
                                      </a>
                                      <a class="btn btn-sm btn-danger absolute" style="top:0;right:0" (click)="BorrarDoc(doc)"><i class="fa fa-trash text-white"></i></a>
                                  </div>
                                  <h3 class="text-center" contenteditable (blur)="EditarDocNombre(doc, $event)">{{doc.nombre}}</h3>
                              </div>
                              <div *ngIf="doc.extension != '.jpg' && doc.extension != '.jpeg' && doc.extension != '.gif' && doc.extension != '.png'" class="relative" style="height:72%;min-height:155px">
                                  <div class="relative" style="height:72%;min-height:155px">
                                      <a target="_blank" [href]="doc.documento" class="btn btn-block btn-default box-content-center">
                                          <i *ngIf="doc.extension == '.pdf'" class="fa fa-file-pdf-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.doc' || doc.extension == '.docx'" class="fa fa-file-word-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.zip' || doc.extension == '.rar'" class="fa fa-file-archive-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.xls' || doc.extension == '.xlsx'  || doc.extension == '.csv'" class="fa fa-file-excel-o" style="font-size:50px;"></i>
                                          <i *ngIf="doc.extension == '.txt'" class="fa fa-file-text-o" style="font-size:50px;"></i>
                                      </a>
                                      <a class="btn btn-sm btn-danger absolute" style="top:0;right:0" (click)="BorrarDoc(doc)"><i class="fa fa-trash text-white"></i></a>
                                  </div>
                                  <h3 class="text-center" contenteditable (blur)="EditarDocNombre(doc, $event)">{{doc.nombre}}</h3>
                              </div>
                          </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-12">

            <div class="row m-t-10">
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label class="text-primary">Empresa <span class="text-danger">*</span></label>
                  <select class="form-control" [(ngModel)]="data.id_usuario">
                    <option *ngFor="let item of empresas" [value]="item.id">{{item.empresa}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label class="text-primary">Ubicaci&oacute;n <span class="text-danger">*</span></label>
                  <select class="form-control" [(ngModel)]="data.id_ubicacion">
                    <option *ngFor="let item of ubicaciones" [value]="item.id">{{item.ubicacion}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label class="text-primary">Categor&iacute;a <span class="text-danger">*</span></label>
                  <select class="form-control" [(ngModel)]="data.id_categoria">
                    <option *ngFor="let item of categorias" [value]="item.id">{{item.categoria}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-xs-12">
                <div class="form-group">
                  <label>Organizador / Empresa <small class="m-l-20">(Se mostrará en los detalles de la actividad)</small></label>
                  <input type="text" class="form-control" [(ngModel)]="data.organizador" maxlength="100">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label><i class="fa fa-calendar"></i> Fecha</label>
                  <div id="txtFechaActividad" class="input-group date">
                    <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                    <input type="text" class="form-control" autocomplete="off" [(ngModel)]="data.fecha_format">
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-xs-12">
                <div class="form-group">
                  <label><i class="fa fa-clock-o"></i> Hora inicio</label>
                  <input type="time" class="form-control" [(ngModel)]="data.hora_inicio" maxlength="5">
                </div>
              </div>
              <div class="col-md-2 col-xs-12">
                <div class="form-group">
                  <label><i class="fa fa-clock-o"></i> Hora fin</label>
                  <input type="time" class="form-control" [(ngModel)]="data.hora_fin" maxlength="5">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Semana</label>
                  <input type="number" class="form-control" placeholder="Dejar vacío para que se calcule" [(ngModel)]="data.semana" maxlength="2">
                </div>
              </div>
            </div>
            <div class="ibox border hidden" *ngIf="data.id && data.id != ''">
              <div class="ibox-title block font-bold">Reprogramación Fecha</div>
              <div class="ibox-content">
                <div class="row">
                  <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                      <label>Fecha</label>
                      <div id="txtFechaActividadReprogramada" class="input-group date">
                        <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                        <input type="text" class="form-control" autocomplete="off" [(ngModel)]="data.cambio.fecha_format">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-xs-12">
                    <div class="form-group">
                      <label><i class="fa fa-clock-o"></i> Hora inicio</label>
                      <input type="time" class="form-control" [(ngModel)]="data.cambio.hora_inicio" maxlength="5">
                    </div>
                  </div>
                  <div class="col-md-2 col-xs-12">
                    <div class="form-group">
                      <label><i class="fa fa-clock-o"></i> Hora fin</label>
                      <input type="time" class="form-control" [(ngModel)]="data.cambio.hora_fin" maxlength="5">
                    </div>
                  </div>
                  <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                      <label>Semana</label>
                      <input type="number" class="form-control" placeholder="Dejar vacío para que se calcule" [(ngModel)]="data.cambio.semana" maxlength="2">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label class="text-primary"><i class="fa fa-map-marker"></i> Municipio <span class="text-danger">*</span>
                    <a class="btn btn-xs" (click)="data.editlocalidad = true;" *ngIf="!data.editlocalidad"><i class="fa fa-pencil"></i></a>
                    <a class="btn btn-xs" (click)="data.editlocalidad = false;" *ngIf="data.editlocalidad"><i class="fa fa-list"></i></a>
                  </label>
                  <ng-container *ngIf="!data.editlocalidad">
                    <select class="form-control" [(ngModel)]="data.localidad">
                      <option *ngFor="let item of localidades" [value]="item.localidad">{{item.localidad}}</option>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="data.editlocalidad">
                    <input type="text" placeholder="Escribe la localidad" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                  </ng-container>
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label><i class="fa fa-map-pin"></i> Punto de encuentro</label>
                  <input type="text" class="form-control" [(ngModel)]="data.municipio" maxlength="200">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Localizaci&oacute;n MAPS</label>
                  <!-- <input type="text" class="form-control" [(ngModel)]="data.localizacion_maps" maxlength="100"> -->
                  <div class="input-group m-b">
                      <input type="text" placeholder="Coordenadas GPS" class="form-control" [(ngModel)]="data.localizacion_maps">
                      <div class="input-group-append cursor" (click)="AbrirMapa()">
                          <span class="input-group-addon bg-exman-oscuro" style="font-size:20px;padding-top:6px;"><i class="fa fa-map-marker"></i></span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Aforo</label>
                  <input type="number" class="form-control" [(ngModel)]="data.aforo" maxlength="20">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Tel&eacute;fono de contacto</label>
                  <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Turnos <strong>(Enter para a&ntilde;adir)</strong>. Ejemplo: 11:00h - 12:30h</label>
                  <input type="text" class="form-control" maxlength="50" (keyup.Enter)="AddTurno($event)" style="width:auto;">
                  <!-- <datalist id="turnos" *ngIf="data.turnos && data.turnos.length">
                    <option *ngFor="let item of data.turnos.split(',')">{{item.turno}}</option>
                  </datalist> -->
                  <div class="m-t-10" *ngIf="data.turnos && data.turnos.length">
                    <div *ngFor="let item of data.turnos.split(',')">{{item}} <a class="cursor m-l-10" (click)="DelTurno(item)"><i class="fa fa-close text-danger"></i></a></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Incluye</label>
                  <input type="text" class="form-control" [(ngModel)]="data.incluye" maxlength="300">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>URL Reserva Externa <i class="fa fa-external-link-square"></i></label>
                  <input type="text" class="form-control" [(ngModel)]="data.url_reserva_externa" maxlength="400">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group p-t-30">
                  <div class="i-checks"><label><input id="chkborrado" type="checkbox" [checked]="data.borrado" (change)="data.borrado = !data.borrado"><i></i> Ocultar actividad</label></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">

                <div class="ibox border">
                  <div class="ibox-title block font-bold">Fecha de inscripciones</div>
                  <div class="ibox-content">
                    
                    <div class="input-group date">
                      <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                      <input id="txtFechaInscripciones" type="text" class="form-control" autocomplete="off" [(ngModel)]="data.fecha_inicio_reservas_format">
                    </div>
                    <div class="form-group p-t-30">
                      <div class="i-checks"><label><input id="chkreservaninos" type="checkbox" [checked]="data.reserva_ninos" (change)="data.reserva_ninos = !data.reserva_ninos"><i></i> Permitir reservar a niños</label></div>
                    </div>

                  </div>
                </div>

              </div>
              <div class="col-md-6 col-sm-12" *ngIf="data.id && data.id != '' && data.principal">

                <div class="ibox border">
                  <div class="ibox-title block font-bold">
                    Actividades relacionadas
                    <a class="btn btn-primary btn-sm absolute" style="top:8px;right:8px" (click)="ModalSeleccionaActividad()"><i class="fa fa-plus"></i></a>
                  </div>
                  <div class="ibox-content">
                    <ul class="list-group" *ngIf="!data.relacionadas || !data.relacionadas.length">
                      <li class="list-group-item">
                        No se han asignado actividades relacionadas aún
                      </li>
                    </ul>
                    <ul class="list-group" *ngIf="data.relacionadas && data.relacionadas.length">
                      <li class="list-group-item" *ngFor="let item of data.relacionadas">
                        <div class="wrap" style="width:calc(100% - 65px)">
                          <img [src]="item.imagen" *ngIf="item.imagen" class="img-listado square m-r-10">
                          {{item.titulo}}
                        </div>
                        <a class="btn btn-default btn-sm absolute" style="top:12px;right:45px" target="_blank" [routerLink]="['/actividad/'+item.seo_url]">
                          <i class="fa fa-eye"></i>
                        </a>
                        <a class="btn btn-danger btn-sm absolute" style="top:12px;right:8px" (click)="DelRelacionada(item)"><i class="fa fa-trash text-white"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            
            <div class="tabs-container">
              <ul class="nav nav-tabs" role="tablist">
                  <li><a class="nav-link active" data-toggle="tab" href="#tab-es"><img src="assets/img/flags/16/Spain.png" alt=""> ES</a></li>
                  <li><a class="nav-link" data-toggle="tab" href="#tab-pt"><img src="assets/img/flags/16/Portugal.png" alt=""> PT</a></li>
              </ul>
              <div class="tab-content">
                <div role="tabpanel" id="tab-es" class="tab-pane active">
                    <div class="panel-body">

                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label class="text-primary">Título <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.titulo" maxlength="80">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label class="text-primary">Subtítulo</label>
                            <input type="text" class="form-control" [(ngModel)]="data.subtitulo" maxlength="200">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label class="text-primary">Descripci&oacute;n <span class="text-danger">*</span></label>
                            <textarea id="txtdescripcion" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion" maxlength="5000" rows="10"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Material recomendado</label>
                            <textarea type="text" class="form-control" [(ngModel)]="data.material" maxlength="500" rows="3"></textarea>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
                <div role="tabpanel" id="tab-pt" class="tab-pane">
                    <div class="panel-body">

                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label class="text-primary">Título <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data_pt.titulo" maxlength="80">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Subtítulo</label>
                            <input type="text" class="form-control" [(ngModel)]="data_pt.subtitulo" maxlength="200">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label class="text-primary">Descripci&oacute;n <span class="text-danger">*</span></label>
                            <textarea id="txtdescripcionpt" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data_pt.descripcion" maxlength="5000" rows="10"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Material recomendado</label>
                            <textarea type="text" class="form-control" [(ngModel)]="data_pt.material" maxlength="500" rows="3"></textarea>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
      <div class="col-botones m-t-10">
        <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
        <a id="btnGuardar2" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
      </div>
  
      <app-footer></app-footer>
    </div>
  
</div>

<div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls">
    <div class="slides"></div>
    <h3 class="title"></h3>
    <a class="prev">‹</a>
    <a class="next">›</a>
    <a class="close">×</a>
    <a class="play-pause"></a>
    <ol class="indicator"></ol>
</div>

<div class="modal inmodal fade" id="modal-mapa" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" style="font-size: 3rem;"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                <h4 class="modal-title">Seleccione localizaci&oacute;n</h4>
                <small></small>
            </div>
            <div id="mapa-container" class="modal-body" style="padding: 0;">
                <div id="mapa" style="width:100%;height:400px;"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="margin-right: auto;" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="SelecLocalizacion()">Seleccionar</button>
            </div>
        </div>
    </div>
</div>


<div id="blueimp-gallery" class="blueimp-gallery">
  <div class="slides"></div>
  <h3 class="title"></h3>
  <a class="prev">‹</a>
  <a class="next">›</a>
  <a class="close">×</a>
  <a class="play-pause"></a>
  <ol class="indicator"></ol>
</div>

<app-admin-modal-seleccion-actividad (seleccionar)="SeleccionarRelacionada($event)"></app-admin-modal-seleccion-actividad>