<div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header font-18">
            <a id="btn-menu-mobile" class="minimalize-styl-2 btn btn-default desktop-hidden" (click)="SlideMenu()"><i class="fa fa-bars"></i></a>

            <!-- <a id="btn-accesos-directos" *ngIf="globals.me && globals.me.id_rol != 3 && globals.me.id_rol != 4" class="btn btn-primary m-l-5" style="margin-top:13px;border-radius:50%" data-toggle="collapse" data-target="#accesos-directos">
                <i class="fa fa-plus"></i>
            </a> -->
            <!-- <form role="search" class="navbar-form-custom" action="search_results.html">
                <div class="form-group">
                    <input type="text" placeholder="Search for something..." class="form-control" name="top-search" id="top-search">
                </div>
            </form> -->
                <div class="licencia" *ngIf="globals.me && globals.me.licencia">
                    <div class="licencia-content-success" *ngIf="!globals.me.licencia.error"><i class="fa fa-shield"></i> Licencia activa</div>
                    <div class="licencia-content-error" *ngIf="globals.me.licencia.error"><i class="fa fa-exclamation-triangle"></i> ¡Licencia expirada!</div>
                </div>
        </div>
        <ul class="nav navbar-top-links" style="margin-left:0;width:auto;">
            <li>
            </li>
        </ul>
        <ul class="nav navbar-top-links navbar-right m-r-10">

            <li>
                <span class="m-r-sm welcome-message mobile-hidden">Sistema de reservas de <span *ngIf="globals.config && globals.config.nombre" class="text-primary font-bold">{{globals.config.nombre}}</span></span>
            </li>

            <ng-container *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
                <ng-container *ngIf="globals.me.id_rol != 4">
                    <li class="dropdown m-r-10" id="btn-notificaciones-header">
                        <a class="dropdown-toggle count-info" [routerLink]="['/actividades/admin/mensajes']" *ngIf="globals.me && globals.me.id_ubicacion == '1'">
                            <i class="fa fa-whatsapp fa-2x"></i>  <span class="label label-default badge-whatsapp-header" *ngIf="globals.cmensajeswhatsapp_monfrague">
                                {{globals.cmensajeswhatsapp_monfrague}}
                            </span>
                        </a>
                        <a class="dropdown-toggle count-info" [routerLink]="['/actividades/admin/mensajes']" *ngIf="globals.me && globals.me.id_ubicacion == '2'">
                            <i class="fa fa-whatsapp fa-2x"></i>  <span class="label label-default badge-whatsapp-header" *ngIf="globals.cmensajeswhatsapp_tajo">
                                {{globals.cmensajeswhatsapp_tajo}}
                            </span>
                        </a>
                        <!-- <a class="dropdown-toggle count-info" data-toggle="dropdown">
                            <i class="fa fa-bell"></i>  <span class="label label-default bg-badge-header" *ngIf="notificaciones.length">{{notificaciones.length}}</span>
                        </a> -->
                        <ul class="dropdown-menu dropdown-messages dropdown-menu-right {{!notificaciones.length ? 'hidden' : ''}}" style="max-height:500px;overflow:auto;">
                            <ng-container *ngFor="let item of notificaciones">
                                <li>
                                    <div class="dropdown-messages-box cursor" (click)="IrReserva(item)">
                                        <a class="dropdown-item float-left" *ngIf="item.imagen">
                                            <img alt="image" class="rounded-circle" [src]="item.imagen">
                                        </a>
                                        <div class="media-body">
                                            <strong>{{item.nombre}} {{item.apellidos}}</strong> ha solicitado reserva.<br>
                                            <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                                            <div *ngIf="item.acompanantes && item.acompanantes != '0'"><strong class="font-16">{{item.acompanantes}}</strong> acompa&ntilde;ante/s</div>
                                            <strong class="text-secondary font-bold text-{{globals.CleanedString(item.ubicacion, '-')}}">{{item.titulo}}</strong><br>
                                            <small class="text-muted">Hace {{globals.PostedDate(item.alta)}}</small>
                                        </div>
                                    </div>
                                </li>
                                <li class="dropdown-divider"></li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
            </ng-container>

            <li>
                <a (click)="Logout()">
                    <i class="fa fa-sign-out"></i> Cerrar sesi&oacute;n
                </a>
            </li>

        </ul>
    </nav>
</div>