import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { SwPush } from '@angular/service-worker';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let XLSX: any;
declare let saveAs: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  public id_instalacion:string = '';
  public fecha_exportacion:string = '';
  public instalaciones:any = [];
  public etapas:any = [];
  public parametros:any = [];
  public exportando:boolean = false;
  public cancelarexportacion:boolean = false;

  public notificaciones = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    public swPush: SwPush
  ) { }

  ngOnInit() {
    $(document).ready(() => {
      $(document).click(function(e) {
        if (e.target.id == 'btn-accesos-directos') return;
        var container = $("#accesos-directos");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $('#accesos-directos').collapse('hide');
        }
      });

      $('.collapse-link').on('click', function (e) {
        e.preventDefault();
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.children('.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
      });
    });
    if (this.globals.config && this.globals.config.tipoapp == 'gimnasio') {
      if (this.globals.me.id_rol == 4) return;
      this.CargarNotificaciones();
    }
    if (this.globals.config && this.globals.config.tipoapp == 'actividades') {
      if (this.globals.me.id_rol == 4) return;
      this.CargarReservasPendientes();
    }
  }
  ngOnDestroy() {
    // console.log('destroy header');
    clearInterval($.IntervalIDNotificacionesHeader);
  }

  CargarNotificaciones() {
    let leyendo:boolean = false;
    $('#btn-notificaciones-header').on('show.bs.dropdown', () => {
      leyendo = true;
      if (this.globals.config && this.globals.config.tipoapp == 'gimnasio') {
        let index = 0;
        let loop = () => {
          if (index >= this.notificaciones.length) return;
          let element = this.notificaciones[index];
          this.globals.Api('/gym-set-noticacion-leida', {id: element.id}).subscribe(data => {
            index++;loop();
          },error => {index++;loop();});
        }
        loop();
      }
    });
    $('#btn-notificaciones-header').on('hidden.bs.dropdown', () => {
      console.log('cerrar notificaciones');
      leyendo = false;
      load();
    });
    let load = () => {
      let params = null;
      if (this.globals.config && this.globals.config.tipoapp == 'gimnasio') {
        if (this.globals.me && this.globals.me.id_rol == 3) {
          params = {
            usuario: true
          };
        } else {
          params = {
            admin: true
          };
        }
      }
      this.globals.Api('/notificaciones', params).subscribe(data => {
        if (!data) return;
        if (data.length == this.notificaciones.length) return;
        this.notificaciones = data;
        this.appref.tick();
      });
    };
    load();
    $.IntervalIDNotificacionesHeader = setInterval(() => {
      if (leyendo) return;
      load();
    }, 30000);
  }

  //ACTIVIDADES
  CargarReservasPendientes() {
    this.globals.Api('/act-reservas', {id_estado: 1, page_size: 10, agrupado_usuario: true, order: 'act_reservas.alta', dir: 'DESC'}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        this.notificaciones = [];return;
      }
      this.notificaciones = data.data;
    });
  }
  IrReserva(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/reserva')).then();
  }

  Logout() {
    $.Logout();
  }

  Opcion(opcion:string) {
    switch(true) {
      case (opcion == 'crear-instalacion'): {
        $.SetIdInstalacion('');
        $.CargarModalCrearInstalacion();
      } break;
    }
    $('#accesos-directos').collapse('hide');
  }

  SlideMenu() {
    $("body").removeClass("mini-navbar");
    $('.overlay-navbar-slide').toggleClass("show");
    $('.navbar-default').toggleClass("navbar-slide");
    $('#side-menu')[0].onclick = () => {
      $('.overlay-navbar-slide').removeClass("show");
      $('.navbar-default').removeClass("navbar-slide");
    };
  }

}
