<div id="wrapper">
    <app-menu></app-menu>
    <div id="page-wrapper" class="gray-bg dashbard-1">
        <app-header></app-header>

        <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-lg-12 col-md-12 col-xs-12">
                <h2>
                    <i class="fa fa-whatsapp"></i>
                    Mensajes
                    <span *ngIf="zona == 'monfrague'">Monfragüe</span>
                    <span *ngIf="zona == 'tajo'">Tajo</span>
                </h2>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a routerLink="/dashboard">Inicio</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <strong>
                            Mensajes
                            <span *ngIf="zona == 'monfrague'">Monfragüe</span>
                            <span *ngIf="zona == 'tajo'">Tajo</span>
                        </strong>
                    </li>
                </ol>
            </div>
        </div>
        <div class="row animated fadeInRight m-t-20">

            <iframe [src]="url_iframe" frameborder="0" *ngIf="url_iframe && url_iframe != ''" style="width:100%;height:78vh"></iframe>

        </div>

        <app-footer></app-footer>
    </div>
</div>