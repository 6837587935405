import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;

@Component({
  selector: 'app-admin-actividades-reservas',
  templateUrl: './reservas.component.html'
})
export class AdminReservasActividadesComponent implements OnInit {
  public reservas = [];
  public params:any = {
    page_index: 1,
    page_size: 9,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
    agrupado: true,
    paginacion: true
  };
  public pendientes = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
    this.CargarDatos();
    this.CargarPendientes();
  }
  CargarPendientes() {
    if (this.globals.GetData('reservas-pendientes')) this.pendientes = this.globals.GetData('reservas-pendientes');
    this.globals.Api('/act-reservas', {id_estado: 1, page_size: 10, agrupado_usuario: true, order: 'act_reservas.alta', dir: 'DESC', filtro: this.params.filtro}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        this.pendientes = [];
        this.globals.SaveData([], 'reservas-pendientes');
        return;
      }
      this.pendientes = data.data;
      this.globals.SaveData(this.pendientes, 'reservas-pendientes');
    });
  }
  CargarDatos(paginationload:boolean = true) {
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.6)',
    //   imageResizeFactor: 0.5
    // });
    if (this.globals.GetData('reservas-filtro')) this.params.filtro = this.globals.GetData('reservas-filtro');
    if (this.globals.GetData('reservas-page-index')) this.params.page_index = this.globals.GetData('reservas-page-index');
    if (this.globals.GetData('reservas')) this.reservas = this.globals.GetData('reservas');
    this.globals.Api('/act-reservas', this.params).subscribe(data => {
      // $.LoadingOverlay("hide", true);
      if (!data || !data.data || !data.data.length) {
        this.reservas = [];
        this.globals.SaveData([], 'reservas');
        this.globals.SaveData('', 'reservas-filtro');
        this.globals.SaveData(1, 'reservas-page-index');
        return;
      }
      this.reservas = data.data;
      this.globals.SaveData(this.reservas, 'reservas');
      this.globals.SaveData(this.params.filtro, 'reservas-filtro');
      this.globals.SaveData(this.params.page_index, 'reservas-page-index');
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    }, error => {
      // $.LoadingOverlay("hide", true);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.globals.SaveData(this.params.filtro, 'reservas-filtro');
    this.globals.SaveData(this.params.page_index, 'reservas-page-index');
    this.CargarDatos(false);
  }
  FiltrarUbicacion(id_ubicacion:string = '') {
    this.params.id_ubicacion = id_ubicacion;
    this.params.page_index = 1;
    this.CargarDatos();
    this.CargarPendientes();
  }
  Buscar() {
    this.params.page_index = 1;
    this.globals.SaveData(this.params.filtro, 'reservas-filtro');
    this.globals.SaveData(this.params.page_index, 'reservas-page-index');
    this.CargarDatos();
    this.CargarPendientes();
  }
  Actualizar() {
    this.CargarDatos();
    this.CargarPendientes();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/reserva')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/reserva')).then();
  }
  VerReserva(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/ver/reserva')).then();
  }
  BorrarReserva(event, item) {
    event.stopPropagation();
    swal({
      title: "¿Cancelar reserva?",
      text: "Va a cancelar esta reserva y la de los acompañantes",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-borrar-reserva', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Cancelada', 'Reserva cancelada correctamente', 'success');
        }, 200);
        this.pendientes.splice(this.pendientes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
