<div id="wrapper" *ngIf="globals.me">
  <app-menu></app-menu>

  <div id="page-wrapper" class="gray-bg dashbard-1">
    <app-header></app-header>

    <div class="row wrapper border-bottom white-bg page-heading" *ngIf="globals.me && ![4].includes(globals.me.id_rol)">
      <div class="col-lg-12 col-xs-12">
        <ng-container *ngIf="globals.config && (globals.config.tipoapp == 'gimnasio' || globals.config.tipoapp == 'actividades')">
          <div *ngIf="globals.me && [0,1,2].includes(globals.me.id_rol)">
            <h2>
              <i class="fa fa-home"></i>
              Inicio
            </h2>
          </div>
          <div *ngIf="globals.me && globals.me.id_rol == 3">
            <h2 class="font-italic">Hola <span class="text-primary font-bold">{{globals.me.nombre}}</span>!</h2>
            <p class="font-16">Bienvenido al sistema de reservas de <span *ngIf="globals.config && globals.config.nombre" class="text-secondary font-bold">{{globals.config.nombre}}</span></p>
          </div>
        </ng-container>
      </div>
    </div>

    <!--Actividades-->
    <ng-container *ngIf="datos && globals.me && ![4].includes(globals.me.id_rol)">
      <!--Dashboard Administrador-->
      <div *ngIf="globals.me && [0,1].includes(globals.me.id_rol)">
        <h2 class="font-italic">Hola <span class="text-primary font-bold">{{globals.me.nombre}}</span>!</h2>
        <p class="font-16">Bienvenido al panel de administraci&oacute;n del sistema de reservas</p>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-01 animated fadeInUp" [routerLink]="['/actividades/admin/reservas']">
              <div class="badge">{{datos.confirmadas}}</div>
              <h2><i class="icofont-calendar"></i> Reservas</h2>
            </a>
          </div>
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-02 animated fadeInUp delay-0-2" [routerLink]="['/actividades/admin/actividades']">
              <div class="badge">{{datos.total_actividades}}</div>
              <h2><i class="icofont-opposite"></i> Actividades</h2>
            </a>
          </div>
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-04 animated fadeInUp delay-0-4" [routerLink]="['/actividades/admin/colaboradores']">
              <div class="badge">{{datos.total_colaboradores}}</div>
              <h2><i class="icofont-tasks"></i> Colaboradores</h2>
            </a>
          </div>
        </div>
        <hr>

        <div class="">
          <ul class="nav nav-pills nav-justified">
              <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#tab-general" (click)="Tab('general')"><i class="icofont-pie-chart"></i> General</a></li>
              <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-monfrague" (click)="Tab('monfrague')"><div class="badge badge-primary" style="background-color:#94C11E;font-size:15px;padding-right:0;"><i class="icofont-pie-chart"></i></div> Monfragüe</a></li>
              <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-tajo" (click)="Tab('tajo')"><div class="badge badge-primary" style="background-color:#5AB0E3;font-size:15px;padding-right:0;"><i class="icofont-pie-chart"></i></div> Tajo / Tejo Internacional</a></li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-general" class="tab-pane fade show active">
                <div class="panel-body">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Inscripciones</div>
                        <div class="ibox-content">
                          <table class="table table-hover margin bottom">
                            <tbody>
                              <!-- <tr>
                                  <td>Pendientes</td>
                                  <td class="text-center"><span class="label label-default">{{datos.pendientes}}</span></td>
                              </tr> -->
                              <tr>
                                <td style="border-top:none;">Bajas</td>
                                <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas}}</span></td>
                              </tr>
                              <tr>
                                  <td>En espera</td>
                                  <td class="text-center"><span class="label label-warning">{{datos.enespera}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Confirmadas</td>
                                  <td class="text-center"><span class="label label-success">{{datos.confirmadas}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Total incripciones</td>
                                  <td class="text-center"><span class="label label-info">{{datos.total_inscripciones}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-people"></i> Aforo</div>
                        <div class="ibox-content">
                          <table class="table table-hover margin bottom">
                            <tbody>
                              <tr>
                                  <td style="border-top:none;">Aforo disponible</td>
                                  <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible}}</span></td>
                              </tr>
                              <tr>
                                  <td>Aforo ocupado</td>
                                  <td class="text-center"><span class="label label-default">{{datos.total_aforo - datos.aforo_disponible}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Total aforo</td>
                                  <td class="text-center"><span class="label label-info">{{datos.total_aforo}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa general</div>
                        <div class="ibox-content text-center">
                          <div id="chart-mapa" style="height:230px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Reservas</div>
                        <div class="ibox-content">
                          <canvas id="chart-reservas" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Aforo</div>
                        <div class="ibox-content">
                          <canvas id="chart-aforo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa localizaciones</div>
                        <div class="ibox-content text-center">
                          <div *ngIf="cargandomapalocalidades">Cargando ...</div>
                          <div id="chart-localizaciones" style="height:300px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title">
                          <i class="icofont-google-map"></i> Localizaciones
                          <a class="btn btn-secondary m-l-15" (click)="ExportarExcel('localizaciones')"><i class="fa fa-file-excel-o"></i> Exportar</a>
                        </div>
                        <div class="ibox-content"
                          style="max-height:350px;overflow:auto;">
                          <table class="table table-hover margin bottom" *ngIf="localizaciones && localizaciones.length">
                            <tbody>
                              <tr *ngFor="let item of localizaciones">
                                  <td style="text-transform: capitalize;">{{item.localidad}}</td>
                                  <td class="text-center"><span class="label label-default">{{item.total}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa Portugal</div>
                        <div class="ibox-content text-center">
                          <div *ngIf="cargandomapalocalidadespt">Cargando ...</div>
                          <div id="chart-localizaciones-pt" style="height:300px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div role="tabpanel" id="tab-monfrague" class="tab-pane fade">
                <div class="panel-body">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Inscripciones</div>
                        <div class="ibox-content">
                          <table class="table table-hover margin bottom">
                            <tbody>
                              <!-- <tr>
                                  <td>Pendientes</td>
                                  <td class="text-center"><span class="label label-default">{{datos.pendientes_monfrague}}</span></td>
                              </tr> -->
                              <tr>
                                <td style="border-top:none;">Bajas</td>
                                <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas_monfrague}}</span></td>
                              </tr>
                              <tr>
                                  <td>En espera</td>
                                  <td class="text-center"><span class="label label-warning">{{datos.enespera_monfrague}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Confirmadas</td>
                                  <td class="text-center"><span class="label label-success">{{datos.confirmadas_monfrague}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Total incripciones</td>
                                  <td class="text-center"><span class="label label-info">{{datos.total_inscripciones_monfrague}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-people"></i> Aforo</div>
                        <div class="ibox-content">
                          <table class="table table-hover margin bottom">
                            <tbody>
                              <tr>
                                  <td style="border-top:none;">Aforo disponible</td>
                                  <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible_monfrague}}</span></td>
                              </tr>
                              <tr>
                                  <td>Aforo ocupado</td>
                                  <td class="text-center"><span class="label label-default">{{datos.total_aforo_monfrague - datos.aforo_disponible_monfrague}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Total aforo</td>
                                  <td class="text-center"><span class="label label-info">{{datos.total_aforo_monfrague}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa general</div>
                        <div class="ibox-content text-center">
                          <div id="chart-mapa-monfrague" style="height:230px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Reservas</div>
                        <div class="ibox-content">
                          <canvas id="chart-reservas-monfrague" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Aforo</div>
                        <div class="ibox-content">
                          <canvas id="chart-aforo-monfrague" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa localizaciones</div>
                        <div class="ibox-content text-center">
                          <div id="chart-localizaciones-monfrague" style="height:300px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title">
                          <i class="icofont-google-map"></i> Localizaciones
                          <a class="btn btn-secondary m-l-15" (click)="ExportarExcel('localizaciones-monfrague')"><i class="fa fa-file-excel-o"></i> Exportar</a>
                        </div>
                        <div class="ibox-content"
                          style="max-height:350px;overflow:auto;">
                          <table class="table table-hover margin bottom" *ngIf="localizaciones_monfrague && localizaciones_monfrague.length">
                            <tbody>
                              <tr *ngFor="let item of localizaciones_monfrague">
                                  <td style="text-transform: capitalize;">{{item.localidad}}</td>
                                  <td class="text-center"><span class="label label-default">{{item.total}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa Portugal</div>
                        <div class="ibox-content text-center">
                          <div id="chart-localizaciones-monfrague-pt" style="height:300px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div role="tabpanel" id="tab-tajo" class="tab-pane fade">
                <div class="panel-body">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Inscripciones</div>
                        <div class="ibox-content">
                          <table class="table table-hover margin bottom">
                            <tbody>
                              <!-- <tr>
                                  <td>Pendientes</td>
                                  <td class="text-center"><span class="label label-default">{{datos.pendientes_tajo}}</span></td>
                              </tr> -->
                              <tr>
                                <td style="border-top:none;">Bajas</td>
                                <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas_tajo}}</span></td>
                              </tr>
                              <tr>
                                  <td>En espera</td>
                                  <td class="text-center"><span class="label label-warning">{{datos.enespera_tajo}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Confirmadas</td>
                                  <td class="text-center"><span class="label label-success">{{datos.confirmadas_tajo}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Total incripciones</td>
                                  <td class="text-center"><span class="label label-info">{{datos.total_inscripciones_tajo}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-people"></i> Aforo</div>
                        <div class="ibox-content">
                          <table class="table table-hover margin bottom">
                            <tbody>
                              <tr>
                                  <td style="border-top:none;">Aforo disponible</td>
                                  <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible_tajo}}</span></td>
                              </tr>
                              <tr>
                                  <td>Aforo ocupado</td>
                                  <td class="text-center"><span class="label label-default">{{datos.total_aforo_tajo - datos.aforo_disponible_tajo}}</span></td>
                              </tr>
                              <tr>
                                  <td class="font-bold">Total aforo</td>
                                  <td class="text-center"><span class="label label-info">{{datos.total_aforo_tajo}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa general</div>
                        <div class="ibox-content text-center">
                          <div id="chart-mapa-tajo" style="height:230px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Reservas</div>
                        <div class="ibox-content">
                          <canvas id="chart-reservas-tajo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-pie-chart"></i> Aforo</div>
                        <div class="ibox-content">
                          <canvas id="chart-aforo-tajo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa localizaciones</div>
                        <div class="ibox-content text-center">
                          <div id="chart-localizaciones-tajo" style="height:300px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title">
                          <i class="icofont-google-map"></i> Localizaciones
                          <a class="btn btn-secondary m-l-15" (click)="ExportarExcel('localizaciones-tajo')"><i class="fa fa-file-excel-o"></i> Exportar</a>
                        </div>
                        <div class="ibox-content"
                          style="max-height:350px;overflow:auto;">
                          <table class="table table-hover margin bottom" *ngIf="localizaciones_tajo && localizaciones_tajo.length">
                            <tbody>
                              <tr *ngFor="let item of localizaciones_tajo">
                                  <td style="text-transform: capitalize;">{{item.localidad}}</td>
                                  <td class="text-center"><span class="label label-default">{{item.total}}</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xs-12">
                      <div class="ibox resumen-dashboard">
                        <div class="ibox-title"><i class="icofont-google-map"></i> Mapa Portugal</div>
                        <div class="ibox-content text-center">
                          <div id="chart-localizaciones-tajo-pt" style="height:300px;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="row hidden">
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Resumen general</div>
              <div class="ibox-content">
                <table class="table table-hover margin bottom">
                  <thead>
                    <tr>
                        <th>General</th>
                        <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                        <td>Pendientes</td>
                        <td class="text-center"><span class="label label-default">{{datos.pendientes}}</span></td>
                    </tr> -->
                    <tr>
                      <td style="border-top:none;">Bajas</td>
                      <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas}}</span></td>
                    </tr>
                    <tr>
                        <td>En espera</td>
                        <td class="text-center"><span class="label label-warning">{{datos.enespera}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Confirmadas</td>
                        <td class="text-center">
                          <span class="label label-success">{{datos.confirmadas}}</span>
                          <br>
                          <small class="block m-t-10"><span class="font-14">{{datos.total_personas}}</span> con acomp.</small>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>Total inscripciones</td>
                        <td class="text-center"><span class="label label-default">{{datos.total_personas}}</span></td>
                    </tr> -->
                    <tr>
                        <td style="border-top:none;">Aforo disponible</td>
                        <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Total aforo</td>
                        <td class="text-center"><span class="label label-info">{{datos.total_aforo}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Resumen Mofragüe</div>
              <div class="ibox-content">
                <table class="table table-hover margin bottom">
                  <thead>
                    <tr>
                        <th>Mofragüe</th>
                        <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                        <td>Pendientes</td>
                        <td class="text-center"><span class="label label-default">{{datos.pendientes_monfrague}}</span></td>
                    </tr> -->
                    <tr>
                      <td style="border-top:none;">Bajas</td>
                      <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas_monfrague}}</span></td>
                    </tr>
                    <tr>
                        <td>En espera</td>
                        <td class="text-center"><span class="label label-warning">{{datos.enespera_monfrague}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Confirmadas</td>
                        <td class="text-center">
                          <span class="label label-success">{{datos.confirmadas_monfrague}}</span>
                          <br>
                          <small class="block m-t-10"><span class="font-14">{{datos.total_personas_monfrague}}</span> con acomp.</small>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>Total inscripciones</td>
                        <td class="text-center"><span class="label label-default">{{datos.total_personas_monfrague}}</span></td>
                    </tr> -->
                    <tr>
                        <td style="border-top:none;">Aforo disponible</td>
                        <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible_monfrague}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Total aforo</td>
                        <td class="text-center"><span class="label label-info">{{datos.total_aforo_monfrague}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Resumen Tajo Internacional</div>
              <div class="ibox-content">
                <table class="table table-hover margin bottom">
                  <thead>
                    <tr>
                        <th>Tajo Internacional</th>
                        <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                        <td>Pendientes</td>
                        <td class="text-center"><span class="label label-default">{{datos.pendientes_tajo}}</span></td>
                    </tr> -->
                    <tr>
                      <td style="border-top:none;">Bajas</td>
                      <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas_tajo}}</span></td>
                    </tr>
                    <tr>
                        <td>En espera</td>
                        <td class="text-center"><span class="label label-warning">{{datos.enespera_tajo}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Confirmadas</td>
                        <td class="text-center">
                          <span class="label label-success">{{datos.confirmadas_tajo}}</span>
                          <br>
                          <small class="block m-t-10"><span class="font-14">{{datos.total_personas_tajo}}</span> con acomp.</small>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>Total inscripciones</td>
                        <td class="text-center"><span class="label label-default">{{datos.total_personas_tajo}}</span></td>
                    </tr> -->
                    <tr>
                        <td style="border-top:none;">Aforo disponible</td>
                        <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible_tajo}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Total aforo</td>
                        <td class="text-center"><span class="label label-info">{{datos.total_aforo_tajo}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="globals.me && globals.me.id_rol == 2">
        <h2 class="font-italic">Hola <span class="text-primary font-bold">{{globals.me.nombre}}</span>!</h2>
        <p class="font-16">Bienvenido al panel de administraci&oacute;n del sistema de reservas</p>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-01 animated fadeInUp" [routerLink]="['/actividades/admin/reservas']">
              <div class="badge">{{datos.confirmadas}}</div>
              <h2><i class="icofont-calendar"></i> Reservas</h2>
            </a>
          </div>
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-02 animated fadeInUp delay-0-2" [routerLink]="['/actividades/admin/actividades']">
              <div class="badge">{{datos.total_actividades}}</div>
              <h2><i class="icofont-opposite"></i> Actividades</h2>
            </a>
          </div>
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-04 animated fadeInUp delay-0-4" [routerLink]="['/actividades/admin/colaboradores']">
              <div class="badge">{{datos.total_colaboradores}}</div>
              <h2><i class="icofont-tasks"></i> Colaboradores</h2>
            </a>
          </div>
        </div>
        <hr><div class="row">
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Inscripciones</div>
              <div class="ibox-content">
                <table class="table table-hover margin bottom">
                  <tbody>
                    <!-- <tr>
                        <td>Pendientes</td>
                        <td class="text-center"><span class="label label-default">{{datos.pendientes}}</span></td>
                    </tr> -->
                    <tr>
                      <td style="border-top:none;">Bajas</td>
                      <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas}}</span></td>
                    </tr>
                    <tr>
                        <td>En espera</td>
                        <td class="text-center"><span class="label label-warning">{{datos.enespera}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Confirmadas</td>
                        <td class="text-center"><span class="label label-success">{{datos.confirmadas}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Total incripciones</td>
                        <td class="text-center"><span class="label label-info">{{datos.total_inscripciones}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-people"></i> Aforo</div>
              <div class="ibox-content">
                <table class="table table-hover margin bottom">
                  <tbody>
                    <tr>
                        <td style="border-top:none;">Aforo disponible</td>
                        <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible}}</span></td>
                    </tr>
                    <tr>
                        <td>Aforo ocupado</td>
                        <td class="text-center"><span class="label label-default">{{datos.total_aforo - datos.aforo_disponible}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Total aforo</td>
                        <td class="text-center"><span class="label label-info">{{datos.total_aforo}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-google-map"></i> Mapa general</div>
              <div class="ibox-content text-center">
                <div id="chart-mapa" style="height:230px;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Reservas</div>
              <div class="ibox-content">
                <canvas id="chart-reservas" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Aforo</div>
              <div class="ibox-content">
                <canvas id="chart-aforo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-google-map"></i> Mapa localizaciones</div>
              <div class="ibox-content text-center">
                <div *ngIf="cargandomapalocalidades">Cargando ...</div>
                <div id="chart-localizaciones" style="height:300px;"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title">
                <i class="icofont-google-map"></i> Localizaciones
                <a class="btn btn-oscuro m-l-15" (click)="ExportarExcel('localizaciones')"><i class="fa fa-file-excel-o"></i> Exportar</a>
              </div>
              <div class="ibox-content"
                style="max-height:350px;overflow:auto;">
                <table class="table table-hover margin bottom" *ngIf="localizaciones && localizaciones.length">
                  <tbody>
                    <tr *ngFor="let item of localizaciones">
                        <td style="text-transform: capitalize;">{{item.localidad}}</td>
                        <td class="text-center"><span class="label label-default">{{item.total}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row hidden">
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Resumen General</div>
              <div class="ibox-content">
                <table class="table table-hover margin bottom">
                  <thead>
                    <tr>
                        <th>General</th>
                        <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                        <td>Pendientes</td>
                        <td class="text-center"><span class="label label-default">{{datos.pendientes}}</span></td>
                    </tr> -->
                    <tr>
                      <td style="border-top:none;">Bajas</td>
                      <td style="border-top:none;" class="text-center"><span class="label label-danger">{{datos.total_bajas}}</span></td>
                    </tr>
                    <tr>
                        <td>En espera</td>
                        <td class="text-center"><span class="label label-warning">{{datos.enespera}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Confirmadas</td>
                        <td class="text-center">
                          <span class="label label-success">{{datos.confirmadas}}</span>
                          <br>
                          <small class="block m-t-10"><span class="font-14">{{datos.total_personas}}</span> con acomp.</small>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>Total inscripciones</td>
                        <td class="text-center"><span class="label label-default">{{datos.total_personas}}</span></td>
                    </tr> -->
                    <tr>
                        <td style="border-top:none;">Aforo disponible</td>
                        <td style="border-top:none;" class="text-center"><span class="label label-default">{{datos.aforo_disponible}}</span></td>
                    </tr>
                    <tr>
                        <td class="font-bold">Total aforo</td>
                        <td class="text-center"><span class="label label-info">{{datos.total_aforo}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Reservas</div>
              <div class="ibox-content">
                <canvas id="chart-reservas" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="ibox resumen-dashboard">
              <div class="ibox-title"><i class="icofont-pie-chart"></i> Aforo</div>
              <div class="ibox-content">
                <canvas id="chart-aforo" width="310" height="310" style="margin:auto;max-width:310px;"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="globals.me && globals.me.id_rol == 3">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-01 animated fadeInUp" [routerLink]="['/actividades/usuario/reservas']">
              <h2><i class="icofont-calendar"></i> Mis Reservas</h2>
            </a>
          </div>
          <div class="col-sm-12 col-md-4">
            <a class="widget p-xl cursor block bg-oscuro bg-03 animated fadeInUp delay-0-4" [routerLink]="['/actividades']">
              <h2><i class="icofont-opposite"></i> Buscar actividades</h2>
            </a>
          </div>
        </div>
        <!-- <div class="linea m-t-30 m-b-30"></div> -->
      </div>
      <!--Fin Dashboard Administrador-->
    </ng-container>

    <!--Colaboradores-->
    <ng-container *ngIf="globals.me && [4].includes(globals.me.id_rol)">
      <ng-container *ngIf="colaborador">

        <div class="ibox border panel-colaborador mt-3">
          <div class="ibox-title block font-18">Tus datos de colaborador
            <span class="m-l-30" *ngIf="colaborador.total_reservas">
              <span class="font-normal font-14 m-r-5">Total reservas anticipadas:</span> {{colaborador.total_reservas}}
            </span>
            <span class="m-l-30" *ngIf="colaborador.total_visitantes">
              <span class="font-normal font-14 m-r-5">Total visitantes:</span> {{colaborador.total_visitantes}}
            </span>
          </div>
          <div class="ibox-content">

            <div class="row">
              <div class="col-md-4 col-sm-12">
                <img [src]="colaborador.imagen" [alt]="colaborador.empresa" *ngIf="colaborador.imagen" class="qr img-fluid m-b-10">
                <img [src]="colaborador.qr" *ngIf="colaborador.qr && !colaborador.imagen" class="qr img-fluid m-b-10">
              </div>
              <div class="col-md-8 col-sm-12">
                <h2 class="font-18 font-bold text-primary" *ngIf="colaborador.empresa">{{colaborador.empresa}}</h2>
                <h6 class="font-16" *ngIf="colaborador.codpostal">{{colaborador.codpostal}} {{colaborador.localidad}}, {{colaborador.provincia}}</h6>
                <h6 class="font-16" *ngIf="colaborador.email">{{colaborador.email}}</h6>
                <h6 class="font-16" *ngIf="colaborador.telefono">{{colaborador.telefono}}</h6>
                <h6 class="font-16" *ngIf="colaborador.ubicacion"><span class="circulo-color {{colaborador.id_ubicacion == '1' ? 'monfrague' : 'tajo'}}"></span> {{colaborador.ubicacion}}</h6>
                <h6 class="font-16" *ngIf="colaborador.servicios">
                  Servicios:<br>
                  <div class="font-14" style="font-weight:normal;line-height:1.1em;">{{colaborador.servicios}}</div>
                </h6>
                <h6 class="font-16">
                  <a class="btn btn-success" target="_blank"
                    href="https://app.mesdelareservabiosfera.es/seleccion-actividad?keycol2022034123tkaHTplk={{colaborador.id}}">
                    <i class="fa fa-external-link"></i> Enlace para reserva anticipada
                  </a>
                  <br>
                  <a class="btn btn-primary font-16 mt-2" style="flex-grow:0;" (click)="ImprimirCartelQR()"><i class="fa fa-print"></i> Imprimir Cartel QR</a>
                </h6>
                <div class="d-flex align-items-end" style="gap:1rem">
                  <img [src]="colaborador.qr" *ngIf="colaborador.qr && colaborador.imagen" class="m-t-10" style="height:110px;object-fit:contain;">
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="feed-activity-list">
              <div class="ibox border panel-colaborador">
                <div class="ibox-title block font-bold font-18">Reservas anticipadas</div>
                <div class="ibox-content">

                  <ng-container *ngIf="colaborador.reservas && colaborador.reservas.length">
                    <div class="feed-element cursor" *ngFor="let item of colaborador.reservas">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="pull-right">
                            <img [src]="'https://quickchart.io/chart?cht=qr&chs=50x50&chld=M%7C0&chl='+item.referencia" class="m-r-10" style="height:50px;vertical-align:top;">
                          </div>
                          <div class="media-body">
                            <strong class="text-secondary">{{item.referencia}}</strong><br>
                            <strong>{{item.nombre}} {{item.apellidos}}</strong><br>
                            <div>{{item.localidad}}, {{item.provincia}}</div>
                            <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                            <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                            <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                          </div>
                          <div class="m-l-30 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                              <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                              <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                  <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                              </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <img [src]="item.imagen" alt="" *ngIf="item.imagen" class="block m-t-10 m-b-10" style="height:80px;border-radius:8px;">
                          <h3>{{item.titulo}}</h3>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!colaborador.reservas || !colaborador.reservas.length">
                    <div class="no-results">
                      Aún no hay reservas registradas
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 hidden">
            <div class="feed-activity-list">
              <div class="ibox border panel-colaborador">
                <div class="ibox-title block font-bold font-18">Visitantes</div>
                <div class="ibox-content">

                  <ng-container *ngIf="colaborador.reservas_visitas && colaborador.reservas_visitas.length">
                    <div class="feed-element cursor" *ngFor="let item of colaborador.reservas_visitas">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="pull-right">
                            <img [src]="'https://quickchart.io/chart?cht=qr&chs=50x50&chld=M%7C0&chl='+item.referencia" class="m-r-10" style="height:50px;vertical-align:top;">
                          </div>
                          <div class="media-body">
                            <strong class="text-secondary">{{item.referencia}}</strong><br>
                            <strong>{{item.nombre}} {{item.apellidos}}</strong><br>
                            <div>{{item.localidad}}, {{item.provincia}}</div>
                            <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                            <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                            <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                            <div *ngIf="item.fecha_visita">Fecha visita: <b>{{item.fecha_visita}}</b></div>
                          </div>
                          <div class="m-l-30 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                              <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                              <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                  <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                              </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <img [src]="item.imagen" alt="" *ngIf="item.imagen" class="block m-t-10 m-b-10" style="height:80px;border-radius:8px;">
                          <h3>{{item.titulo}}</h3>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!colaborador.reservas_visitas || !colaborador.reservas_visitas.length">
                    <div class="no-results">
                      Aún no hay visitas registradas
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <app-footer></app-footer>
  </div>

</div>