import { Component, OnInit, NgZone, ApplicationRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-admin-modal-seleccion-actividad',
  templateUrl: './modal-seleccion-actividad.component.html'
})
export class AdminModalSeleccionActividadComponent implements OnInit {
  @Output() seleccionar: EventEmitter<any> = new EventEmitter<any>();
  public actividades = [];
  public params:any = {
    page_index: 1,
    page_size: 10,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.checkrolredirect(3);
    $.btnmenu();
    $.disableinputnumberwheel();
    $('#modal-seleccion-actividad').on('show.bs.modal', () => {
      $('#modal-seleccion-actividad').off('show.bs.modal');
      this.CargarDatos();
      this.globals.GridOrderJS((order, dir) => {
        this.params.order = order;
        this.params.dir = dir;
        this.params.page_index = 1;
        this.CargarDatos();
        this.appref.tick();
      });
    });
    $.RefreshDatosSeleccionActividad = (exclude_ids:any = null) => {
      this.params.exclude_ids = null;
      if (exclude_ids && exclude_ids != '') {
        this.params.exclude_ids = exclude_ids;
      }
      this.Buscar();
    };
  }
  CargarDatos(paginationload:boolean = true) {
    this.globals.Api('/act-actividades', this.params).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        this.actividades = [];return;
      }
      this.actividades = data.data;
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarDatos(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarDatos();
  }
  Seleccionar(item:any = null) {
    $('#modal-seleccion-actividad').modal('hide');
    if (!item) return;
    this.seleccionar.emit(item);
  }

}
