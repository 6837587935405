import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-edit-usuario',
  templateUrl: './edit-usuario.component.html'
})
export class EditUsuarioComponent implements OnInit {
  
  public title:string = 'Nuevo usuario';
  public iconclass:string = 'icofont-users';
  public data:any = {
    id: '',
    id_rol: 1,
    imagen: '',
    img: '',
    referencia: '000',
    alias: '',
    nombre: '',
    apellidos: '',
    codpostal: '',
    direccion: '',
    localidad: '',
    telefono: '',
    email: '',
    clave: '',
    acceso: '1',
    gimnasio: {}
  };
  public imgsubir:string = '';
  public roles = [];
  public colaboradores:boolean = (window.location.pathname.indexOf('colaboradores') != -1);

  //Gimnasio
  public monitor:boolean = false;
  public tarifas:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit() {
    $.checkrolredirect(3);
    $.btnmenu();
    $.disableinputnumberwheel();
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (this.data.id == '') {
      this.title = 'Nuevo usuario';
    } else {
      this.title = this.data.nombre + (this.data.apellidos ? ' ' + this.data.apellidos : '');
    }

    //Gimnasio
    if (this.globals.config && this.globals.config.tipoapp == 'gimnasio') {
      if (this.data.id == '') this.data.id_rol = 3;
      if (this.globals.GetQueryString('tipo') == 'monitor' || this.data.id_rol == 2) {
        this.monitor = true;
        if (this.data.id == '') {
          this.title = 'Nuevo monitor';
        }
      }
    }
    //Actividades
    if (this.globals.config && this.globals.config.tipoapp == 'actividades') {
      if (this.data.id == '') this.data.id_rol = 3;
      if (this.globals.GetQueryString('tipo') == 'empresa' || this.data.id_rol == 2) {
        // this.monitor = true;
        this.iconclass = 'icofont-ui-office';
        if (this.data.id == '') {
          this.title = 'Nueva empresa';
        }
      }
    }

    this.globals.Api('/roles').subscribe(data => {
      if (!data || data.length == 0) return;
      this.roles = data;
      if (this.monitor || this.globals.GetQueryString('tipo') == 'empresa') {
        this.data.id_rol = 2;
        let arr = JSON.parse(JSON.stringify(this.roles));
        arr.forEach(item => {
          if (item.id != this.data.id_rol) this.roles.splice(this.roles.findIndex(i => i.id == item.id), 1);
        });
        this.appref.tick();
      }
    });

    $(document).ready(() => {
      $('.date').datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        dateFormat: 'dd/mm/yy'
      });
      $('#fechanacimiento').datepicker('setDate', '01/01/1990');
      if (this.data.fecha_nacimiento) $('#fechanacimiento').datepicker('setDate', new Date(this.data.fecha_nacimiento));
    });
    if (this.globals.config && this.globals.config.tipoapp == 'gimnasio') {
      if (!this.data.gimnasio) this.data.gimnasio = {};
      let changeTarifa = () => {
        let cmbtarifas = $('#cmbTarifas')[0];
        let dias = null;
        if (cmbtarifas && cmbtarifas.options.length) {
          dias = cmbtarifas.options[cmbtarifas.selectedIndex].getAttribute('dias');
        }
        if (!dias) return;
        dias = parseInt(dias);
        if (dias == -1) {
          $('#txtfechacaducidad').prop('disabled', false);
        } else {
          $('#txtfechacaducidad').prop('disabled', true);
          let fecha = $('#fechapago').datepicker('getDate');
          if (!fecha || isNaN(fecha.getTime())) return;
          fecha.setDate(fecha.getDate() + dias);
          $('#fechacaducidad').datepicker('setDate', fecha);
        }
      };
      $(document).ready(() => {
        $('#fechapago').datepicker().on('changeDate', (ev) => {
          changeTarifa();
        });
        $('#cmbTarifas').on('change', () => {
          changeTarifa();
        });
        let fecha = new Date();
        $('#fechapago').datepicker('setDate', fecha);
        fecha.setDate(fecha.getDate() + 30);
        $('#fechacaducidad').datepicker('setDate', fecha);
        if (this.data.gimnasio.fecha_pago) $('#fechapago').datepicker('setDate', new Date(this.data.gimnasio.fecha_pago));
        if (this.data.gimnasio.fecha_caducidad) $('#fechacaducidad').datepicker('setDate', new Date(this.data.gimnasio.fecha_caducidad));
      });
      this.globals.Api('/gym-tarifas').subscribe(data => {
        if (!data || data.length == 0) return;
        this.tarifas = data;
        if (!this.data.gimnasio.id_tarifa) {
          if (this.tarifas.length) this.data.gimnasio.id_tarifa = this.tarifas[0].id;
        }
      });
    }
  }
  
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/borrar-imagen-usuario', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data || !data.localidad) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  EditarClave() {
    this.data.title = 'Editar contraseña usuario';
    this.data.clave = '';
    $('#modal-editar-clave-usuario').modal('show');
  }
  GenerarClave() {
    this.data.clave = this.globals.GeneratePassword();
    this.appref.tick();
  }
  GuardarClaveUsuario() {
    if (
      this.data.clave == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    $.buttonloading('btnGuardarClaveUsuarioModal', 'Enviando');
    this.globals.Api('/editar-clave-usuario', this.data).subscribe(data => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-editar-clave-usuario').modal('hide');
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
    });
  }

  Cancelar() {
    if (this.globals.GetQueryString('tipo') == 'monitor') {
      this.ngZone.run(() => this.router.navigateByUrl('/gimnasio/admin/monitores')).then();
      return;
    }
    if (this.globals.config && this.globals.config.tipoapp == 'actividades') {
      if (this.globals.GetQueryString('tipo') == 'empresa') {
        this.ngZone.run(() => this.router.navigateByUrl('/actividades/admin/empresas')).then();
        return;
      }
    }
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios')).then();
  }
  Guardar() {
    if (
      this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.id_rol == ''
      || this.data.email == ''
      || (this.data.clave == '' && this.data.id == '')
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.globals.config && this.globals.config.tipoapp == 'gimnasio') {
      this.data.fecha_nacimiento = moment($('#fechanacimiento').datepicker('getDate')).format('YYYY-MM-DD');
      if (
        !this.data.fecha_nacimiento || this.data.fecha_nacimiento == '' || this.data.fecha_nacimiento == 'Invalid date'
        ) {
        swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
      }
      
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (this.globals.config && this.globals.config.tipoapp == 'gimnasio' && this.data.id_rol == 3) {
      this.data.gimnasio.fecha_pago = moment($('#fechapago').datepicker('getDate')).format('YYYY-MM-DD');
      this.data.gimnasio.fecha_caducidad = moment($('#fechacaducidad').datepicker('getDate')).format('YYYY-MM-DD');
    }

    if (this.data.id == '') {
      $.buttonloading('btnGuardar', 'Enviando');
      $.buttonloading('btnGuardar2', 'Enviando');
      this.globals.Api('/crear-usuario', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
      });
    } else {
      $.buttonloading('btnGuardar', 'Enviando');
      $.buttonloading('btnGuardar2', 'Enviando');
      this.globals.Api('/editar-usuario', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
      });
    }
  }

}
