<div class="overlay-navbar-slide" (click)="SlideMenu()"></div>
<nav class="navbar-default navbar-static-side menu-sidebar" role="navigation" *ngIf="globals.me">
  <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
            <li class="nav-header">
                <div class="text-center m-b-20 m-t-20">
                    <img src="assets/img/logo-2024.png" class="logo-menu" />
                </div>
                <div class="text-center m-b-10 m-t-10">
                    <div class="img-circle-container" *ngIf="!globals.me.imagen || globals.me.imagen == ''">
                        <img src="assets/img/user-icon.svg" />
                    </div>
                    <div class="img-circle-container" *ngIf="globals.me.imagen && globals.me.imagen != ''">
                        <img class="img-circle" [src]="globals.me.imagen" />
                    </div>
                </div>
                <div class="dropdown profile-element">
                    <a data-toggle="dropdown" class="dropdown-toggle" href="#">
                        <span class="block m-t-xs">{{globals.me.empresa ? globals.me.empresa : globals.me.nombre}}</span>
                        <span class="text-xs block" *ngIf="!globals.config || !globals.config.tipoapp || globals.config.tipoapp != 'gimnasio'">
                            {{globals.me.rol}} <b class="caret"></b>
                        </span>
                        <span class="text-xs block" *ngIf="globals.config && globals.config.tipoapp && globals.config.tipoapp == 'gimnasio'">
                            {{globals.me.alias ? globals.me.alias : 'Usuario'}} <b class="caret"></b>
                        </span>
                    </a>
                    <ul class="dropdown-menu animated fadeInRight m-t-xs">
                            <li><a class="dropdown-item" (click)="Perfil()">Mis datos</a></li>
                            <li class="dropdown-divider"></li>
                            <li><a class="dropdown-item" (click)="Logout()">Cerrar sesi&oacute;n</a></li>
                    </ul>
                </div>
            </li>

            <li routerLinkActive="active" *ngIf="globals.me && globals.me.id_rol == 0">
                <a routerLink="/config-app"><i class="fa fa-cogs"></i> <span class="nav-label">Config. App</span></a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/dashboard"><i class="fa fa-home"></i> <span class="nav-label">Inicio</span></a>
            </li>

            <ng-container *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
                <ng-container *ngIf="[0,1].includes(globals.me.id_rol)">
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/reservas"><i class="icofont-calendar"></i> <span class="nav-label">Reservas</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/mensajes/monfrague"><i class="fa fa-whatsapp"></i>
                            <span class="nav-label">Mensajes Monfragüe</span>
                            <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp_monfrague">{{globals.cmensajeswhatsapp_monfrague}}</span>
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/mensajes/tajo"><i class="fa fa-whatsapp"></i>
                            <span class="nav-label">Mensajes Tajo</span>
                            <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp_tajo">{{globals.cmensajeswhatsapp_tajo}}</span>
                        </a>
                    </li>
                    <li routerLinkActive="active" >
                        <a routerLink="/actividades/admin/actividades"><i class="icofont-opposite"></i> <span class="nav-label">Actividades</span></a>
                    </li>
                    <!-- <li routerLinkActive="active" >
                        <a routerLink="/actividades/admin/actividades-complementarias"><i class="icofont-opposite"></i> <span class="nav-label">Actividades Comp.</span></a>
                    </li> -->
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/colaboradores"><i class="icofont-tasks"></i> <span class="nav-label">Colaboradores</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/empresas"><i class="icofont-bag-alt"></i> <span class="nav-label">Empresas</span></a>
                    </li>
                    <li routerLinkActive="active" *ngIf="globals.me.id_rol == 0">
                        <a routerLink="/usuarios"><i class="icofont-users"></i> <span class="nav-label">Usuarios</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="[2].includes(globals.me.id_rol)">
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/reservas"><i class="icofont-calendar"></i> <span class="nav-label">Reservas</span></a>
                    </li>
                    <li routerLinkActive="active" >
                        <a routerLink="/actividades/admin/actividades"><i class="icofont-opposite"></i> <span class="nav-label">Actividades</span></a>
                    </li>
                    <li routerLinkActive="active" *ngIf="globals.me && globals.me.id_ubicacion == '1'">
                        <a routerLink="/actividades/admin/mensajes"><i class="fa fa-whatsapp"></i>
                            <span class="nav-label">Mensajes</span>
                            <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp_monfrague">{{globals.cmensajeswhatsapp_monfrague}}</span>
                        </a>
                    </li>
                    <li routerLinkActive="active" *ngIf="globals.me && globals.me.id_ubicacion == '2'">
                        <a routerLink="/actividades/admin/mensajes"><i class="fa fa-whatsapp"></i>
                            <span class="nav-label">Mensajes</span>
                            <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp_tajo">{{globals.cmensajeswhatsapp_tajo}}</span>
                        </a>
                    </li>
                    <!-- <li routerLinkActive="active" >
                        <a routerLink="/actividades/admin/actividades-complementarias"><i class="icofont-opposite"></i> <span class="nav-label">Actividades Comp.</span></a>
                    </li> -->
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/admin/colaboradores"><i class="icofont-tasks"></i> <span class="nav-label">Colaboradores</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="[3].includes(globals.me.id_rol)">
                    <li routerLinkActive="active">
                        <a routerLink="/actividades/usuario/reservas"><i class="icofont-calendar"></i> <span class="nav-label">Mis reservas</span></a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/actividades"><i class="icofont-opposite"></i> <span class="nav-label">Buscar actividades</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="[4].includes(globals.me.id_rol)">
                    <li routerLinkActive="active">
                        <a routerLink="/perfil"><span class="nav-label">Mis datos</span></a>
                    </li>
                </ng-container>
            </ng-container>

      </ul>
      <div class="clearfix"></div>
  </div>
</nav>