import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html'
})
export class AvisoLegalComponent implements OnInit {
  public titulo:string = 'Aviso legal';
  public texto:string = '';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Aviso legal');
    this.meta.updateTag({name: 'description', content: 'Aviso legal'});
    this.globals.Api('/configuracion-public', {idioma: this.globals.idioma}).subscribe(data => {
      if (!data || !data.textos_legales) {
        return;
      }
      this.texto = data.textos_legales.aviso_legal;
      this.appref.tick();
    });
  }

}
