import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Globals } from './classes/globals.class';
import { LoginComponent } from './backend/login/login.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { MenuComponent } from './controls/menu/menu.component';
import { HeaderComponent } from './controls/header/header.component';
import { FooterComponent } from './controls/footer/footer.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { EditUsuarioComponent } from './backend/edit-usuario/edit-usuario.component';
import { PerfilComponent } from './backend/perfil/perfil.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ActividadesComponent } from './frontend/actividades/actividades.component';
import { AdminReservasActividadesComponent } from './backend/actividades/admin/reservas/reservas.component';
import { AdminActividadesComponent } from './backend/actividades/admin/actividades/actividades.component';
import { AdminEmpresasActividadesComponent } from './backend/actividades/admin/empresas/empresas.component';
import { UsuariosReservasActividadesComponent } from './backend/actividades/usuario/reservas/reservas.component';
import { EditActividadComponent } from './backend/actividades/admin/edit-actividad/edit-actividad.component';
import { EditReservaActividadComponent } from './backend/actividades/admin/edit-reserva/edit-reserva.component';
import { AdminModalSeleccionActividadComponent } from './backend/actividades/admin/modal-seleccion-actividad/modal-seleccion-actividad.component';
import { AdminVerReservaActividadComponent } from './backend/actividades/admin/ver-reserva/ver-reserva.component';
import { ActividadComponent } from './frontend/actividad/actividad.component';
import { PoliticaPrivacidadComponent } from './frontend/legales/politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './frontend/legales/politica-cookies/politica-cookies.component';
import { AvisoLegalComponent } from './frontend/legales/aviso-legal/aviso-legal.component';
import { AutorizacionImagenComponent } from './frontend/legales/autorizacion-imagen/autorizacion-imagen.component';
import { AutorizacionComunicacionesComponent } from './frontend/legales/autorizacion-comunicaciones/autorizacion-comunicaciones.component';
import { CabeceraComponent } from './frontend/controls/cabecera/cabecera.component';
import { CancelacionComponent } from './frontend/cancelacion/cancelacion.component';
import { ActividadesComplementariasComponent } from './backend/actividades/admin/actividades-complementarias/actividades-complementarias.component';
import { ColaboradoresComponent } from './backend/actividades/admin/colaboradores/colaboradores.component';
import { CheckpointComponent } from './frontend/checkpoint/checkpoint.component';
import { MensajesComponent } from './backend/actividades/admin/mensajes/mensajes.component';
import { AltaColaboradorComponent } from './frontend/alta-colaborador/alta-colaborador.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    UsuariosComponent,
    EditUsuarioComponent,
    PerfilComponent,
    ConfigAppComponent,
    //Actividades
    ActividadesComponent,
    AdminReservasActividadesComponent,
    AdminActividadesComponent,
    AdminEmpresasActividadesComponent,
    UsuariosReservasActividadesComponent,
    EditActividadComponent,
    EditReservaActividadComponent,
    AdminModalSeleccionActividadComponent,
    AdminVerReservaActividadComponent,
    ActividadComponent,
    PoliticaPrivacidadComponent,
    PoliticaCookiesComponent,
    AvisoLegalComponent,
    AutorizacionImagenComponent,
    AutorizacionComunicacionesComponent,
    CabeceraComponent,
    CancelacionComponent,
    ActividadesComplementariasComponent,
    ColaboradoresComponent,
    CheckpointComponent,
    MensajesComponent,
    AltaColaboradorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
