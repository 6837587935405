import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let tinymce: any;
declare let L: any;
declare let blueimp: any;
declare let toastr: any;

@Component({
  selector: 'app-edit-actividad',
  templateUrl: './edit-actividad.component.html'
})
export class EditActividadComponent implements OnInit {

  public title:string = 'Nueva actividad';
  public data:any = {
    id: '', idioma: 'es', cambio: {}
  };
  public data_pt:any = {
    id: '', idioma: 'pt'
  };
  public imgsubir:string = '';
  public categorias = [];
  public ubicaciones = [];
  public localidades = [];
  public empresas = [];
  public idiomas = [{idioma: 'Español', id: 'es'}, {idioma: 'Portugués', id: 'pt'}];

  public docdata_es = {
    id: '',
    id_actividad: '',
    idioma: 'es',
    nombre: '',
    documento: '',
    filepath: '',
    extension: ''
  };
  public docdata_pt = {
    id: '',
    id_actividad: '',
    idioma: 'pt',
    nombre: '',
    documento: '',
    filepath: '',
    extension: ''
  };
  public docs_es = [];
  public docs_pt = [];
  public id_complementaria = '';
  public complementarias = [];
  public duplicar:boolean = (window.location.pathname.indexOf('duplicar') != -1);

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.checkrolredirect(3);
    $.btnmenu();
    $.disableinputnumberwheel();
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) {
      this.data = this.globals.passData;
      if (this.data.data_pt) this.data_pt = this.data.data_pt;
    }
    if (this.data.id == '') {
      this.title = 'Nueva actividad';
    } else {
      this.title = this.data.titulo;
      if (this.duplicar) {
        this.title = 'Copia de: ' + this.title;
        this.data.id = null;
      } else {
        this.CargarDocumentos();
      }
    }
    this.data.editlocalidad = false;
    if (this.globals.GetData('empresas-edit')) this.empresas = this.globals.GetData('empresas-edit');
    this.globals.Api('/usuarios', {id_rol: 2}).subscribe(data => {
      if (!data || !data.data || data.data.length == 0) {
        this.globals.SaveData([], 'empresas-edit'); return;
      }
      this.empresas = data.data;
      this.globals.SaveData(this.empresas, 'empresas-edit');
      if (!this.data.id_usuario && this.empresas.length) this.data.id_usuario = this.empresas[0].id;
    });
    if (this.globals.GetData('ubicaciones-edit')) this.ubicaciones = this.globals.GetData('ubicaciones-edit');
    this.globals.Api('/act-ubicaciones').subscribe(data => {
      if (!data || data.length == 0) {
        this.globals.SaveData([], 'ubicaciones-edit'); return;
      }
      this.ubicaciones = data;
      this.globals.SaveData(this.ubicaciones, 'ubicaciones-edit');
      if (!this.data.id_ubicacion && this.ubicaciones.length) this.data.id_ubicacion = this.ubicaciones[0].id;
    });
    if (this.globals.GetData('categorias-edit')) this.categorias = this.globals.GetData('categorias-edit');
    this.globals.Api('/act-categorias').subscribe(data => {
      if (!data || data.length == 0) {
        this.globals.SaveData([], 'categorias-edit'); return;
      }
      this.categorias = data;
      this.globals.SaveData(this.categorias, 'categorias-edit');
      if (!this.data.id_categoria && this.categorias.length) this.data.id_categoria = this.categorias[0].id;
    });
    if (this.globals.GetData('localidades')) this.localidades = this.globals.GetData('localidades');
    this.globals.Api('/localidades').subscribe(data => {
      if (!data || !data.length) {
        this.globals.SaveData([], 'localidades'); return;
      }
      this.localidades = data;
      this.globals.SaveData(this.localidades, 'localidades');
      if (!this.data.localidad && this.localidades.length) {
        if (this.data.municipio && this.data.municipio != '') {
          let item = this.localidades.find(el => {
            return this.globals.CleanedString(this.data.municipio) == this.globals.CleanedString(el.localidad);
          });
          if (item) this.data.localidad = item.localidad;
          if (!this.data.localidad || this.data.localidad == '') {
            item = this.data.localidad = this.localidades.find(el => {
              return this.globals.CleanedString(this.data.municipio).indexOf(this.globals.CleanedString(el.localidad)) != -1;
            })
            if (item) this.data.localidad = item.localidad;
          }
          if (!this.data.localidad || this.data.localidad == '') this.data.localidad = this.localidades[0].localidad;
        } else {
          this.data.localidad = this.localidades[0].localidad;
        }

        if (!this.localidades.find(el => el.localidad == this.data.localidad)) {
          this.data.editlocalidad = true;
        }
      }
    });
    if (this.globals.GetData('complementarias')) this.complementarias = this.globals.GetData('complementarias');
    this.globals.Api('/act-complementarias').subscribe(data => {
      if (!data || !data.data ||!data.data.length) {
        this.globals.SaveData([], 'complementarias'); return;
      }
      this.complementarias = data.data;
      this.globals.SaveData(this.complementarias, 'complementarias');
      if (this.complementarias.length) this.id_complementaria = this.complementarias[0].id;
    });
    tinymce.remove();
    $(document).ready(() => {
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 500,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable code',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | code',
        language: 'es'
      });
      $("#txtFechaActividad").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        format: "dd/mm/yyyy",
      });
      $("#txtFechaActividadReprogramada").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        format: "dd/mm/yyyy",
      });
      $('#txtFechaInscripciones').daterangepicker({
        alwaysShowCalendars: true,
        opens: 'left',
        autoUpdateInput: true,
        locale: {
          "format": "DD/MM/YYYY",
          "separator": " - ",
          "applyLabel": "Aplicar",
          "cancelLabel": "Cancel",
          "fromLabel": "Desde",
          "toLabel": "Hasta",
          "customRangeLabel": "Personalizado",
          "weekLabel": "S",
          "daysOfWeek": [
              "Do",
              "Lu",
              "Ma",
              "Mi",
              "Ju",
              "Vi",
              "Sa"
          ],
          "monthNames": [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
          ],
          "firstDay": 1
        }
      });
      $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
      });
      if (this.data.fecha_format) $('#txtFechaActividad').datepicker('setDate', this.data.fecha_format);
      if (this.data.fecha_inicio_reservas)  $('#txtFechaInscripciones').data('daterangepicker').setStartDate(moment(this.data.fecha_inicio_reservas));
      if (this.data.fecha_fin_reservas)  $('#txtFechaInscripciones').data('daterangepicker').setEndDate(moment(this.data.fecha_fin_reservas));
      this.CargarLightBox();
      $('.galeria-imagenes').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('.galeria-imagenes .ui-sortable-handle').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/act-actividades-imagenes-order', {id_actividad: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
    });
  }

  CargarDocumentos() {
    this.docs_es = [];
    this.globals.Api('/act-actividades-documentos', {id_actividad: this.data.id, idioma: 'es'}).subscribe(data => {
      if (!data || !data.length) return;
      this.docs_es = data;
    });
    this.docs_pt = [];
    this.globals.Api('/act-actividades-documentos', {id_actividad: this.data.id, idioma: 'pt'}).subscribe(data => {
      if (!data || !data.length) return;
      this.docs_pt = data;
    });
  }

  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/act-borrar-imagen', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }

  CargarLightBox() {
    document.querySelectorAll('.lightBoxGallery').forEach(function(item:any) {
      item.onclick = function (event:any) {
        event.stopPropagation();
        event.preventDefault();
        event = event || window.event
        var target = event.target || event.srcElement
        var link = target.src ? target.parentNode : target
        var options = { index: link, event: event };
        blueimp.Gallery(item.querySelectorAll('.link-gallery'), options);
      };
    });
  }
  //Galeria de imagenes
  AddImagen(e:any) {
    e.stopPropagation();
    e.preventDefault();
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        // this.data.imagen = base64;
        // this.imgsubir = base64;
        this.appref.tick();
        if (!this.data.id || this.data.id == '') {
          if (!this.data.imagenes) this.data.imagenes = [];
          this.data.imagenes.push({imagen: base64});
          this.CargarLightBox();
          return;
        }
        $.buttonloading('btn-add-imagen', 'Enviando');
        this.globals.Api('/act-actividades-imagenes-add', {id_actividad: this.data.id, imagen: base64}).subscribe(data => {
          $.buttoncancelloading('btn-add-imagen');
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          if (!this.data.imagenes) this.data.imagenes = [];
          this.data.imagenes.push({id: data.value, imagen: base64});
          this.CargarLightBox();
        }, error => {
          $.buttoncancelloading('btn-add-imagen');
        });

      });
    });
    input.click();
  }
  DelImagen(event:any, item:any) {
    event.stopPropagation();
    event.preventDefault();
    if (!item || !item.id) {
      this.data.imagenes.splice(this.data.imagenes.findIndex(el => el.imagen == item.imagen), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-actividades-imagenes-del', {id_actividad: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.imagenes.splice(this.data.imagenes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  //Galeria de cómo lo pasamos
  AddImagenComoLoPasamos(e:any) {
    e.stopPropagation();
    e.preventDefault();
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        // this.data.imagen = base64;
        // this.imgsubir = base64;
        this.appref.tick();
        if (!this.data.id || this.data.id == '') {
          if (!this.data.imagenes_comolopasamos) this.data.imagenes_comolopasamos = [];
          this.data.imagenes_comolopasamos.push({imagen: base64});
          this.CargarLightBox();
          return;
        }
        $.buttonloading('btn-add-imagen-clp', 'Enviando');
        this.globals.Api('/act-actividades-imagenes-comolopasamos-add', {id_actividad: this.data.id, imagen: base64}).subscribe(data => {
          $.buttoncancelloading('btn-add-imagen-clp');
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          if (!this.data.imagenes_comolopasamos) this.data.imagenes_comolopasamos = [];
          this.data.imagenes_comolopasamos.push({id: data.value, imagen: base64});
          this.CargarLightBox();
        }, error => {
          $.buttoncancelloading('btn-add-imagen-clp');
        });

      });
    });
    input.click();
  }
  DelImagenComoLoPasamos(event:any, item:any) {
    event.stopPropagation();
    event.preventDefault();
    if (!item || !item.id) {
      this.data.imagenes_comolopasamos.splice(this.data.imagenes_comolopasamos.findIndex(el => el.imagen == item.imagen), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-actividades-imagenes-comolopasamos-del', {id_actividad: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.imagenes_comolopasamos.splice(this.data.imagenes_comolopasamos.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  AddVideoComoLoPasamos(e:any) {
    e.stopPropagation();
    e.preventDefault();
    let input = this.globals.FileInput('.mp4', event => {
      let filepath = input.value;
      let extension = '.'+input.value.split('.')[input.value.split('.').length-1];
      extension = extension.toLocaleLowerCase();
      this.globals.Base64(input, base64 => {
        this.data.video = base64;
        this.data.vid = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  DelVideoComoLoPasamos() {
    if (!this.data.id || this.data.id == '') {
      this.data.video = null;
      return;
    }
    swal({
      title: "¿Borrar video?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-actividades-video-del', {id_actividad: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.video = null;
        this.appref.tick();
      });
    });
  }

  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  AbrirMapa() {
    $('#modal-mapa').modal('show');
    $('#mapa').remove();
    $('#mapa-container').html('<div id="mapa" style="width:100%;height:400px;"></div>');
    $('#mapa').removeAttr('class');
    setTimeout(() => {
      let point = [40.036198, -6.088190];
      $.localizaciontemp = point[0]+','+point[1];
      // let m = L.DomUtil.get('mapa'); if (m != null) { m._leaflet_id = null; }
      var mymap = L.map('mapa').setView(point, 8);
      if (this.data.localizacion_maps) {
        point = [this.data.localizacion_maps.split(',')[0], this.data.localizacion_maps.split(',')[1]];
        $.localizaciontemp = point[0]+','+point[1];
        mymap.setView(point, 14);
      }
      L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(mymap);
      var marker = L.marker(point).addTo(mymap);
      mymap.on('click', function(e) {
        marker.setLatLng([e.latlng.lat, e.latlng.lng]);
        $.localizaciontemp = e.latlng.lat+','+e.latlng.lng;
      });
    }, 500);
  }
  SelecLocalizacion() {
    if ($.localizaciontemp) this.data.localizacion_maps = $.localizaciontemp;
    $('#modal-mapa').modal('hide');
    this.appref.tick();
  }

  AddTurno(event:any) {
    if (!event.target.value) return;
    let turno = event.target.value;
    let turnos = (this.data.turnos ? this.data.turnos.split(',') : []);
    if (turnos.indexOf(turno) == -1) {
      turnos.push(turno);
    }
    this.data.turnos = (turnos.length ? turnos.join(',') : '');
    event.target.value = '';
    this.appref.tick();
  }
  DelTurno(item) {
    if (this.data.turnos) {
      let turnos = this.data.turnos.split(',');
      turnos.splice(turnos.indexOf(item), 1);
      this.data.turnos = (turnos.length ? turnos.join(',') : '');
      this.appref.tick();
    }
  }

  SelectDocES() {
    let accept = '*';
    accept = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*';
    let input = this.globals.FileInput(accept, event => {
      this.docdata_es.filepath = input.value;
      this.docdata_es.extension = '.'+input.value.split('.')[input.value.split('.').length-1];
      this.docdata_es.extension = this.docdata_es.extension.toLocaleLowerCase();
      if (this.docdata_es.extension == '.jpg' || this.docdata_es.extension == '.jpeg' || this.docdata_es.extension == '.gif' || this.docdata_es.extension == '.png') {
        this.globals.Base64Image(input, base64 => {
          this.docdata_es.documento = base64;
          this.appref.tick();
        });
      } else {
        if (input.files[0].size / 1024 / 1024 > 2) {
            input.value = '';
            swal('Tamaño excedido!', 'El tamaño para los archivos seleccionados debe ser inferior a 2 MB', 'warning');
            this.appref.tick();
            return;
        }
        this.globals.Base64(input, base64 => {
          this.docdata_es.documento = base64;
          this.appref.tick();
        });
      }
    });
    input.click();
  }
  SelectDocPT() {
    let accept = '*';
    accept = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*';
    let input = this.globals.FileInput(accept, event => {
      this.docdata_pt.filepath = input.value;
      this.docdata_pt.extension = '.'+input.value.split('.')[input.value.split('.').length-1];
      this.docdata_pt.extension = this.docdata_pt.extension.toLocaleLowerCase();
      if (this.docdata_pt.extension == '.jpg' || this.docdata_pt.extension == '.jpeg' || this.docdata_pt.extension == '.gif' || this.docdata_pt.extension == '.png') {
        this.globals.Base64Image(input, base64 => {
          this.docdata_pt.documento = base64;
          this.appref.tick();
        });
      } else {
        if (input.files[0].size / 1024 / 1024 > 2) {
            input.value = '';
            swal('Tamaño excedido!', 'El tamaño para los archivos seleccionados debe ser inferior a 2 MB', 'warning');
            this.appref.tick();
            return;
        }
        this.globals.Base64(input, base64 => {
          this.docdata_pt.documento = base64;
          this.appref.tick();
        });
      }
    });
    input.click();
  }
  BorrarDoc(item:any) {
    swal({
      title: `¿Borrar documento?`,
      text: "No se podrán recuperar los datos!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-borrar-actividad-documento', {id: item.id, id_actividad: this.data.id, idioma: item.idioma}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (item.idioma == 'es') {
          this.docs_es.splice(this.docs_es.findIndex(el => el.id == item.id), 1);
        } else {
          this.docs_pt.splice(this.docs_pt.findIndex(el => el.id == item.id), 1);
        }
        this.appref.tick();
      });
    });
  }
  EditarDocNombre(item:any, event:any) {
    if (event.target.innerText == item.nombre) return;
    this.globals.Api('/act-editar-actividad-documento', {
      id: item.id,
      id_actividad: this.data.id,
      idioma: item.idioma,
      nombre: event.target.innerText
    }).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
    }, error => {swal('Error', 'Se ha producido un error', 'error');});
  }
  GuardarDocES() {
    if (
      this.docdata_es.nombre == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    this.docdata_es.id_actividad = this.data.id;

    if (this.docdata_es.id == '') {
      $.buttonloading('btn-adjuntar-doc-es', 'Enviando');
      this.globals.Api('/act-crear-actividad-documento', this.docdata_es).subscribe(data => {
        $.buttoncancelloading('btn-adjuntar-doc-es');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.docdata_es.nombre = '';
        this.docdata_es.documento = '';
        this.docdata_es.filepath = '';
        this.docdata_es.extension = '';
        swal('Guardado', 'Documento guardado correctamente', 'success');
        this.CargarDocumentos();
      }, error => {
        $.buttoncancelloading('btn-adjuntar-doc-es');
      });
    }
  }
  GuardarDocPT() {
    if (
      this.docdata_pt.nombre == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    this.docdata_pt.id_actividad = this.data.id;

    if (this.docdata_pt.id == '') {
      $.buttonloading('btn-adjuntar-doc-es', 'Enviando');
      this.globals.Api('/act-crear-actividad-documento', this.docdata_pt).subscribe(data => {
        $.buttoncancelloading('btn-adjuntar-doc-es');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.docdata_pt.nombre = '';
        this.docdata_pt.documento = '';
        this.docdata_pt.filepath = '';
        this.docdata_pt.extension = '';
        swal('Guardado', 'Documento guardado correctamente', 'success');
        this.CargarDocumentos();
      }, error => {
        $.buttoncancelloading('btn-adjuntar-doc-es');
      });
    }
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/admin/actividades')).then();
  }
  Guardar() {
    this.data.descripcion = tinymce.EditorManager.get('txtdescripcion').getContent();
    if (
      !this.data.titulo
      || !this.data.descripcion
      || !this.data.id_usuario
      || !this.data.id_ubicacion
      || !this.data.id_categoria
      || !this.data.localidad
      || this.data.titulo == ''
      || this.data.descripcion == ''
      || this.data.id_usuario == ''
      || this.data.id_ubicacion == ''
      || this.data.id_categoria == ''
      || this.data.localidad == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    this.data.fecha_inicio_reservas = moment($('#txtFechaInscripciones').data('daterangepicker').startDate).format('YYYY-MM-DD');
    this.data.fecha_fin_reservas = moment($('#txtFechaInscripciones').data('daterangepicker').endDate).format('YYYY-MM-DD');

    if ($('#txtFechaActividad input').val() != '') {
      this.data.fecha = moment($('#txtFechaActividad').datepicker('getDate')).format('YYYY-MM-DD');
    } else {this.data.fecha = null;}
    if ($('#txtFechaActividadReprogramada input').val() != '') {
      if (!this.data.cambio) this.data.cambio = {};
      this.data.cambio.fecha = moment($('#txtFechaActividadReprogramada').datepicker('getDate')).format('YYYY-MM-DD');
    } else {
      if (!this.data.cambio) this.data.cambio = {};
      this.data.cambio.fecha = null;
    }

    if (this.duplicar) {
      this.data.url_imagen = this.data.imagen;
      if (this.data.imagenes) {
        this.data.dup_imagenes = this.data.imagenes;
        this.data.imagenes = null;
      }
      if (this.data.imagenes_comolopasamos) {
        this.data.dup_imagenes_comolopasamos = this.data.imagenes_comolopasamos;
        this.data.imagenes_comolopasamos = null;
      }
    } else {
      this.data.img = null;
      if (this.imgsubir != '') this.data.img = this.imgsubir;
    }

    this.data.borrado = $('#chkborrado').prop('checked');
    this.data.reserva_ninos = $('#chkreservaninos').prop('checked');

    if (this.data_pt && this.data_pt.titulo) {
      this.data.data_pt = this.data_pt;
      this.data.data_pt.descripcion = tinymce.EditorManager.get('txtdescripcionpt').getContent();
    }

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('btnGuardar', 'Enviando');
      $.buttonloading('btnGuardar2', 'Enviando');
      this.globals.Api('/act-crear-actividad', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
      });
    } else {
      $.buttonloading('btnGuardar', 'Enviando');
      $.buttonloading('btnGuardar2', 'Enviando');
      this.globals.Api('/act-editar-actividad', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        if (this.data.url_video) {
          this.data.video = this.data.url_video;
        }
      }, error => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
      });
    }
  }

  AddComplementaria() {
    if (!this.id_complementaria || this.id_complementaria == '') return;
    if (!this.data.complementarias || !this.data.complementarias.length) this.data.complementarias = [];
    this.data.complementarias.push({
      id: this.id_complementaria,
      titulo: this.complementarias.find(el => el.id == this.id_complementaria).titulo
    });
  }
  DelComplementaria(item:any) {
    this.data.complementarias.splice(this.data.complementarias.findIndex(el => el.id == item.id), 1);
  }

  ModalSeleccionaActividad() {
    if ($.RefreshDatosSeleccionActividad) $.RefreshDatosSeleccionActividad(this.data.id);
    $('#modal-seleccion-actividad').modal('show');
  }
  SeleccionarRelacionada(item:any) {
    if (this.data.relacionadas && this.data.relacionadas.find(el => el.id == item.id)) {
      toastr.warning(`Activdad ya añadida como relacionada`, 'Ya añadida');
      return;
    }
    swal({
      title: "¿Agregar como actividad relacionada?",
      text: 'Pasará a ser una actividad secundaria',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-warning",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-actividades-relacionadas-add', {id: this.data.id, id_actividad_relacionada: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (!this.data.relacionadas) this.data.relacionadas = [];
        this.data.relacionadas.push(item);
        this.appref.tick();
      });
    });
  }
  DelRelacionada(item:any) {
    swal({
      title: "¿Eliminar esta actividad relacionada?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-warning",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-actividades-relacionadas-del', {id: this.data.id, id_actividad_relacionada: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (!this.data.relacionadas) this.data.relacionadas = [];
        this.data.relacionadas.splice(this.data.relacionadas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
