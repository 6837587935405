import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;

@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html'
})
export class MensajesComponent implements OnInit {
  public url_iframe:any = '';
  public zona:any = '';

  constructor(
    private activeRoute: ActivatedRoute,
    public sanitize: DomSanitizer,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
    this.activeRoute.params.subscribe(routeParams => {
      this.CargarDatos();
    });
  }
  CargarDatos() {
    if (window.location.pathname.indexOf('monfrague') != -1) this.zona = 'monfrague';
    if (window.location.pathname.indexOf('tajo') != -1) this.zona = 'tajo';
    
    this.globals.Api('/configuracion').subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      let timestamp = Date.now();
      if (this.zona == 'monfrague') {
        if (!data.monfrague || !data.monfrague.whatsapp_fbid) return;
        this.url_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
          'https://manager.pecesgordosestudio.es/whatsapp-web?fbid='+data.monfrague.whatsapp_fbid+'&token=DX9FO2BV46QYVD53JQ02&t='+timestamp
        );
      }
      if (this.zona == 'tajo') {
        if (!data.tajo || !data.tajo.whatsapp_fbid) return;
        this.url_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
          'https://manager.pecesgordosestudio.es/whatsapp-web?fbid='+data.tajo.whatsapp_fbid+'&token=DX9FO2BV46QYVD53JQ02&t='+timestamp
        );
      }
      if (this.zona == '') {
        if (this.globals.me.id_ubicacion == '1') {
          if (!data.monfrague || !data.monfrague.whatsapp_fbid) return;
          this.url_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
            'https://manager.pecesgordosestudio.es/whatsapp-web?fbid='+data.monfrague.whatsapp_fbid+'&token=DX9FO2BV46QYVD53JQ02&t='+timestamp
          );
        }
        if (this.globals.me.id_ubicacion == '2') {
          if (!data.tajo || !data.tajo.whatsapp_fbid) return;
          this.url_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
            'https://manager.pecesgordosestudio.es/whatsapp-web?fbid='+data.tajo.whatsapp_fbid+'&token=DX9FO2BV46QYVD53JQ02&t='+timestamp
          );
        }
      }
      // window.addEventListener('message', function(event) {
      //     if (!event.data || !event.data.height) return;
      //     $('iframe').height((event.data.height + 100) + 'px');
      // });
    });
  }

}
