<div id="wrapper">
  <app-menu></app-menu>

  <div id="page-wrapper" class="gray-bg dashbard-1">
    <app-header></app-header>

    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-6 col-md-4 col-xs-12">
        <h2>Mis datos</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
              <a routerLink="/dashboard">Inicio</a>
          </li>
          <li class="breadcrumb-item active">
              <strong>Mis datos</strong>
          </li>
        </ol>
      </div>
      <div class="col-lg-6 col-md-4 col-xs-12 col-botones">
          <a class="btn btn-default" routerLink="/dashboard">Cancelar</a>
          <a id="btnGuardar" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
      </div>
    </div>
    <div class="row animated fadeInRight">
      <div class="col-lg-12 col-xs-12">

        <div class="row m-t-30">
          <div class="col-lg-4 col-md-12">
            <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
              <i class="icofont-camera-alt"></i>
            </div>
            <div class="img-preview-container cursor relative" (click)="SelecImagen()">
              <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
              <small class="block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
              <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
            </div>
          </div>
          <div class="col-lg-8 col-md-12">

            <div class="p-l-10">
              <p>Rol: <strong class="font-16 m-l-10">{{data.rol}}</strong></p>
            </div>
            <div class="row m-t-10">
              <div class="col-lg-4 col-md-6 col-xs-12">
                <div class="form-group">
                  <label>Nombre <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <div class="form-group">
                  <label>Apellidos <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <div class="form-group">
                  <label>Nombre de tu negocio</label>
                  <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                </div>
              </div>
            </div>

            <div class="row"
            *ngIf="globals.config && globals.config.tipoapp == 'gimnasio'"
            >
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Nº Usuario</label>
                  <input type="text" class="form-control" [(ngModel)]="data.referencia" maxlength="50"
                    [readonly]="globals.config && globals.config.tipoapp == 'gimnasio'">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Fecha nacimiento *</label>
                  <div class="input-group date" id="fechanacimiento">
                      <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                      <input type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-xs-12 my-auto">
                <div class="m-t-10" *ngIf="data.edad">Edad: {{data.edad}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-xs-12">
                <div class="form-group">
                  <label>Direcci&oacute;n</label>
                  <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                </div>
              </div>
              <div class="col-md-3 col-xs-12">
                <div class="form-group">
                  <label>C&oacute;digo postal</label>
                  <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                    (change)="DatosLocalidad()">
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label>Localidad</label>
                  <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-xs-12">
                <div class="form-group">
                  <label>Tel&eacute;fono</label>
                  <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                </div>
              </div>
            </div>
            <div class="ibox border">
              <div class="ibox-title"><i class="fa fa-sign-in"></i> Acceso al sistema de reservas</div>
              <div class="ibox-content">
                <div class="row">
                  <div class="col-md-6 col-xs-12">
                    <div class="form-group">
                      <label><i class="fa fa-envelope"></i> Email <span class="text-danger">*</span></label>
                      <input type="email" class="form-control"[(ngModel)]="data.email" maxlength="80">
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-12">
                    <div class="form-group">
                      <a class="btn btn-sm btn-warning text-white m-t-30" (click)="EditarClave()"><i class="fa fa-lock"></i> Cambiar contraseña</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-botones">
      <a class="btn btn-default" routerLink="/dashboard">Cancelar</a>
      <a id="btnGuardar2" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
    </div>

    <app-footer></app-footer>
  </div>

</div>
<div class="modal inmodal fade" id="modal-editar-clave-usuario" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title">{{data.title}}</h4>
        </div>
        <div class="modal-body">
            <p>Nombre: <strong>{{data.nombre}}</strong></p>
            <p>Email: <strong>{{data.email}}</strong></p>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Nueva contrase&ntilde;a <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarClaveUsuarioModal" type="button" class="btn btn-primary" (click)="GuardarClaveUsuario()">Guardar</button>
        </div>
        </div>
    </div>
</div>