<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
      <app-header></app-header>

      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-md-6 col-sm-6 col-xs-12">
            <h2>
              <i class="icofont-calendar"></i>
              {{title}}
            </h2>
            <h4 *ngIf="data.referencia" class="font-20 text-secondary">{{data.referencia}} <span *ngIf="data.asistencia" class="text-success m-l-20"><i class="fa fa-check"></i> Asistencia validada</span></h4>
            <h4 *ngIf="data.colaborador" class="font-20"><i class="icofont-tasks"></i> {{data.colaborador}}</h4>
            <ol class="breadcrumb m-l-10">
                <li class="breadcrumb-item">
                    <a routerLink="/dashboard">Inicio</a>
                </li>
                <li class="breadcrumb-item">
                    <a routerLink="/actividades/admin/reservas">Reservas</a>
                </li>
                <li class="breadcrumb-item active">
                    <strong>{{title}}</strong>
                </li>
            </ol>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 col-botones" style="flex-wrap:wrap;">
            <ng-container *ngIf="data && data.id != ''">
                <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Acciones
                    </button>
                    <ul class="dropdown-menu" style="width:260px">
                        <ng-container *ngIf="data && data.id != '' && data.id_estado == 2 && !data.asistencia">
                            <li><a (click)="ValidarAsistencia()">Validar asistencia <i class="fa fa-check text-success m-l-10"></i></a></li>
                        </ng-container>
                        <ng-container *ngIf="data && data.id != '' && data.id_estado == 2">
                            <li><a (click)="ReConfirmar()">Reenviar confirmaci&oacute;n <i class="fa fa-envelope-o m-l-10"></i></a></li>
                        </ng-container>
                        <ng-container *ngIf="data && data.id != '' && data.id_estado == 2 && globals.me.id_rol == 0">
                            <li><a (click)="ReConfirmarWhatsApp()">Reenviar WhatsApp confirmaci&oacute;n <i class="fa fa-whatsapp m-l-10"></i></a></li>
                        </ng-container>
                        <li role="separator" class="divider"></li>
                        <ng-container *ngIf="data && data.id != '' && data.id_estado != 2">
                            <li><a class="btn btn-success text-white" (click)="Confirmar()">Confirmar <i class="fa fa-check"></i></a></li>
                            <li role="separator" class="divider"></li>
                        </ng-container>
                        <ng-container *ngIf="data && data.id != '' && (data.id_estado == 1 || data.id_estado == 2)">
                            <li><a class="btn btn-warning text-white" (click)="EnEspera()">Pasar a espera</a></li>
                            <li role="separator" class="divider"></li>
                        </ng-container>
                        <li><a class="btn btn-danger text-white" (click)="CancelarReserva()">Cancelar</a></li>
                    </ul>
                </div>
                <div class="separador-vertical"></div>
            </ng-container>
            <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
            <a id="btnGuardar" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
        </div>
      </div>
      <div class="animated fadeInRight">

        <div class="row m-t-10">
          <div class="col-lg-5 col-md-6 col-md-12">

            <div class="ibox border">
                <div class="ibox-title"><i class="icofont-opposite"></i> Actividad</div>
                <div class="ibox-content">
                    <div class="text-center">
                        <a class="btn btn-default cursor m-b-20" (click)="ModalSeleccionaActividad()">
                            <i class="fa fa-search"></i> Seleccionar actividad
                        </a>
                    </div>
                    <ng-container *ngIf="actividad && actividad.id != ''">
                        <div class="actividad-card" [style.background-image]="actividad.imagen ? 'url('+actividad.imagen+')' : null">
                            <div class="fecha">
                                <div>{{actividad.fecha_mes}} {{actividad.fecha_dia}}</div>
                            </div>
                            <div class="actividad-card-body">
                                <h2 [innerHTML]="actividad.titulo" class="titulo"></h2>
                                <div>{{actividad.categoria}}</div>
                                <div><i class="icofont-google-map text-white"></i> {{actividad.ubicacion}}</div>
                                <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="aforo">
                                    <span>{{actividad.aforo}} Pers.</span>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="ibox border hidden">
                <div class="ibox-title">Estado</div>
                <div class="ibox-content">
                    <div class="form-group">
                        <select class="form-control" [(ngModel)]="data.id_estado">
                        <option *ngFor="let item of estados" [value]="item.id">{{item.estado}}</option>
                        </select>
                    </div>
                    <!-- <div class="form-group checkbox {{data && data.id != '' ? null : 'hidden'}}">
                        <input type="checkbox" id="chkNotificar">
                        <label for="chkNotificar">Notificar al usuario al cambiar de estado</label>
                    </div> -->
                </div>
            </div>

          </div>
          <div class="col-lg-7 col-md-6 col-md-12">

            <div class="ibox border">
                <div class="ibox-title"><i class="fa fa-user"></i> Persona que se inscribe</div>
                <div class="ibox-content">
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                            <label class="text-primary">Nombre *</label>
                            <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80" autocomplete="offff">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                            <label class="text-primary">Apellidos *</label>
                            <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80" autocomplete="offff">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                            <label class="text-primary">DNI / NIE *</label>
                            <input type="text" class="form-control" [(ngModel)]="data.dni" maxlength="15" autocomplete="offff"
                                placeholder="{{globals.idioma == 'pt' ? 'Exemplo: 12345678-9ZZ1' : ''}}">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                            <label class="text-primary">Sexo *</label>
                            <select class="form-control" [(ngModel)]="data.sexo" autocomplete="offff">
                                <option value="F">Femenino</option>
                                <option value="M">Masculino</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!data.idioma || data.idioma == 'es'">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">C&oacute;digo postal *</label>
                                <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="8" autocomplete="offff"
                                    placeholder="{{globals.idioma == 'pt' ? 'Exemplo: 2400-083' : ''}}"
                                    (change)="DatosLocalidad()">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                <div class="mobile-hidden-block p-t-30"></div>
                                <!-- <span [innerHTML]="data.localidad+', '+data.provincia" class="font-bold"></span> -->
                                <div id="lbllocalidad" class="font-bold"></div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data.idioma == 'pt'">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">C&oacute;digo postal *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="8" autocomplete="offff"
                                        placeholder="{{globals.idioma == 'pt' ? 'Exemplo: 2400-083' : ''}}"
                                        (change)="DatosLocalidad()">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Provincia *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80" autocomplete="offff">
                                </div>
                                <div class="form-group">
                                    <label class="text-primary">Localidad *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80" autocomplete="offff">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Tel&eacute;fono m&oacute;vil *</label>
                                <!-- <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20" autocomplete="offff"> -->
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <select class="form-group mb-0" [(ngModel)]="data.prefijo_telefono">
                                                <option value="+34">+34</option>
                                                <option value="+351">+351</option>
                                            </select>
                                            <img src="assets/img/flags/32/Spain.png" alt="" *ngIf="data.prefijo_telefono == '+34'" style="height:20px;margin-left:4px;vertical-align:middle;">
                                            <img src="assets/img/flags/32/Portugal.png" alt="" *ngIf="data.prefijo_telefono == '+351'" style="height:20px;margin-left:4px;vertical-align:middle;">
                                        </span>
                                    </div>
                                    <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20" autocomplete="offff"
                                        (keyup)="KeyDownTel()"
                                        (blur)="KeyDownTel()"
                                        >
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                            <label class="text-primary">Email *</label>
                            <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="80" autocomplete="offff">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Idioma *</label>
                                <select class="form-control" [(ngModel)]="data.idioma">
                                    <option value="es" selected>Español</option>
                                    <option value="pt">Portugués</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12" *ngIf="actividad && actividad.id != '' && actividad.turnos">
                            <div class="form-group">
                                <label class="text-primary">Turno *</label>
                                <!-- <select class="form-control" [(ngModel)]="data.turno">
                                    <option [value]="item" *ngFor="let item of actividad.turnos.split(',')">{{item}}</option>
                                </select> -->

                                <input type="text" class="form-control" list="turnos" [(ngModel)]="data.turno" maxlength="50">
                                <datalist id="turnos">
                                  <option *ngFor="let item of actividad.turnos.split(',')" [value]="item">{{item}}</option>
                                </datalist>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ibox border">
                <a class="ibox-title block" data-toggle="collapse" href="#persona2">
                    <i class="fa fa-user"></i> Acompa&ntilde;ante 1
                    <div class="ibox-tools">
                        <select class="form-control inline-block m-r-10" style="width:auto;margin-top:-8px"
                            [(ngModel)]="data.reservas[0].adulto"
                            onclick="event.preventDefault();event.stopPropagation();return false;">
                            <option value="1">{{globals.Literal('Adulto')}}</option>
                            <option value="0">{{globals.Literal('Niño')}}</option>
                        </select>
                        <a><i class="fa fa-chevron-up"></i></a>
                    </div>
                </a>
                <div id="persona2" class="ibox-content collapse {{data.personas && data.personas.length > 0 ? 'show' : null}}">
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Nombre *</label>
                                <input id="txtnombre2" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].nombre">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Apellidos *</label>
                                <input id="txtapellidos2" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].apellidos">
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="data.reservas[0].adulto == '1'">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">DNI / NIE *</label>
                                <input id="txtdni2" type="text" class="form-control" maxlength="15" autocomplete="offff" [(ngModel)]="data.reservas[0].dni">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">Sexo *</label>
                                <select id="cmbsexo2" class="form-control" autocomplete="offff" [(ngModel)]="data.reservas[0].sexo">
                                    <option value="F">Femenino</option>
                                    <option value="M">Masculino</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!data.idioma || data.idioma == 'es'">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">C&oacute;digo postal</label>
                                        <input id="txtcodpostal2" type="text" class="form-control" maxlength="8" autocomplete="offff" [(ngModel)]="data.reservas[0].codpostal"
                                            (change)="DatosLocalidad($event, 'lbllocalidad2')">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                    <div class="mobile-hidden-block p-t-30"></div>
                                    <div id="lbllocalidad2" class="font-bold"></div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="data.idioma == 'pt'">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">C&oacute;digo postal</label>
                                        <input id="txtcodpostal2" type="text" class="form-control" maxlength="8" autocomplete="offff" [(ngModel)]="data.reservas[0].codpostal">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">Provincia</label>
                                        <input id="txtprovincia2" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].provincia">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-primary">Localidad</label>
                                        <input id="txtlocalidad2" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].localidad">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Tel&eacute;fono m&oacute;vil</label>
                                    <input id="txttelefono2" type="tel" class="form-control" maxlength="20" autocomplete="offff"
                                    [(ngModel)]="data.reservas[0].telefono"
                                    (keyup)="KeyDownTel(data.reservas[0])"
                                    (blur)="KeyDownTel(data.reservas[0])">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Email</label>
                                    <input id="txtemail2" type="email" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[0].email">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="ibox border">
                <a class="ibox-title block" data-toggle="collapse" href="#persona3">
                    <i class="fa fa-user"></i> Acompa&ntilde;ante 2
                    <div class="ibox-tools">
                        <select class="form-control inline-block m-r-10" style="width:auto;margin-top:-8px"
                            [(ngModel)]="data.reservas[1].adulto"
                            onclick="event.preventDefault();event.stopPropagation();return false;">
                            <option value="1">{{globals.Literal('Adulto')}}</option>
                            <option value="0">{{globals.Literal('Niño')}}</option>
                        </select>
                        <a><i class="fa fa-chevron-up"></i></a>
                    </div>
                </a>
                <div id="persona3" class="ibox-content collapse {{data.personas && data.personas.length > 1 ? 'show' : null}}">
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Nombre *</label>
                                <input id="txtnombre3" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].nombre">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Apellidos *</label>
                                <input id="txtapellidos3" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].apellidos">
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="data.reservas[1].adulto == '1'">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">DNI / NIE *</label>
                                <input id="txtdni3" type="text" class="form-control" maxlength="15" autocomplete="offff" [(ngModel)]="data.reservas[1].dni">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">Sexo *</label>
                                <select id="cmbsexo3" class="form-control" autocomplete="offff" [(ngModel)]="data.reservas[1].sexo">
                                    <option value="F">Femenino</option>
                                    <option value="M">Masculino</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!data.idioma || data.idioma == 'es'">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">C&oacute;digo postal</label>
                                        <input id="txtcodpostal3" type="text" class="form-control" maxlength="8" autocomplete="offff" [(ngModel)]="data.reservas[1].codpostal"
                                            (change)="DatosLocalidad($event, 'lbllocalidad3')">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                    <div class="mobile-hidden-block p-t-30"></div>
                                    <div id="lbllocalidad3" class="font-bold"></div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="data.idioma == 'pt'">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">C&oacute;digo postal</label>
                                        <input id="txtcodpostal3" type="text" class="form-control" maxlength="8" autocomplete="offff" [(ngModel)]="data.reservas[1].codpostal">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">Provincia</label>
                                        <input id="txtprovincia3" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].provincia">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-primary">Localidad</label>
                                        <input id="txtlocalidad3" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].localidad">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Tel&eacute;fono m&oacute;vil</label>
                                    <input id="txttelefono3" type="tel" class="form-control" maxlength="20" autocomplete="offff"
                                    [(ngModel)]="data.reservas[1].telefono"
                                    (keyup)="KeyDownTel(data.reservas[1])"
                                    (blur)="KeyDownTel(data.reservas[1])">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Email</label>
                                    <input id="txtemail3" type="email" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[1].email">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="ibox border">
                <a class="ibox-title block" data-toggle="collapse" href="#persona4">
                    <i class="fa fa-user"></i> Acompa&ntilde;ante 3
                    <div class="ibox-tools">
                        <select class="form-control inline-block m-r-10" style="width:auto;margin-top:-8px"
                            [(ngModel)]="data.reservas[2].adulto"
                            onclick="event.preventDefault();event.stopPropagation();return false;">
                            <option value="1">{{globals.Literal('Adulto')}}</option>
                            <option value="0">{{globals.Literal('Niño')}}</option>
                        </select>
                        <a><i class="fa fa-chevron-up"></i></a>
                    </div>
                </a>
                <div id="persona4" class="ibox-content collapse {{data.personas && data.personas.length > 2 ? 'show' : null}}">
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Nombre *</label>
                                <input id="txtnombre4" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].nombre">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label class="text-primary">Apellidos *</label>
                                <input id="txtapellidos4" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].apellidos">
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="data.reservas[2].adulto == '1'">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">DNI / NIE *</label>
                                <input id="txtdni4" type="text" class="form-control" maxlength="15" autocomplete="offff" [(ngModel)]="data.reservas[2].dni">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                <label class="text-primary">Sexo *</label>
                                <select id="cmbsexo4" class="form-control" autocomplete="offff" [(ngModel)]="data.reservas[2].sexo">
                                    <option value="F">Femenino</option>
                                    <option value="M">Masculino</option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!data.idioma || data.idioma == 'es'">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">C&oacute;digo postal</label>
                                        <input id="txtcodpostal4" type="text" class="form-control" maxlength="8" autocomplete="offff" [(ngModel)]="data.reservas[2].codpostal"
                                            (change)="DatosLocalidad($event, 'lbllocalidad4')">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12 p-b-30 p-l-30 text-primary">
                                    <div class="mobile-hidden-block p-t-30"></div>
                                    <div id="lbllocalidad4" class="font-bold"></div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="data.idioma == 'pt'">
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">C&oacute;digo postal</label>
                                        <input id="txtcodpostal4" type="text" class="form-control" maxlength="8" autocomplete="offff" [(ngModel)]="data.reservas[2].codpostal">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="text-primary">Provincia</label>
                                        <input id="txtprovincia4" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].provincia">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-primary">Localidad</label>
                                        <input id="txtlocalidad4" type="text" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].localidad">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Tel&eacute;fono m&oacute;vil</label>
                                    <input id="txttelefono4" type="tel" class="form-control" maxlength="20" autocomplete="offff"
                                    [(ngModel)]="data.reservas[2].telefono"
                                    (keyup)="KeyDownTel(data.reservas[2])"
                                    (blur)="KeyDownTel(data.reservas[2])">
                                </div>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="text-primary">Email</label>
                                    <input id="txtemail4" type="email" class="form-control" maxlength="80" autocomplete="offff" [(ngModel)]="data.reservas[2].email">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

          </div>
        </div>
      </div>

      <div class="col-botones m-t-10">
        <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
        <a id="btnGuardar2" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
      </div>

      <app-footer></app-footer>
    </div>

  </div>

  <app-admin-modal-seleccion-actividad (seleccionar)="SeleccionarActividad($event)"></app-admin-modal-seleccion-actividad>