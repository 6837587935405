import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html'
})
export class AdminActividadesComponent implements OnInit {
  public datagrid = [];
  public params:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
    principales: true
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
  }

  CargarGrid(paginationload:boolean = true) {
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.6)',
    //   imageResizeFactor: 0.5
    // });
    if (this.globals.GetData('actividades-filtro')) this.params.filtro = this.globals.GetData('actividades-filtro');
    if (this.globals.GetData('actividades-page-index')) this.params.page_index = this.globals.GetData('actividades-page-index');
    if (this.globals.GetData('actividades-id-ubicacion')) this.params.id_ubicacion = this.globals.GetData('actividades-id-ubicacion');
    if (this.globals.GetData('actividades')) this.datagrid = this.globals.GetData('actividades');
    this.globals.Api('/act-actividades', this.params).subscribe(data => {
      // $.LoadingOverlay("hide", true);
      if (!data || !data.data || !data.data.length) {
        this.datagrid = [];
        this.globals.SaveData([], 'actividades');
        this.globals.SaveData('', 'actividades-filtro');
        this.globals.SaveData(1, 'actividades-page-index');
        return;
      }
      this.datagrid = data.data;
      this.globals.SaveData(this.datagrid, 'actividades');
      this.globals.SaveData(this.params.filtro, 'actividades-filtro');
      this.globals.SaveData(this.params.page_index, 'actividades-page-index');
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    }, error => {
      // $.LoadingOverlay("hide", true);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.globals.SaveData(this.params.filtro, 'actividades-filtro');
    this.globals.SaveData(this.params.page_index, 'actividades-page-index');
    this.CargarGrid(false);
  }
  FiltrarUbicacion(id_ubicacion:string = '') {
    this.params.id_ubicacion = id_ubicacion;
    this.params.page_index = 1;
    this.globals.SaveData(this.params.id_ubicacion, 'actividades-id-ubicacion');
    this.globals.SaveData(this.params.page_index, 'actividades-page-index');
    this.CargarGrid();
  }
  Buscar() {
    this.params.page_index = 1;
    this.globals.SaveData(this.params.filtro, 'actividades-filtro');
    this.globals.SaveData(this.params.page_index, 'actividades-page-index');
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/actividad')).then();
  }
  Duplicar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/duplicar/actividad/')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/actividad')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar actividad?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-borrar-actividad', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.datagrid.splice(this.datagrid.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID: ${item.id}`,'Copiado al portapapeles');
  }

}
