import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let XLSX: any;
declare let saveAs: any;

@Component({
  selector: 'app-ver-reserva',
  templateUrl: './ver-reserva.component.html'
})
export class AdminVerReservaActividadComponent implements OnInit {
  
  public title:string = '';
  public data:any = {
    id: ''
  };
  public reservas = [];
  public pendientes = [];
  public enespera = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.checkrolredirect(3);
    $.btnmenu();
    $.disableinputnumberwheel();
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) {
      this.data = this.globals.passData;
    } else {
      this.Cancelar();
      return;
    }
    if (this.data.id == '') {
      this.title = '';
    } else {
      this.title = this.data.titulo;
    }
    this.globals.Api('/act-reservas', {id: this.data.id}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        return;
      }
      this.data = data.data[0];
    });
    this.globals.Api('/act-reservas', {id_estado: 1, id_actividad: this.data.id_actividad, agrupado_usuario: true}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        this.pendientes = [];return;
      }
      this.pendientes = data.data;
    });
    this.globals.Api('/act-reservas', {id_estado: 2, id_actividad: this.data.id_actividad, agrupado_usuario: true}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        this.reservas = [];return;
      }
      this.reservas = data.data;
    });
    this.globals.Api('/act-reservas', {id_estado: 3, id_actividad: this.data.id_actividad, agrupado_usuario: true}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        this.enespera = [];return;
      }
      this.enespera = data.data;
    });
  }

  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/reserva?from=view')).then();
  }
  BorrarReserva(event, item) {
    event.stopPropagation();
    swal({
      title: "¿Cancelar reserva?",
      text: "Va a cancelar esta reserva y la de los acompañantes",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-borrar-reserva', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Cancelada', 'Reserva cancelada correctamente', 'success');
        }, 200);
        this.reservas.splice(this.reservas.findIndex(el => el.id == item.id), 1);
        this.enespera.splice(this.enespera.findIndex(el => el.id == item.id), 1);
        this.pendientes.splice(this.pendientes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/admin/reservas')).then();
  }
  IrActividad() {
    this.globals.Api('/act-actividades', {id: this.data.id_actividad}).subscribe(data => {
      if (!data || !data.data || !data.data.length) return;
      let item = data.data[0];
      this.globals.passData = JSON.parse(JSON.stringify(item));
      this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/actividad')).then();
    });
  }

  CerrarAbrirActividad() {
    swal({
      title: (!this.data.cerrada_inscripcion ? "¿Cerrar inscripciones a esta actividad?" : "¿Abrir inscripciones a esta actividad?"),
      text: (!this.data.cerrada_inscripcion ? "Nadie podrá inscribirse" : "Los usuarios podrán inscribirse"),
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-warning",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-cerrar-abrir-actividad', {id: this.data.id_actividad}).subscribe(data => {
        if (data && !data.error) {
          this.data.cerrada_inscripcion = data.value;
        }
      })
    });
  }

  EmailTodoPendientes(event:any) {
    event.preventDefault();
    event.stopPropagation();
    swal({
      title: "¿Reenviar email 'aforo completo' a toda la lista?",
      text: 'Se notificará a toda la lista de pendientes',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-email-pendientes-todos-reserva', {id: this.data.id}).subscribe(data => {
        console.log(data);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Reenviado', 'Email "aforo completo" reenviado correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }
  EmailTodosEnEspera(event:any) {
    event.preventDefault();
    event.stopPropagation();
    swal({
      title: "¿Reenviar email 'en espera' a toda la lista?",
      text: 'Se notificará a toda la lista de en espera',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-email-enespera-todos-reserva', {id: this.data.id}).subscribe(data => {
        console.log(data);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Reenviado', 'Email "en espera" reenviado correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }
  EmailTodosConfirmados(event:any) {
    event.preventDefault();
    event.stopPropagation();
    swal({
      title: "¿Reenviar confirmación a toda la lista?",
      text: 'Se notificará a toda la lista de confirmados',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-email-confirmar-todos-reserva', {id: this.data.id}).subscribe(data => {
        console.log(data);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Reenviada', 'Confirmación reenviada correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }
  EmailCambioHorario(event:any) {
    event.preventDefault();
    event.stopPropagation();
    swal({
      title: "¿Enviar cambio de horario a la lista?",
      text: 'Se notificará a toda la lista de confirmados',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-email-cambio-horario-todos-reserva', {id: this.data.id}).subscribe(data => {
        console.log(data);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Enviado', 'Cambio de horario enviado correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }

  Excel(tipo:string = 'Confirmadas') {
    let id_estado = 2;
    if (tipo == 'En Espera') id_estado = 3;
    this.globals.Api('/act-reservas', {id_estado: id_estado, id_actividad: this.data.id_actividad, order: 'COALESCE(act_reservas.turno, 0) ASC, act_reservas.alta ASC, COALESCE(act_reservas.id_reserva_padre, 0)', dir: ''}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        swal('Lista vacía', 'La lista que quieres exportar está vacía', 'error');
        return;
      }
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Reservas " + this.data.titulo.toString().toLowerCase(),
        Subject: "Reservas confirmadas",
        // Author: "Peces Gordos Estudio"
      };
      let hoja = 'Reservas ' + this.data.titulo.toString().toLowerCase().substr(0, 20);
      wb.SheetNames.push(hoja);
      var ws_data = [];
      ws_data.push([this.data.titulo]);
      ws_data.push(['']);
      ws_data.push([tipo]);
      ws_data.push([this.data.ubicacion, '']);
      ws_data.push(['Fecha:', this.data.fecha_format]);
      ws_data.push(['Aforo:', this.data.aforo + ' personas']);
      if (this.data.hora_inicio) ws_data.push(['Hora inicio:', this.data.hora_inicio]);
      if (this.data.hora_fin) ws_data.push(['Hora fin:', this.data.hora_fin]);
      ws_data.push(['']);
      //Turnos
      let turnos = [];
      for (let item of data.data) {
        if (item.turno && turnos.indexOf(item.turno) == -1) turnos.push(item.turno);
      }
      if (turnos && turnos.length) {
        for (let turno of turnos) {
          ws_data.push(['']);
          ws_data.push(['']);
          ws_data.push(['Turno:', turno]);
          ws_data.push(['']);
          ws_data.push(['Nº Reserva', 'Nombre', 'Apellidos', 'Teléfono', 'Email', 'Provincia', 'Localidad']);
          for (let item of data.data.filter(e => e.turno == turno)) {
            if (!item.provincia) item.provincia = '';
            if (!item.localidad) item.localidad = '';
            if (!item.telefono) item.telefono = '';
            if (item.provincia == 'null') item.provincia = '';
            if (item.localidad == 'null') item.localidad = '';
            if (item.telefono == 'null') item.telefono = '';
            ws_data.push([item.referencia, item.nombre, item.apellidos, item.telefono, item.email, item.provincia, item.localidad]);
          }
        }
      } else {
        ws_data.push(['Nº Reserva', 'Nombre', 'Apellidos', 'Teléfono', 'Email', 'Provincia', 'Localidad']);
        for (let item of data.data) {
          if (!item.provincia) item.provincia = '';
          if (!item.localidad) item.localidad = '';
          if (!item.telefono) item.telefono = '';
          if (item.provincia == 'null') item.provincia = '';
          if (item.localidad == 'null') item.localidad = '';
          if (item.telefono == 'null') item.telefono = '';
          ws_data.push([item.referencia, item.nombre, item.apellidos, item.telefono, item.email, item.provincia, item.localidad]);
        }
      }
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        {wch:20},
        {wch:26},
        {wch:35},
        {wch:26},
        {wch:50},
        {wch:35},
        {wch:40}
      ];
      ws['!cols'] = wscols;
      wb.Sheets[hoja] = ws;
      var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      // saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), exportName+'.xlsx');
      var blob = new Blob([s2ab(wbout)], {type: "octet/stream"});
      var url = window.URL.createObjectURL(blob);
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     url);
      downloadAnchorNode.setAttribute("download", tipo+' - ' + this.data.titulo.toString().toLowerCase() + ".xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      window.URL.revokeObjectURL(url);
      downloadAnchorNode.remove();
    });
  }
  Imprimir(tipo:string = 'Confirmadas') {
    let id_estado = 2;
    if (tipo == 'En Espera') id_estado = 3;
    this.globals.Api('/act-reservas', {id_estado: id_estado, id_actividad: this.data.id_actividad, order: 'COALESCE(act_reservas.turno, 0) ASC, act_reservas.alta ASC, COALESCE(act_reservas.id_reserva_padre, 0)', dir: ''}).subscribe(data => {
      if (!data || !data.data || !data.data.length) {
        swal('Lista vacía', 'La lista que quieres imprimir está vacía', 'error');
        return;
      }

      //Turnos
      let turnos = [];
      for (let item of data.data) {
        if (item.turno && turnos.indexOf(item.turno) == -1) turnos.push(item.turno);
      }

      let personas = '';
      if (turnos && turnos.length) {
        for (let turno of turnos) {
          let turnohtml = '';
          for (let item of data.data.filter(e => e.turno == turno)) {
            if (!item.provincia) item.provincia = '';
            if (!item.localidad) item.localidad = '';
            if (!item.telefono) item.telefono = '';
            if (item.provincia == 'null') item.provincia = '';
            if (item.localidad == 'null') item.localidad = '';
            if (item.telefono == 'null') item.telefono = '';
            turnohtml += `
              <tr>
                <td>${item.referencia}</td>
                <td>${item.nombre}</td>
                <td>${item.apellidos}</td>
                <td>${item.telefono ? item.telefono : ''}</td>
                <td>${item.email ? item.email : ''}</td>
                <td>${item.provincia ? item.provincia : ''}</td>
                <td>${item.localidad ? item.localidad : ''}</td>
              </tr>
            `;
          }
          personas += `
            <h4 class="m-t-30">Turno: ${turno}</h4>
            <table class="table table-borderer m-t-20" style="width:auto;">
              <thead class="font-bold">
                <tr>
                  <td style="width:130px">Nº Reserva</td>
                  <td style="width:200px">Nombre</td>
                  <td style="width:260px">Apellidos</td>
                  <td style="width:120px">Tel&eacute;fono</td>
                  <td>Email</td>
                  <td>Provincia</td>
                  <td style="width:200px">Localidad</td>
                </tr>
              </thead>
              <tbody>
                ${turnohtml}
              </tbody>
            </table>
          `;
        }
      } else {
        for (let item of data.data) {
          if (!item.provincia) item.provincia = '';
          if (!item.localidad) item.localidad = '';
          if (!item.telefono) item.telefono = '';
          if (item.provincia == 'null') item.provincia = '';
          if (item.localidad == 'null') item.localidad = '';
          if (item.telefono == 'null') item.telefono = '';
          personas += `
            <tr>
              <td>${item.referencia}</td>
              <td>${item.nombre}</td>
              <td>${item.apellidos}</td>
              <td>${item.telefono ? item.telefono : ''}</td>
              <td>${item.email ? item.email : ''}</td>
              <td>${item.provincia ? item.provincia : ''}</td>
              <td>${item.localidad ? item.localidad : ''}</td>
            </tr>
          `;
        }
        personas = `
          <table class="table table-borderer m-t-20" style="width:auto;">
            <thead class="font-bold">
              <tr>
                <td style="width:130px">Nº Reserva</td>
                <td style="width:200px">Nombre</td>
                <td style="width:260px">Apellidos</td>
                <td style="width:120px">Tel&eacute;fono</td>
                <td>Email</td>
                <td>Provincia</td>
                <td style="width:200px">Localidad</td>
              </tr>
            </thead>
            <tbody>
              ${personas}
            </tbody>
          </table>
        `;
      }

      var mywindow = window.open('', 'Imprimir reservas confirmadas',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=1024,
      height=576`
      );
      mywindow.document.write(`
        <html lang="es">
          <head>
            ${document.head.innerHTML}
            <style type="text/css">
              body {background-color:#FFF !important;}
            </style>
            <style type="text/css" media="print">
              body {background-color:#FFF !important;}
            </style>
          </head>
          <body>
            <div class="container">
              <h2>${this.data.titulo}</h2>
              <div class="p-l-5 m-t-20">
                <h4 class="text-secondary">${tipo}</h4>
                <h4><i class="fa fa-map-marker"></i> ${this.data.ubicacion}</h4>
              </div>
              <table class="table table-borderer m-t-20" style="width:auto;">
                <tbody>
                  <tr>
                    <td>Fecha: </td>
                    <td class="font-bold">${this.data.fecha_format}</td>
                  </tr>
                  <tr>
                    <td>Aforo: </td>
                    <td>${this.data.aforo} personas</td>
                  </tr>
                  ${this.data.hora_inicio ? 
                  `
                    <tr>
                      <td>Hora inicio: </td>
                      <td>${this.data.hora_inicio}</td>
                    </tr>
                  ` : ''
                  }
                  ${this.data.hora_fin ? 
                  `
                    <tr>
                      <td>Hora fin: </td>
                      <td>${this.data.hora_fin}</td>
                    </tr>
                  ` : ''
                  }
                </tbody>
              </table>
              ${personas}
            </div>
          </body>
        </html>
      `);
      mywindow.document.close();
      mywindow.focus();
      mywindow.moveTo(50,50);
      setTimeout(() => {
        mywindow.print();
      }, 1000);
      // mywindow.close();
    });
  }

}
