import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html'
})
export class AdminEmpresasActividadesComponent implements OnInit {
  public usuarios = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
    id_rol: 2
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
  }

  CargarGrid(paginationload:boolean = true) {
    this.globals.Api('/usuarios', this.params).subscribe(data => {
      if (!data || !data.data) {
        this.usuarios = [];return;
      }
      this.usuarios = data.data;
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/edit/usuario?tipo=empresa')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/edit/usuario?tipo=empresa')).then();
  }
  EditarClave(item:any) {
    $.EditarClaveUsuarioModal(item);
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar empresa?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/borrar-usuario', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.usuarios.splice(this.usuarios.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
