<div class="row border-bottom white-bg">
    <nav class="navbar navbar-expand-lg navbar-static-top navbar-frontend" role="navigation">
        <div class="container">
            <div class="navbar-brand" style="pointer-events:none;">
                <img src="assets/img/logo-2024.png" class="logo m-r-20" alt="">
                <!-- {{titulo}} -->
            </div>
            <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa fa-reorder"></i>
            </button> -->
            <div class="navbar-collapse" id="navbar">
                <ul class="nav navbar-nav mr-auto">
                </ul>
                <ul class="nav navbar-top-links navbar-right">
                    <!--
                    <li>
                        <a [routerLink]="['/login']" *ngIf="!globals.me || !globals.me.nombre">
                            <i class="fa fa-user fa-2x"></i> Mi perfli
                        </a>
                        <a [routerLink]="['/dashboard']" *ngIf="globals.me && globals.me.nombre">
                            <i class="fa fa-user fa-2x"></i> Mi perfli
                        </a>
                    </li>
                    -->

                    <li><a class="cursor" (click)="SetIdioma('es')" class="btn-flag"><img src="assets/img/flags/32/Spain.png" alt=""></a></li>
                    <li><a class="cursor" (click)="SetIdioma('pt')" class="btn-flag"><img src="assets/img/flags/32/Portugal.png" alt=""></a></li>

                </ul>
            </div>
        </div>
    </nav>
</div>