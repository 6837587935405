import { ReturnStatement } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-edit-reserva',
  templateUrl: './edit-reserva.component.html'
})
export class EditReservaActividadComponent implements OnInit {

  public title:string = 'Nueva reserva';
  public estados = [];
  public data:any = {
    id: '', idioma: 'es', sexo: 'F',
    reservas: [
      {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: 1},
      {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: 1},
      {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: 1}
    ]
  };
  public actividad:any = {
    id: ''
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.checkrolredirect(3);
    $.btnmenu();
    $.disableinputnumberwheel();
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) {
      this.data = this.globals.passData;
    }
    if (this.data.id == '') {
      this.title = 'Nueva reserva';
      this.data.prefijo_telefono = '+34';
    } else {
      // console.log(this.data);
      this.title = this.data.nombre + (this.data.apellidos ? ' ' + this.data.apellidos : '');
      setTimeout(() => {
        $('#lbllocalidad').html(this.data.localidad+', '+this.data.provincia);
      }, 200);
      this.actividad = {
        id: this.data.id_actividad,
        imagen: this.data.imagen,
        fecha: this.data.fecha,
        fecha_mes: this.data.fecha_mes,
        fecha_dia: this.data.fecha_dia,
        titulo: this.data.titulo,
        empresa: this.data.empresa,
        ubicacion: this.data.ubicacion,
        categoria: this.data.categoria,
        aforo: this.data.aforo,
        turnos: this.data.turnos
      };
      this.ValidarDatosPersonas();
      // if (this.data.personas && this.data.personas.length) {
        // let i = 2;
        // for (let item of this.data.personas) {
        //   $('#txtnombre'+i).val(item.nombre);
        //   $('#txtapellidos'+i).val(item.apellidos);
        //   $('#txtcodpostal'+i).val(item.codpostal);
        //   $('#txtprovincia'+i).val(item.provincia);
        //   $('#txtlocalidad'+i).val(item.localidad);
        //   $('#txttelefono'+i).val(item.telefono);
        //   $('#txtemail'+i).val(item.email);
        //   $('#txtdni'+i).val(item.dni);
        //   $('#cmbsexo'+i).val(item.sexo);
        //   if (item.localidad && item.provincia) {
        //     $('#lbllocalidad'+i).html(item.localidad+', '+item.provincia);
        //   }
        //   i++;
        // }
      // }
    }

    // this.globals.Api('/act-estados-reservas').subscribe(data => {
    //   if (!data || data.length == 0) return;
    //   this.estados = data;
    //   if (!this.data.id_estado && this.estados.length) this.data.id_estado = this.estados[0].id;
    // });

    $(document).ready(() => {
      $('.date').datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        forceParse: false,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        dateFormat: 'dd/mm/yy'
      });
      if (this.data.fecha_nacimiento) $('#fechanacimiento').datepicker('setDate', new Date(this.data.fecha_nacimiento));
    });
  }
  ValidarDatosPersonas() {
    if (this.data.telefono.toString().indexOf('+') == -1) this.data.telefono = '+'+this.data.telefono;
    if (this.data.telefono.toString().startsWith('+34')) this.data.prefijo_telefono = '+34';
    if (this.data.telefono.toString().startsWith('+351')) this.data.prefijo_telefono = '+351';
    this.data.telefono = this.data.telefono.toString().replace('+34', '');
    this.data.telefono = this.data.telefono.toString().replace('+351', '');
    this.data.reservas = [];
    for (let i = 0; i <= 2; i++) {
      let item:any = this.data.personas[i];
      if (!item) item = {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: 1};
      if (item.telefono && item.telefono != '' && item.telefono.toString().indexOf('+') == -1) item.telefono = '+'+item.telefono;
      item.telefono = item.telefono.toString().replace('+34', '');
      item.telefono = item.telefono.toString().replace('+351', '');
      this.data.reservas.push(item);
    }
  }

  DatosLocalidad(event:any = null, idtxt:string = null) {
    let codpostal = '';
    codpostal = (event ? event.target.value : this.data.codpostal);
    if (!codpostal || codpostal == '') return;
    this.globals.Api('/localidad', {codpostal: codpostal, idioma: this.data.idioma}).subscribe(data => {
      if (!data || !data.localidad) {
        // this.data.localidad = '';
        // this.data.provincia = '';
        $('#lbllocalidad').html('<span class="text-danger">'+this.globals.Literal('Código postal no valido')+'</span>');
        this.appref.tick();
        return;
      }
      if (idtxt && idtxt != '') {
        $('#'+idtxt).html(data.localidad+', '+data.provincia);
        this.appref.tick();
        return;
      }
      // this.data.localidad = data.localidad;
      // this.data.provincia = data.provincia;
      $('#lbllocalidad').html(data.localidad+', '+data.provincia);
      this.appref.tick();
    });
  }

  ModalSeleccionaActividad() {
    $('#modal-seleccion-actividad').modal('show');
  }
  SeleccionarActividad(item:any) {
    this.actividad = item;
    this.data.id_actividad = this.actividad.id;
    if (this.actividad.turnos) this.data.turno = this.actividad.turnos.split(',')[0];
  }

  AddPersonas() : boolean {
    let ok = true;
    let i = 2;
    for (let item of this.data.reservas) {
      if (this.data.idioma == 'es') {
        item.provincia = $('#lbllocalidad'+i).text().split(', ')[1];
        item.localidad = $('#lbllocalidad'+i).text().split(', ')[0];
      } else {
        item.provincia = $('#txtprovincia'+i).val();
        item.localidad = $('#txtlocalidad'+i).val();
      }
      if (item.adulto == '0') {
        item.dni = '';
        item.codpostal = '';
        item.telefono = '';
        item.email = '';
      }
      if (
        item.nombre == '' && item.apellidos == ''
        ) {
        continue;
      }
      if (
           item.nombre == ''
        || item.apellidos == ''
        ) {
        swal(this.globals.Literal('Complete acompañante')+i, this.globals.Literal('Mensaje Rellene datos'), 'error');
        return false;
      }
      if (item.adulto == '1') {
        if (
          !item.dni || item.dni == ''
        ) {
          swal(this.globals.Literal('Complete acompañante')+i, this.globals.Literal('Mensaje Rellene datos'), 'error');
          return false;
        }

        //Validar DNI
        item.dni = item.dni.toString().toUpperCase().trim();
        $('#txtdni'+i).val(item.dni);
        let valdni = /^[XYZ]?\d{5,8}[A-Z]$/;
        let valdnipt = /^[\d]{8}[-]?\d([A-Z]{2}\d)?$/;
        if (!valdni.test(item.dni) && this.data.idioma == 'es') {
          swal(this.globals.Literal('Error'), this.globals.Literal('Error DNI')+' '+this.globals.Literal('acompañante')+i, 'error');
          return false;
        }
        if (!valdnipt.test(item.dni) && this.data.idioma == 'pt') {
          swal(this.globals.Literal('Error'), this.globals.Literal('Error DNI')+' '+this.globals.Literal('acompañante')+i, 'error');
          return false;
        }
        if (item.dni.toString().toLowerCase().trim() == this.data.dni.toString().toLowerCase().trim()) {
          swal(this.globals.Literal('DNI repetido acompañante')+i, this.globals.Literal('Mensaje repetido acompañante DNI'), 'error');
          return false;
        }
        let elems = this.data.reservas.filter(el => el.dni.toString().toLowerCase().trim() == item.dni.toString().toLowerCase().trim());
        if (elems.length > 1) {
          swal(this.globals.Literal('DNI repetido acompañante')+i, this.globals.Literal('Mensaje repetido acompañante DNI'), 'error');
          return false;
        }

        if (item.email != '') {
          if (!this.globals.ValidateEmail(item.email)) {
            swal(this.globals.Literal('Email acompañante')+i, this.globals.Literal('Mensaje acompañante'), 'error');
            return false;
          }
          if (item.email.toString().toLowerCase().trim() == this.data.email.toString().toLowerCase().trim()) {
            swal(this.globals.Literal('Email repetido acompañante')+i, this.globals.Literal('Mensaje repetido acompañante'), 'error');
            return false;
          }
          let elems = this.data.reservas.filter(el => el.email.toString().toLowerCase().trim() == item.email.toString().toLowerCase().trim());
          if (elems.length > 1) {
            swal(this.globals.Literal('Email repetido acompañante')+i, this.globals.Literal('Mensaje repetido acompañante'), 'error');
            return false;
          }
        }
      }

      i++;
    }

    return ok;
  }
  KeyDownTel(item:any = null) {
    if (item) {
      if (!item.telefono) item.telefono = '';
      item.telefono = item.telefono.toString().replace(/ /g, '');
      item.telefono = item.telefono.toString().replace(/\+/g, '');
      item.telefono = item.telefono.toString().replace(/\D/g, '');
    } else {
      if (!this.data.telefono) this.data.telefono = '';
      this.data.telefono = this.data.telefono.toString().replace(/ /g, '');
      this.data.telefono = this.data.telefono.toString().replace(/\+/g, '');
      this.data.telefono = this.data.telefono.toString().replace(/\D/g, '');
    }
  }


  EnEspera() {
    swal({
      title: "¿Pasar solicitud a 'En espera'?",
      text: 'Se notificará al usuario del cambio de estado de la reserva',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-warning",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-enespera-reserva', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('En espera', 'Solicitud puesta "en espera" correctamente', 'success');
        }, 200);
        this.data.id_estado = 3;
        this.appref.tick();
      });
    });
  }
  Confirmar() {
    swal({
      title: "¿Confirmar reserva?",
      text: 'Se notificará al usuario de la confirmación de su reserva',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-confirmar-reserva', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Confirmada', 'Reserva confirmada correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }
  ReConfirmar() {
    swal({
      title: "¿Reenviar confirmación?",
      text: 'Se notificará al usuario',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-confirmar-reserva', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Reenviada', 'Confirmación reenviada correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }
  ReConfirmarWhatsApp() {
    swal({
      title: "¿Reenviar confirmación WhatsApp?",
      text: 'Se notificará al usuario',
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-confirmar-reserva-whatsapp', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Reenviada', 'Confirmación WhatsApp reenviada correctamente', 'success');
        }, 200);
        this.data.id_estado = 2;
        this.appref.tick();
      });
    });
  }
  CancelarReserva() {
    swal({
      title: "¿Cancelar reserva?",
      text: "Va a cancelar esta reserva y la de los acompañantes",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-borrar-reserva', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Cancelada', 'Reserva cancelada correctamente', 'success');
        }, 200);
        this.Cancelar();
      });
    });
  }
  ValidarAsistencia() {
    swal({
      title: "¿Validar asistencia?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/act-confirmar-asistencia', {id_reserva: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        setTimeout(() => {
          swal('Confirmada', 'Asistencia confirmada correctamente', 'success');
        }, 200);
        this.data.asistencia = 1;
        this.appref.tick();
      });
    });
  }
  Cancelar() {
    if (this.globals.GetQueryString('from') == 'view') {
      this.globals.passData = JSON.parse(JSON.stringify(this.data));
      this.ngZone.run(() => this.router.navigateByUrl('/actividades/ver/reserva')).then();
      return;
    }
    if (this.globals.GetQueryString('from') == 'colaboradores') {
      this.globals.passData = JSON.parse(JSON.stringify(this.data));
      this.ngZone.run(() => this.router.navigateByUrl('/actividades/admin/colaboradores')).then();
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/admin/reservas')).then();
  }
  Guardar() {
    if (this.data.idioma == 'es') {
      this.data.provincia = $('#lbllocalidad').text().split(', ')[1];
      this.data.localidad = $('#lbllocalidad').text().split(', ')[0];
    }
    if (
      !this.data.id_actividad || this.data.id_actividad == ''
      ) {
      swal('Seleccione una actividad', 'Por favor, seleccione una actividad para continuar', 'error');return;
    }
    if (
         this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.codpostal == ''
      || this.data.localidad == ''
      || this.data.provincia == ''
      || this.data.telefono == ''
      || this.data.email == ''
      || this.data.dni == ''
      || this.data.sexo == ''
      || !this.data.nombre
      || !this.data.apellidos
      || !this.data.codpostal
      || !this.data.localidad
      || !this.data.provincia
      || !this.data.telefono
      || !this.data.email
      || !this.data.dni
      || !this.data.sexo
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.dni = this.data.dni.toString().toUpperCase();
    if (!this.AddPersonas()) return;

    this.data.notificar = $('#chkNotificar').val();

    if (this.data.prefijo_telefono && this.data.prefijo_telefono != '') {
      this.data.telefono = this.data.prefijo_telefono + this.data.telefono;
      for (let item of this.data.reservas) {
        if (!item.telefono || item.telefono == '') continue;
        item.telefono = this.data.prefijo_telefono + item.telefono;
      }
    }

    if (this.data.id == '') {
      $.buttonloading('btnGuardar', 'Enviando');
      $.buttonloading('btnGuardar2', 'Enviando');
      this.globals.Api('/act-crear-reserva', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        if (!data || data.error) {
          this.ValidarDatosPersonas();
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '1 email repetido') {
            swal('Email existente persona 1', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '2 email repetido') {
            swal('Email existente persona 2', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '3 email repetido') {
            swal('Email existente persona 3', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '4 email repetido') {
            swal('Email existente persona 4', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '5 email repetido') {
            swal('Email existente persona 5', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == 'Aforo completado') {
            swal('Aforo completo', 'Esta inscripción supera el aforo máximo permitido', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        this.ValidarDatosPersonas();
      });
    } else {
      $.buttonloading('btnGuardar', 'Enviando');
      $.buttonloading('btnGuardar2', 'Enviando');
      this.globals.Api('/act-editar-reserva', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        if (!data || data.error) {
          this.ValidarDatosPersonas();
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '1 email repetido') {
            swal('Email existente persona 1', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '2 email repetido') {
            swal('Email existente persona 2', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '3 email repetido') {
            swal('Email existente persona 3', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '4 email repetido') {
            swal('Email existente persona 4', 'Existe un usuario con este email', 'error');return;
          }
          if (data.value == '5 email repetido') {
            swal('Email existente persona 5', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        this.ValidarDatosPersonas();
      }, error => {
        $.buttoncancelloading('btnGuardar');
        $.buttoncancelloading('btnGuardar2');
        this.ValidarDatosPersonas();
      });
    }
  }

}
