<div id="wrapper">
    <app-menu></app-menu>
    
    <div id="page-wrapper" class="gray-bg dashbard-1">
      <app-header></app-header>
  
      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-4 col-md-8 col-xs-12">
          <h2>
            <i class="icofont-bag-alt"></i>
            Empresas
            <button class="btn btn-xs" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
          </h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard">Inicio</a>
            </li>
            <li class="breadcrumb-item active">
                <strong>Empresas</strong>
            </li>
          </ol>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-12 my-auto text-center no-wrap">
          <label class="col-form-label inline-block m-r-10">Mostrar</label>
          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12 my-auto text-center">
          <div class="input-group">
            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
            <div class="input-group-append cursor" (click)="Buscar()">
              <span class="input-group-addon" style="font-size:20px;padding-top:6px;"><i class="fa fa-search"></i></span>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
          <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva empresa</a>
        </div>
      </div>
      <div class="row animated fadeInRight">
        <div class="table-responsive table-grid ibox-content padding-0">
          <table class="table table-hover">
              <thead>
              <tr>
                <th></th>
                <th style="width:200px" order="empresa">Empresa</th>
                <th style="width:130px" order="alta">Alta</th>
                <th order="localidad">Localidad</th>
                <th order="telefono">Tel&eacute;fono</th>
                <th order="email">Email</th>
                <th style="width:50px">Editar</th>
                <th style="width:50px">Borrar</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of usuarios" class="cursor">
                <td class="text-center" (click)="Editar(item)">
                  <img *ngIf="item.imagen" [src]="item.imagen" class="img-usuario img-circle square">
                  <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                </td>
                <td class="font-bold text-primary" (click)="Editar(item)">
                  <div *ngIf="!item.empresa">{{item.nombre}} {{item.apellidos}}</div>
                  <div *ngIf="item.empresa">{{item.empresa}}</div>
                </td>
                <td>{{item.alta_format}}</td>
                <td>{{item.localidad}}</td>
                <td><span *ngIf="item.telefono" class="no-wrap"><i class="fa fa-phone"></i> {{item.telefono}}</span></td>
                <td class="breaktd"><span *ngIf="item.email" class="no-wrap"><i class="fa fa-envelope"></i> {{item.email}}</span></td>
                <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
              </tr>
              </tbody>
          </table>
          <div class="dataTables_paginate paging_simple_numbers m-r-10">
            <ul class="pagination justify-content-end">
              <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                <a (click)="Paging('prev')" class="page-link">Anterior</a>
              </li>
              <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
              </li>
              <li class="paginate_button page-item active">
                <a class="page-link cursor">{{params.page_index}}</a>
              </li>
              <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
              </li>
              <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                <a (click)="Paging('next')" class="page-link">Siguiente</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <app-footer></app-footer>
    </div>
  
  </div>