import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let tinymce: any;
declare let XLSX: any;
declare let saveAs: any;
declare let ace: any;
declare let grapesjs: any;

@Component({
  selector: 'app-config-app',
  templateUrl: './config-app.component.html'
})
export class ConfigAppComponent implements OnInit {
  public data:any = {
    tipoapp: '',
    nombre: '',
    customcss: '',
    emailconf_host: '',
    emailconf_port: '',
    emailconf_ssl: 0,
    emailconf_email: '',
    emailconf_pass: '',
    emailconf_alias: '',
    emailconf_emailadmin_alias: '',
    emailconf_emailadmin1: '',
    emailconf_emailadmin2: '',
    emailconf_emailadmin3: '',
    redsys_numcomercio: '',
    redsys_nombre_comercio: '',
    redsys_cifrado: '',
    redsys_nummoneda: '',
    redsys_entornopruebas: 1,
    monfrague: {},
    tajo: {}
  };

  public salas = [];
  public plantillas = [];
  public dataitem:any = {};
  public wp_tablas = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    tinymce.remove();
    this.data = this.globals.config;
    this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5
    });
    this.globals.Api('/configuracion').subscribe(data => {
      $.LoadingOverlay("hide");
      if (!data || data.error) return;
      this.globals.config = data;
      this.data = this.globals.config;
      this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
      if (!this.data.monfrague) this.data.monfrague = {};
      if (!this.data.tajo) this.data.tajo = {};
      this.appref.tick();
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    }, error => {
      toastr.error(`No se ha podido cargar datos de configuración por falta de conexión`, 'Error!');
      $.LoadingOverlay("hide");
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    });
    if (!this.data.emailconf_ssl) this.data.emailconf_ssl = 0;
    $.logseditor = ace.edit("txtlogsdb");
    $.logseditor.setTheme("ace/theme/monokai");
    this.CargarLogs();
    $.sqleditor = ace.edit("txtsql");
    // $.sqleditor.setTheme("ace/theme/monokai");
    $.resultsqleditor = ace.edit("txtresultsql");
    // $.resultsqleditor.setTheme("ace/theme/monokai");
    $.btnmenu();
    $.disableinputnumberwheel();
    this.CargarPlantillas();
  }
  Guardar() {
    if (this.data.tipoapp) this.data.tipoapp = this.data.tipoapp.toString().toLowerCase().trim();
    // this.data.customcss = tinymce.EditorManager.get('txtcustomcss').getContent({format: 'text'});
    this.data.customcss = $.csseditor.getValue();
    this.data.customcss = this.data.customcss.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]*>?/gm, '');
    $.buttonloading('btnGuardar', 'Enviando');
    this.globals.Api('/guardar-configuracion', this.data).subscribe(data => {
      $.buttoncancelloading('btnGuardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
      this.globals.config = this.data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      if ($.CargarConfiguracionGlobal) $.CargarConfiguracionGlobal();
    }, error => {
      $.buttoncancelloading('btnGuardar');
    });
  }

  ChangeIntervaloMinutos() {
    $('.timepicker').timepicker('option', 'step', this.data.cal_intervalo_minutos);
  }
  ChangeIntervaloMinutosMusculacion() {
    $('.timepicker2').timepicker('option', 'step', this.data.cal_musculacion_intervalo_minutos);
  }

  EmailTest() {
    this.globals.Api('/email-test').subscribe(data => {
      if (data && data.error) {
        swal('Error', data.value, 'error');
        return;
      }
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  ExportarDB() {
    this.globals.Api('/exportar-db', {}).subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     'data:text/plain;charset=utf-8,'+data.content);
      downloadAnchorNode.setAttribute("download", "mesdelareservabiosfera.sql");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
  ExportarExcel(tipo:string = '', filtro:string = '') {
    let year = new Date().getFullYear();
    switch(tipo) {
      case 'personas-inscritas': {
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.6)',
          imageResizeFactor: 0.5
        });
        let archivo = 'Asistentes MRB ' + year;
        if (filtro == 'confirmadas-espera') archivo = 'Total (Asistentes + EnEspera) MRB ' + year;
        this.globals.Api('/act-exportar-reservas', {estado: filtro}).subscribe(data => {
          $.LoadingOverlay("hide", true);
          if (!data || !data.length) {
            swal('Lista vacía', 'La lista que quieres exportar está vacía', 'error');
            return;
          }
          var wb = XLSX.utils.book_new();
          wb.Props = {
            Title: archivo,
            Subject: "Personas inscritas",
            // Author: "Peces Gordos Estudio"
          };
          let hoja = archivo.substring(0, 31);
          wb.SheetNames.push(hoja);
          var ws_data = [];
          let header = ['Actividad', 'Municipio / Lugar', 'Aforo', 'Fecha', 'Horario', 'Nombre', 'Apellidos', 'Teléfono', 'Email', 'Provincia', 'Localidad'];
          if (filtro != '') header.splice(1, 0, 'Estado');
          ws_data.push(header);
          for (let item of data) {
            let el = [
              item.titulo,
              item.municipio,
              item.aforo,
              item.fecha_format,
              item.hora_inicio + (item.hora_fin ? ' - ' + item.hora_fin : ''),
              item.nombre,
              item.apellidos,
              item.telefono,
              item.email,
              item.provincia,
              item.localidad
            ];
            if (filtro != '') el.splice(1, 0, item.estado);
            ws_data.push(el);
          }
          var ws = XLSX.utils.aoa_to_sheet(ws_data);
          var wscols = [
            {wch:40},
            {wch:35},
            {wch:10},
            {wch:10},
            {wch:20},

            {wch:26},
            {wch:35},
            {wch:26},
            {wch:50},
            {wch:35},
            {wch:40}
          ];
          if (filtro != '') wscols.splice(1, 0, {wch:10});
          ws['!cols'] = wscols;
          wb.Sheets[hoja] = ws;
          var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
          }
          // saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), exportName+'.xlsx');
          var blob = new Blob([s2ab(wbout)], {type: "octet/stream"});
          var url = window.URL.createObjectURL(blob);
          var downloadAnchorNode = document.createElement('a');
          downloadAnchorNode.setAttribute("href",     url);
          downloadAnchorNode.setAttribute("download", archivo + ".xlsx");
          document.body.appendChild(downloadAnchorNode);
          downloadAnchorNode.click();
          window.URL.revokeObjectURL(url);
          downloadAnchorNode.remove();
        }, error => {
          $.LoadingOverlay("hide", true);
        });
      } break;
    };
  }
  ExportarDatosGenerales() {
    let year = new Date().getFullYear();
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5
    });
    let archivo = 'Datos Generales MRB ' + year;
    this.globals.Api('/act-exportar-datos-generales').subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data || data.error) {
        swal('Sin datos', 'La consulta no ha devuelto datos', 'error');
        return;
      }
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: archivo,
        // Author: "Peces Gordos Estudio"
      };
      var ws_data = [];
      ws_data.push(['RESERVAS CONFIRMADAS POR FECHA']);
      ws_data.push(['Total', 'Fecha']);
      for (let item of data['total-por-fecha']) {
        ws_data.push([
          item.total,
          item.fecha,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['DATOS GENERALES']);
      ws_data.push(['Tipo', 'Total']);
      ws_data.push(['Total reservas', data['total-general']['confirmadas']]);
      ws_data.push(['Total aforo', data['total-general']['aforo']]);
      ws_data.push(['Total bajas', data['total-general']['bajas']]);
      ws_data.push(['Total reservas España', data['total-general']['espana']]);
      ws_data.push(['Total reservas Portugal', data['total-general']['portugal']]);

      ws_data.push(['']);
      ws_data.push(['ACTIVIDADES MÁS RESERVADAS']);
      ws_data.push(['Total', 'Activdad']);
      for (let item of data['total-por-actividad']) {
        ws_data.push([
          item.total,
          item.actividad,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['ACTIVIDADES MENOS RESERVADAS']);
      ws_data.push(['Total', 'Activdad']);
      for (let item of data['total-por-actividad-menos-reservada']) {
        ws_data.push([
          item.total,
          item.actividad,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['RESERVAS POR PROVINCIA']);
      ws_data.push(['Total', 'Provincia']);
      for (let item of data['total-provincia']) {
        ws_data.push([
          item.total,
          item.provincia,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['RESERVAS POR LOCALIDAD']);
      ws_data.push(['Total', 'Localidad']);
      for (let item of data['total-localidad']) {
        ws_data.push([
          item.total,
          item.localidad,
        ]);
      }

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        {wch:30},
        {wch:30},
      ];
      ws['!cols'] = wscols;
      wb.SheetNames.push('GENERAL');
      wb.Sheets['GENERAL'] = ws;

      ws_data = [];
      ws_data.push(['RESERVAS CONFIRMADAS POR FECHA']);
      ws_data.push(['Total', 'Fecha']);
      for (let item of data['tajo']['total-por-fecha']) {
        ws_data.push([
          item.total,
          item.fecha,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['DATOS GENERALES']);
      ws_data.push(['Tipo', 'Total']);
      ws_data.push(['Total reservas', data['tajo']['total-general']['confirmadas']]);
      ws_data.push(['Total aforo', data['tajo']['total-general']['aforo']]);
      ws_data.push(['Total bajas', data['tajo']['total-general']['bajas']]);
      ws_data.push(['Total reservas España', data['tajo']['total-general']['espana']]);
      ws_data.push(['Total reservas Portugal', data['tajo']['total-general']['portugal']]);


      ws_data.push(['']);
      ws_data.push(['ACTIVIDADES MÁS RESERVADAS']);
      ws_data.push(['Total', 'Activdad']);
      for (let item of data['tajo']['total-por-actividad']) {
        ws_data.push([
          item.total,
          item.actividad,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['ACTIVIDADES MENOS RESERVADAS']);
      ws_data.push(['Total', 'Activdad']);
      for (let item of data['tajo']['total-por-actividad-menos-reservada']) {
        ws_data.push([
          item.total,
          item.actividad,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['RESERVAS POR PROVINCIA']);
      ws_data.push(['Total', 'Provincia']);
      for (let item of data['tajo']['total-provincia']) {
        ws_data.push([
          item.total,
          item.provincia,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['RESERVAS POR LOCALIDAD']);
      ws_data.push(['Total', 'Localidad']);
      for (let item of data['tajo']['total-localidad']) {
        ws_data.push([
          item.total,
          item.localidad,
        ]);
      }

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        {wch:30},
        {wch:30},
      ];
      ws['!cols'] = wscols;
      wb.SheetNames.push('TAJO INTERNACIONAL');
      wb.Sheets['TAJO INTERNACIONAL'] = ws;

      ws_data = [];
      ws_data.push(['RESERVAS CONFIRMADAS POR FECHA']);
      ws_data.push(['Total', 'Fecha']);
      for (let item of data['monfrague']['total-por-fecha']) {
        ws_data.push([
          item.total,
          item.fecha,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['DATOS GENERALES']);
      ws_data.push(['Tipo', 'Total']);
      ws_data.push(['Total reservas', data['monfrague']['total-general']['confirmadas']]);
      ws_data.push(['Total aforo', data['monfrague']['total-general']['aforo']]);
      ws_data.push(['Total bajas', data['monfrague']['total-general']['bajas']]);
      ws_data.push(['Total reservas España', data['monfrague']['total-general']['espana']]);
      ws_data.push(['Total reservas Portugal', data['monfrague']['total-general']['portugal']]);

      ws_data.push(['']);
      ws_data.push(['ACTIVIDADES MÁS RESERVADAS']);
      ws_data.push(['Total', 'Activdad']);
      for (let item of data['monfrague']['total-por-actividad']) {
        ws_data.push([
          item.total,
          item.actividad,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['ACTIVIDADES MENOS RESERVADAS']);
      ws_data.push(['Total', 'Activdad']);
      for (let item of data['monfrague']['total-por-actividad-menos-reservada']) {
        ws_data.push([
          item.total,
          item.actividad,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['RESERVAS POR PROVINCIA']);
      ws_data.push(['Total', 'Provincia']);
      for (let item of data['monfrague']['total-provincia']) {
        ws_data.push([
          item.total,
          item.provincia,
        ]);
      }

      ws_data.push(['']);
      ws_data.push(['RESERVAS POR LOCALIDAD']);
      ws_data.push(['Total', 'Localidad']);
      for (let item of data['monfrague']['total-localidad']) {
        ws_data.push([
          item.total,
          item.localidad,
        ]);
      }

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        {wch:30},
        {wch:30},
      ];
      ws['!cols'] = wscols;
      wb.SheetNames.push('MONFRAGÜE');
      wb.Sheets['MONFRAGÜE'] = ws;

      var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      // saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), exportName+'.xlsx');
      var blob = new Blob([s2ab(wbout)], {type: "octet/stream"});
      var url = window.URL.createObjectURL(blob);
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     url);
      downloadAnchorNode.setAttribute("download", archivo + ".xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      window.URL.revokeObjectURL(url);
      downloadAnchorNode.remove();
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
  CargarLogs() {
    this.globals.Api('/logs-db').subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      $.logseditor.setValue(data.content);
    });
  }
  BorrarLogs() {
    this.globals.Api('/borrar-logs-db').subscribe(data => {
      $.logseditor.setValue('');
    });
  }
  EjecutarSQL() {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5
    });
    $.resultsqleditor.setValue('');
    this.globals.Api('/exec-db', {sql: $.sqleditor.getValue()}).subscribe(data => {
      console.log(data);
      $.LoadingOverlay("hide", true);
      if (!data || data.error) {
        toastr.error('Error en la consulta', 'Error');
        this.CargarLogs();
        return;
      }
      $.resultsqleditor.setValue('Resulado:\n'+JSON.stringify(data.value));
      toastr.success('Consulta ejecutada correctamente', 'Ejecución correcta');
      this.CargarLogs();
    }, error => {
      $.LoadingOverlay("hide", true);
      toastr.error('Error en la consulta', 'Error');
      this.CargarLogs();
    });
  }
  SincroDip() {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5
    });
    this.globals.Api('/sinc-dip').subscribe(data => {
      $.LoadingOverlay("hide", true);
      $.resultsqleditor.setValue('Resulado:\n'+JSON.stringify(data));
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }

  //PLANTILLAS
  CargarPlantillas() {
    this.globals.Api('/plantillas').subscribe(data => {
      if (!data && !data.length) return;
      this.plantillas = data;
    });
  }
  AddPlantilla() {
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.dataitem.title = 'Nueva plantilla';
    this.dataitem.tipo = 'email';
    this.dataitem.idioma = 'es';
    $('#modal-edit-plantilla').modal('show');
    $.editor = grapesjs.init({
      container: '#plantilla',
      components: null,
      style: '',
      storageManager: false,
      plugins: ['gjs-preset-newsletter'],
      height: '400px'
    });
    const prop = $.editor.StyleManager.getProperty('typography', 'font-family');
  }
  EditPlantilla(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    this.dataitem.title = item.asunto;
    $('#modal-edit-plantilla').modal('show');
    $.editor = grapesjs.init({
      container: '#plantilla',
      components: this.dataitem.plantilla,
      style: '',
      storageManager: false,
      plugins: ['gjs-preset-newsletter'],
      height: '400px'
    });
  }
  ClonarPlantilla(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    this.dataitem.title = 'Copia de: ' + item.asunto;
    this.dataitem.id = null;
    $('#modal-edit-plantilla').modal('show');
    $.editor = grapesjs.init({
      container: '#plantilla',
      components: this.dataitem.plantilla,
      style: '',
      storageManager: false,
      plugins: ['gjs-preset-newsletter'],
      height: '400px'
    });
  }
  VerPlantilla(item:any) {
    let a:any = document.createElement('a');
    a.href = './api/email-template/'+item.slug;
    a.target = '_blank';
    a.click();
  }
  DelPlantilla(item:any) {
    swal({
      title: "¿Borrar plantilla?",
      text: '¡No se podrán deshacer los cambios!',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/plantillas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.plantillas.splice(this.plantillas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarPlantilla() {
    if (this.dataitem.tipo == 'email') this.dataitem.plantilla = $.editor.runCommand('gjs-get-inlined-html');
    if (
      !this.dataitem.asunto
      || !this.dataitem.tipo
      || !this.dataitem.idioma
      || !this.dataitem.plantilla
      || this.dataitem.asunto == ''
      || this.dataitem.tipo == ''
      || this.dataitem.idioma == ''
      || this.dataitem.plantilla == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.dataitem.id || this.dataitem.id == '') {
      $.buttonloading('btn-guardar-plantilla', 'Enviando');
      this.globals.Api('/plantillas-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-plantilla');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-edit-plantilla').modal('hide');
        this.CargarPlantillas();
      }, error => {
        $.buttoncancelloading('btn-guardar-plantilla');
      });
    } else {
      $.buttonloading('btn-guardar-plantilla', 'Enviando');
      this.globals.Api('/plantillas-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-plantilla');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        $('#modal-edit-plantilla').modal('hide');
        this.CargarPlantillas();
      }, error => {
        $.buttoncancelloading('btn-guardar-plantilla');
      });
    }
  }
  TestWPConexion() {
    this.globals.Api('/wordpress-test').subscribe((data:any) => {
      if (!data || !data.length) {toastr.error('Error en la conexión al WordPress', 'Error');return;}
      this.wp_tablas = data;
      toastr.success('Conexión correcta a WordPress', 'Conectado');
    })
  }
}
