import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let tinymce: any;
declare let L: any;
declare let blueimp: any;

@Component({
  templateUrl: './actividades-complementarias.component.html',
  styles: [
  ]
})
export class ActividadesComplementariasComponent implements OnInit {
  public datagrid = [];
  public data:any = {};
  public data_pt:any = {};
  public params:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: ''
  };
  public imgsubir:string = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
  }
  
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/act-complementarias-del-imagen', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }

  CargarGrid(paginationload:boolean = true) {
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.6)',
    //   imageResizeFactor: 0.5
    // });
    if (this.globals.GetData('complementarias')) this.datagrid = this.globals.GetData('complementarias');
    this.globals.Api('/act-complementarias', this.params).subscribe(data => {
      // $.LoadingOverlay("hide", true);
      if (!data || !data.data || !data.data.length) {
        this.datagrid = [];
        this.globals.SaveData([], 'complementarias');
      }
      this.datagrid = data.data;
      this.globals.SaveData(this.datagrid, 'complementarias');
      this.appref.tick();

      if (!paginationload) return;
      this.params.total = data.total;
      this.params.pages = Math.ceil(data.total / data.data.length);
    }, error => {
      // $.LoadingOverlay("hide", true);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    // this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/actividad')).then();
    Object.keys(this.data).forEach(item => {this.data[item] = ''});
    this.data.title = 'Nueva actividad complementaria';
    $('#modal-editar-complementaria').modal('show');
    tinymce.remove();
    $(document).ready(() => {
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 200,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | code',
        language: 'es'
      });
      $("#txtFechaComplementaria").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        format: "dd/mm/yyyy",
      });
    });
  }
  Editar(item:any) {
    this.data = JSON.parse(JSON.stringify(item));
    // this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/actividad')).then();
    this.data.title = item.titulo;
    if (this.data.data_pt) this.data_pt = this.data.data_pt;
    $('#modal-editar-complementaria').modal('show');
    tinymce.remove();
    $(document).ready(() => {
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 200,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | code',
        language: 'es'
      });
      $("#txtFechaComplementaria").datepicker({
        todayBtn: "linked",
        keyboardNavigation: false,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        format: "dd/mm/yyyy",
      });
    });
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar actividad complementaria?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/act-complementarias-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.datagrid.splice(this.datagrid.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID: ${item.id}`,'Copiado al portapapeles');
  }
  GuardarComplementaria() {
    this.data.descripcion = tinymce.EditorManager.get('txtdescripcion').getContent();
    if (
      !this.data.titulo
      || !this.data.descripcion
      || this.data.titulo == ''
      || this.data.descripcion == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if ($('#txtFechaComplementaria input').val() != '') {
      this.data.fecha = moment($('#txtFechaComplementaria').datepicker('getDate')).format('YYYY-MM-DD');
    } else {this.data.fecha = null;}
    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (this.data_pt && this.data_pt.titulo) {
      this.data.data_pt = this.data_pt;
      this.data.data_pt.descripcion = tinymce.EditorManager.get('txtdescripcionpt').getContent();
    }

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('btnGuardarComplementaria', 'Enviando');
      this.globals.Api('/act-complementarias-add', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardarComplementaria');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-editar-complementaria').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarComplementaria');
      });
    } else {
      $.buttonloading('btnGuardarComplementaria', 'Enviando');
      this.globals.Api('/act-complementarias-edit', this.data).subscribe(data => {
        $.buttoncancelloading('btnGuardarComplementaria');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        $('#modal-editar-complementaria').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarComplementaria');
      });
    }
  }

}
