import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  public CMensajesWhatsApp:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) {
    this.globals.Api('/configuracion').subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      this.MensajesWhatsApp();
    });
  }

  ngOnInit() {
  }

  Perfil() {
    this.ngZone.run(() => this.router.navigateByUrl('/perfil')).then();
  }
  Lecturas(item:any) {
    this.ngZone.run(() => this.router.navigate(['/lecturas', item.id])).then();
  }
  Logout() {
    $.Logout();
  }

  SlideMenu() {
    $("body").removeClass("mini-navbar");
    $('.overlay-navbar-slide').toggleClass("show");
    $('.navbar-default').toggleClass("navbar-slide");
  }

  MensajesWhatsApp() {
    if (this.globals.me.id_rol == 4) return;
    clearTimeout($.IDTMWhatsApp);
    if (!this.globals.config) {
      if (!this.globals.config.monfrague || !this.globals.config.monfrague.whatsapp_fbid) this.globals.cmensajeswhatsapp_monfrague = 0;
      if (!this.globals.config.tajo || !this.globals.config.tajo.whatsapp_fbid) this.globals.cmensajeswhatsapp_tajo = 0;
      $.IDTMWhatsApp = setTimeout(() => { this.MensajesWhatsApp(); }, 10000);
      return;
    }
    if (this.globals.config.monfrague && this.globals.config.monfrague.whatsapp_fbid) {
      this.globals.Api('/crm-mensajes-count', {fbid: this.globals.config.monfrague.whatsapp_fbid}).subscribe((data:any) => {
        if (!data || data.error) return;
        this.globals.cmensajeswhatsapp_monfrague = data.value;
      });
    }
    if (this.globals.config.tajo && this.globals.config.tajo.whatsapp_fbid) {
      this.globals.Api('/crm-mensajes-count', {fbid: this.globals.config.tajo.whatsapp_fbid}).subscribe((data:any) => {
        if (!data || data.error) return;
        this.globals.cmensajeswhatsapp_tajo = data.value;
        $.IDTMWhatsApp = setTimeout(() => { this.MensajesWhatsApp(); }, 10000);
      });
    }
  }

}
