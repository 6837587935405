import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './backend/login/login.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { EditUsuarioComponent } from './backend/edit-usuario/edit-usuario.component';
import { PerfilComponent } from './backend/perfil/perfil.component';

import { AdminActividadesComponent } from './backend/actividades/admin/actividades/actividades.component';
import { AdminReservasActividadesComponent } from './backend/actividades/admin/reservas/reservas.component';
import { AdminEmpresasActividadesComponent } from './backend/actividades/admin/empresas/empresas.component';
import { UsuariosReservasActividadesComponent } from './backend/actividades/usuario/reservas/reservas.component';
import { ActividadesComponent } from './frontend/actividades/actividades.component';
import { EditActividadComponent } from './backend/actividades/admin/edit-actividad/edit-actividad.component';
import { EditReservaActividadComponent } from './backend/actividades/admin/edit-reserva/edit-reserva.component';
import { AdminVerReservaActividadComponent } from './backend/actividades/admin/ver-reserva/ver-reserva.component';
import { ActividadComponent } from './frontend/actividad/actividad.component';
import { PoliticaPrivacidadComponent } from './frontend/legales/politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './frontend/legales/politica-cookies/politica-cookies.component';
import { AvisoLegalComponent } from './frontend/legales/aviso-legal/aviso-legal.component';
import { AutorizacionImagenComponent } from './frontend/legales/autorizacion-imagen/autorizacion-imagen.component';
import { AutorizacionComunicacionesComponent } from './frontend/legales/autorizacion-comunicaciones/autorizacion-comunicaciones.component';
import { CancelacionComponent } from './frontend/cancelacion/cancelacion.component';
import { ActividadesComplementariasComponent } from './backend/actividades/admin/actividades-complementarias/actividades-complementarias.component';
import { ColaboradoresComponent } from './backend/actividades/admin/colaboradores/colaboradores.component';
import { CheckpointComponent } from './frontend/checkpoint/checkpoint.component';
import { MensajesComponent } from './backend/actividades/admin/mensajes/mensajes.component';
import { AltaColaboradorComponent } from './frontend/alta-colaborador/alta-colaborador.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  // {path: 'actividades', component: ActividadesComponent},
  {path: 'login', component: LoginComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'perfil', component: PerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'edit/usuario', component: EditUsuarioComponent},

  {path: 'actividades/admin/reservas', component: AdminReservasActividadesComponent},
  {path: 'actividades/admin/actividades', component: AdminActividadesComponent},
  {path: 'actividades/admin/actividades-complementarias', component: ActividadesComplementariasComponent},
  {path: 'actividades/admin/empresas', component: AdminEmpresasActividadesComponent},
  {path: 'actividades/admin/colaboradores', component: ColaboradoresComponent},
  {path: 'actividades/usuario/reservas', component: UsuariosReservasActividadesComponent},
  {path: 'actividades/edit/actividad', component: EditActividadComponent},
  {path: 'actividades/duplicar/actividad/', component: EditActividadComponent},
  {path: 'actividades/edit/reserva', component: EditReservaActividadComponent},
  {path: 'actividades/ver/reserva', component: AdminVerReservaActividadComponent},
  {path: 'actividades/admin/mensajes/:tipo', component: MensajesComponent},
  {path: 'actividades/admin/mensajes', component: MensajesComponent},

  //FRONTEND
  {path: 'politica-privacidad', component: PoliticaPrivacidadComponent},
  {path: 'politica-de-privacidad', component: PoliticaPrivacidadComponent},
  {path: 'politica-cookies', component: PoliticaCookiesComponent},
  {path: 'aviso-legal', component: AvisoLegalComponent},
  {path: 'seleccion-actividad', component: ActividadesComponent},
  {path: 'actividad', component: ActividadComponent},
  {path: 'actividad/:url', component: ActividadComponent},
  {path: 'cancelacion/:data', component: CancelacionComponent},
  {path: 'checkpoint', component: CheckpointComponent},
  {path: 'alta-colaborador', component: AltaColaboradorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
