<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
        <app-header></app-header>

        <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-lg-8 col-md-6 col-sm-12">
                <h2>
                <i class="icofont-calendar"></i>
                    Reservas
                    <button class="btn btn-xs" (click)="Actualizar()"><i class="fa fa-refresh"></i></button>
                </h2>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a routerLink="/dashboard">Inicio</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <strong>Reservas</strong>
                    </li>
                </ol>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-botones">
                <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a>
            </div>
        </div>
        <div class="row animated fadeInRight">

            <!-- <div class="col-lg-4 col-md-12 col-sm-12 ibox" style="border-right:1px solid #e7eaec">
                <a class="ibox-title cursor block" href="#panel-pendientes" data-toggle="collapse">
                    <i class="fa fa-exclamation-triangle text-danger"></i> Pendiente <span *ngIf="pendientes && pendientes.length">({{pendientes.length}})</span>
                    <div class="ibox-tools"><a><i class="fa fa-chevron-up"></i></a></div>
                </a>
                <div id="panel-pendientes" class="ibox-content collapse show">
                    <div class="feed-activity-list" style="overflow:auto;max-height:550px;">
                        <div class="feed-element cursor" *ngIf="!pendientes || !pendientes.length">
                            <div class="media-body">
                                <strong>No hay reservas pendientes</strong>
                            </div>
                        </div>
                        <div class="feed-element cursor" *ngFor="let item of pendientes" (click)="Editar(item)">
                            <div class="float-left m-r-10" *ngIf="item.imagen">
                                <img alt="image" class="rounded-circle" [src]="item.imagen">
                            </div>
                            <div class="pull-right">
                                <a class="btn btn-sm btn-danger m-r-10" (click)="BorrarReserva($event, item)"><i class="fa fa-close text-white"></i></a>
                                <a class="btn btn-sm btn-default"><i class="fa fa-pencil"></i></a>
                            </div>
                            <div class="media-body">
                                <strong>{{item.nombre}} {{item.apellidos}}</strong> ha solicitado reserva.<br>
                                <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                                <div *ngIf="item.acompanantes && item.acompanantes != '0'"><strong class="font-16">{{item.acompanantes}}</strong> acompa&ntilde;ante/s</div>
                                <strong class="text-secondary font-bold text-{{globals.CleanedString(item.ubicacion, '-')}}">{{item.titulo}}</strong><br>
                                <small class="text-muted">Hace {{globals.PostedDate(item.alta)}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-12 col-md-12 col-sm-12 ibox">
                <div class="ibox-title"><i class="fa fa-calendar"></i> 
                    Actividades
                    <div class="btn-group m-l-30" role="group" *ngIf="globals.me && (globals.me.id_rol == 0 || globals.me.id_rol == 1)">
                        <button type="button" class="btn btn-default" (click)="FiltrarUbicacion()">Todas</button>
                        <button type="button" class="btn btn-monfrague" (click)="FiltrarUbicacion('1')">Monfragüe</button>
                        <button type="button" class="btn btn-tajo" (click)="FiltrarUbicacion('2')">Tajo</button>
                    </div>
                    <div class="input-group input-busqueda">
                        <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                            <span class="input-group-addon" style="font-size:20px;padding-top:6px;"><i class="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
                <div class="ibox-content">
                    <div *ngIf="!reservas || !reservas.length">
                        <strong>No hay reservas</strong>
                    </div>
                    <div class="row m-t-10">
                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let item of reservas">
                          <div class="actividad-card-admin {{item.cerrada_inscripcion ? 'actividad-cerrada' : null}}" 
                            [style.background-image]="item.imagen ? 'url('+item.imagen+')' : null" (click)="VerReserva(item)">
                            <div class="fecha">
                              <div>{{item.fecha_mes}} {{item.fecha_dia}}</div>
                            </div>
                            <div class="actividad-card-admin-body">
                                <h2 class="titulo">
                                    <i class="fa fa-lock" *ngIf="item.cerrada_inscripcion"></i> {{item.titulo}}
                                    <div class="font-italic font-12 m-l-5" *ngIf="!item.principal"><i class="fa fa-chevron-right text-secondary"></i> Complementaria</div>
                                </h2>
                                <div>{{item.categoria}}</div>
                                <div>
                                    <span class="circulo-color {{item.id_ubicacion == '1' ? 'monfrague' : 'tajo'}}"></span>
                                    <i class="icofont-google-map text-white"></i> 
                                    {{item.ubicacion}}
                                </div>
                                <div>Aforo: {{item.aforo}} Pers.</div>
                                <div class="resumen confirmados">
                                  <span>{{item.plazas_ocupadas}}</span> confirmadas
                                </div>
                                <div class="resumen" *ngIf="item.plazas_pendientes && item.plazas_pendientes > 0">
                                  <span>{{item.plazas_pendientes}}</span> solicitudes
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="dataTables_paginate m-t-20 p-t-20 border-top paging_simple_numbers">
                        <ul class="pagination justify-content-center" style="margin-bottom:0">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                            <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                            <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                            <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                            <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                            <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                        </ul>
                    </div>

                </div>
            </div>
            
        </div>
        <div class="clearfix"></div>

        <app-footer></app-footer>
    </div>

</div>