<ng-container *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
<div class="top-navigation">
    <div id="wrapper">
        <div id="page-wrapper" *ngIf="data">
            <app-cabecera></app-cabecera>
            <div class="middle-box text-center animated fadeInDown" *ngIf="!cancelado" style="width:auto;max-width:600px;padding-left:15px;padding-right:15px;">
                <h2>{{globals.Literal('Cancelar reserva')}}</h2>
                <p class="m-t-20" [innerHTML]="globals.Literal('Mensaje cancelacion')"></p>
                <a class="btn btn-primary m-t-20" (click)="Cancelar()">{{globals.Literal('Cancelar')}}</a>
                <hr>
                <p>{{globals.Literal('Mensaje cancelacion 2')}}</p>
                <p>
                    <i class="fa fa-envelope"></i> {{globals.Literal('Email')}}: <a href="mailto:mesreservabiosfera@gmail.com">mesreservabiosfera@gmail.com</a><br>
                    <!-- <i class="fa fa-phone"></i> {{globals.Literal('Teléfono')}}: <a href="tel:927000000">927 00 00 00</a> -->
                </p>
            </div>
            <div class="middle-box text-center animated fadeInDown" *ngIf="cancelado" style="width:auto;max-width:600px;">
                <div>
                    <img src="https://mesdelareservabiosfera.es/reservas/api/mail-templates/logo-email.png" style="height:100px;" />
                </div>
                <h2>{{globals.Literal('Reserva cancelada')}}</h2>
                <p>{{globals.Literal('Reserva cancelada correctamente')}}</p>
            </div>
        </div>
    </div>
</div>
</ng-container>