import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html'
})
export class ColaboradoresComponent implements OnInit {
  public colaboradores = [];
  public data:any = {};
  public colaborador:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
    this.CargarDatos();
    $(document).ready(() => {
      $('#txtFechaInscripciones').daterangepicker({
        alwaysShowCalendars: true,
        opens: 'left',
        autoUpdateInput: true,
        locale: {
          "format": "DD/MM/YYYY",
          "separator": " - ",
          "applyLabel": "Aplicar",
          "cancelLabel": "Cancel",
          "fromLabel": "Desde",
          "toLabel": "Hasta",
          "customRangeLabel": "Personalizado",
          "weekLabel": "S",
          "daysOfWeek": [
              "Do",
              "Lu",
              "Ma",
              "Mi",
              "Ju",
              "Vi",
              "Sa"
          ],
          "monthNames": [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
          ],
          "firstDay": 1
        }
      });
    });
    this.CargarFechaInscripciones();
  }

  CargarFechaInscripciones() {
    this.globals.Api('/configuracion').subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      if (this.globals.config.resvip_fecha_inicio)  $('#txtFechaInscripciones').data('daterangepicker').setStartDate(moment(this.globals.config.resvip_fecha_inicio));
      if (this.globals.config.resvip_fecha_fin)  $('#txtFechaInscripciones').data('daterangepicker').setEndDate(moment(this.globals.config.resvip_fecha_fin));
    });
  }
  CargarDatos() {
    this.globals.Api('/usuarios', {id_rol: 4}).subscribe(data => {
      if (!data || !data.data) {
        this.colaboradores = [];return;
      }
      this.colaboradores = data.data;
      for (let item of this.colaboradores) {
        if (item.email.toString().indexOf('@') == -1) item.email = null;
        let url = 'https://app.mesdelareservabiosfera.es/checkpoint?v='+item.id;
        item.qr = 'https://quickchart.io/chart?cht=qr&chs=220x220&chld=M%7C0&chl='+url;
      }
      if (this.colaboradores.length) this.SelColaborador(this.colaboradores[0]);
    });
  }
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.data.img = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        delete this.data.img;
        delete this.data.imagen;
        return;
      }
      this.globals.Api('/borrar-imagen-usuario', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        delete this.data.img;
        delete this.data.imagen;
        this.appref.tick();
      });
    });
  }
  Nuevo() {
    Object.keys(this.data).forEach(item => {this.data[item] = ''});
    this.data.title = 'Nuevo colaborador';
    this.data.id_ubicacion = 1;
    if (this.globals.me && this.globals.me.id_ubicacion) this.data.id_ubicacion = this.globals.me.id_ubicacion;
    $('#modal-edit-colaborador').modal('show');
  }
  Editar(item:any) {
    this.data = JSON.parse(JSON.stringify(item));
    this.data.title = item.empresa;
    $('#modal-edit-colaborador').modal('show');
  }
  GuardarFechas() {
    this.globals.config.resvip_fecha_inicio = moment($('#txtFechaInscripciones').data('daterangepicker').startDate).format('YYYY-MM-DD');
    this.globals.config.resvip_fecha_fin = moment($('#txtFechaInscripciones').data('daterangepicker').endDate).format('YYYY-MM-DD 23:59:59');
    let params:any = {
      resvip_fecha_inicio: this.globals.config.resvip_fecha_inicio,
      resvip_fecha_fin: this.globals.config.resvip_fecha_fin
    };
    $.buttonloading('btn-guardar-fechas', 'Enviando');
    this.globals.Api('/guardar-resvip-config', params).subscribe(data => {
      $.buttoncancelloading('btn-guardar-fechas');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('btn-guardar-fechas');
    });
  }
  Guardar() {
    if (
      !this.data.empresa
      || this.data.empresa == ''
      || !this.data.codpostal
      || this.data.codpostal == ''
      || !this.data.localidad
      || this.data.localidad == ''
      || !this.data.provincia
      || this.data.provincia == ''
      || !this.data.telefono
      || this.data.telefono == ''
      || !this.data.email
      || this.data.email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    // if (!this.data.email || this.data.email == '') this.data.email = this.globals.GenerateRandomString(6).toLowerCase();
    // this.data.clave = ' ';
    this.data.id_rol = 4;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('btn-guardar-colaborador', 'Enviando');
      this.globals.Api('/crear-usuario', this.data).subscribe(data => {
        $.buttoncancelloading('btn-guardar-colaborador');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un colaborador con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-edit-colaborador').modal('hide');
        this.CargarDatos();
      }, error => {
        $.buttoncancelloading('btn-guardar-colaborador');
      });
    } else {
      $.buttonloading('btn-guardar-colaborador', 'Enviando');
      this.globals.Api('/editar-usuario', this.data).subscribe(data => {
        $.buttoncancelloading('btn-guardar-colaborador');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un colaborador con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        $('#modal-edit-colaborador').modal('hide');
        this.CargarDatos();
      }, error => {
        $.buttoncancelloading('btn-guardar-colaborador');
      });
    }
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar colaborador?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/borrar-usuario', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.colaboradores.splice(this.colaboradores.findIndex(el => el.id == item.id), 1);
        if (this.colaboradores.length) this.SelColaborador(this.colaboradores[0]);
        this.appref.tick();
      });
    });
  }
  SelColaborador(item:any) {
    for (let item of this.colaboradores) item.active = null;
    item.active = true;
    this.colaborador = item;
  }
  DatosLocalidad(event:any = null, idtxt:string = null) {
    let codpostal = '';
    codpostal = (event ? event.target.value : this.data.codpostal);
    if (!codpostal || codpostal == '') return;
    this.globals.Api('/localidad', {codpostal: codpostal}).subscribe(data => {
      if (!data || !data.localidad) {
        this.data.localidad = '';
        this.data.provincia = '';
        this.appref.tick();
        return;
      }
      if (idtxt && idtxt != '') {
        $('#'+idtxt).html(data.localidad+', '+data.provincia);
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
      this.appref.tick();
    });
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID Colaborador: ${item.id}`,'Copiado al portapapeles');
  }
  EnviarConfirmacion(item:any) {
    swal({
      title: "¿Enviar confirmación al colaborador?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true,
      showLoaderOnConfirm: true
    },() => {
      this.globals.Api('/enviar-confirmacion-colaborador', {id: item.id}).subscribe(data => {
        if (data && data.error) {
          swal('Error', data.value, 'error');
          return;
        }
        swal('Enviado', 'Confirmación enviada al colaborador', 'success');
      }, error =>  {
        swal('Error', 'Se ha producido un error', 'error');
      });
    });
  }
  EditarReserva(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit/reserva?from=colaboradores')).then();
  }
}
