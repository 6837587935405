<div class="modal fade" id="modal-seleccion-actividad" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title font-20 text-primary"><i class="icofont-search-user font-30"></i> Selecci&oacute;n de actividad</h4>
                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            </div>
            <div class="modal-body" style="background-color:#fafafa;">
                <div class="form-group">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar actividad ..." [(ngModel)]="params.filtro" (keyup.enter)="Buscar()">
                        <span class="input-group-addon bg-primary" (click)="Buscar()"><i class="fa fa-search"></i></span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive table-grid padding-0" style="max-height:426px;overflow:auto;">
                    <table class="table table-striped table-hover table-admin">
                        <thead>
                        <tr>
                            <th class="th-imagen"></th>
                            <th order="titulo">Actividad</th>
                            <th order="ubicacion">Ubicaci&oacute;n</th>
                            <th order="fecha">Fecha</th>
                            <th order="empresa">Empresa</th>
                            <th style="width:50px">Seleccionar</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of actividades" class="cursor" (click)="Seleccionar(item)">
                                <td class="text-center">
                                    <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado">
                                    <!-- <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i> -->
                                </td>
                                <td class="font-bold text-primary">
                                    <div>{{item.titulo}}</div>
                                </td>
                                <td>{{item.ubicacion}}</td>
                                <td>{{item.fecha}}</td>
                                <td>{{item.empresa}}</td>
                                <td class="text-center btntd"><a class="btn btn-primary btn-xs"><i class="fa fa-check text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                        <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                            <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                            <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                            <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                            <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                            <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                        </ul>
                    </div> 
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>