import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;
declare let toastr: any;

@Component({
  selector: 'app-usuario-actividades-reservas',
  templateUrl: './reservas.component.html'
})
export class UsuariosReservasActividadesComponent implements OnInit {
  public reservas = [];
  public params:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: '',
    order: '',
    dir: '',
    agrupado: true
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.btnmenu();
    $.disableinputnumberwheel();
  }
  Actualizar() {
  }
  Nuevo() {
  }
  Reservar(item:any) {
  }
  SeleccionarUsuario(item:any) {
  }

}
