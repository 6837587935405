<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
        <app-header></app-header>

        <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-4 col-md-8 col-xs-12">
            <h2>
            <i class="icofont-opposite"></i>
            Actividades
            <button class="btn btn-xs" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
            </h2>
            <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard">Inicio</a>
            </li>
            <li class="breadcrumb-item active">
                <strong>Actividades</strong>
            </li>
            </ol>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-12 my-auto text-center no-wrap">
            <label class="col-form-label inline-block m-r-10">Mostrar</label>
            <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            </select>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12 my-auto text-center">
            <div class="input-group">
            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
            <div class="input-group-append cursor" (click)="Buscar()">
                <span class="input-group-addon" style="font-size:20px;padding-top:6px;"><i class="fa fa-search"></i></span>
            </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
            <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva actividad</a>
        </div>
        </div>
        <div class="row animated fadeInRight">
            <div class="btn-group" role="group" *ngIf="globals.me && (globals.me.id_rol == 0 || globals.me.id_rol == 1)">
                <button type="button" class="btn btn-default" (click)="FiltrarUbicacion()">Todas</button>
                <button type="button" class="btn btn-monfrague" (click)="FiltrarUbicacion('1')">Monfragüe</button>
                <button type="button" class="btn btn-tajo" (click)="FiltrarUbicacion('2')">Tajo</button>
            </div>
            <div class="table-responsive table-grid ibox-content padding-0">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th></th>
                        <th order="titulo">Actividad</th>
                        <th order="fecha" style="width:120px">Fecha</th>
                        <th order="categoria" style="width:230px">Categor&iacute;a</th>
                        <th order="ubicacion" style="width:230px">Ubicaci&oacute;n</th>
                        <th order="empresa" style="width:200px">Empresa</th>
                        <th style="width:100px" order="aforo">Inscripc.</th>
                        <th style="width:50px">Duplicar</th>
                        <th style="width:50px">Ver</th>
                        <th style="width:50px">Editar</th>
                        <th style="width:50px">Borrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of datagrid">
                            <tr class="cursor {{item.borrado ? 'bg-tr-borrado' : null}}">
                                <td class="text-center" (click)="Editar(item)">
                                    <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                                    <i *ngIf="!item.imagen || item.imagen == ''" class="icofont-opposite font-25"></i>
                                </td>
                                <td class="" (click)="Editar(item)">
                                    <div class="font-bold text-primary">{{item.titulo}}</div>
                                    <div class="font-10">
                                        ID: {{item.id}} <a class="btn btn-default btn-xs" (click)="Portapapeles($event, item)"><i class="fa fa-clipboard "></i></a>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-primary">{{item.fecha_format}}</div>
                                    <div class="font-11" *ngIf="item.fecha_inicio_reservas_format && item.fecha_fin_reservas_format">
                                        Inscripciones:<br>
                                        <div class="font-bold text-secondary">{{item.fecha_inicio_reservas_format}}</div>
                                        <div class="font-bold text-secondary">{{item.fecha_fin_reservas_format}}</div>
                                    </div>
                                </td>
                                <td>{{item.categoria}}</td>
                                <td>
                                    <div class="font-italic">{{item.localidad}}</div>
                                    <div>
                                        <span class="circulo-color {{item.id_ubicacion == '1' ? 'monfrague' : 'tajo'}}"></span>
                                        {{item.ubicacion}}
                                    </div>
                                </td>
                                <td><div>{{item.empresa}}</div></td>
                                <td class="breaktd">
                                    <span *ngIf="item.confirmadas"><b>{{item.confirmadas}}</b> de </span>{{item.aforo}} Pers.<br>
                                    <div class="progress" *ngIf="item.confirmadas">
                                        <div class="progress-bar" style="width:{{item.confirmadas*100/item.aforo}}%" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </td>
                                <td class="text-center btntd"><a class="btn btn-default btn-xs" (click)="Duplicar(item)"><i class="fa fa-clone"></i></a></td>
                                <td class="text-center btntd"><a class="btn btn-default btn-xs" target="_blank" [routerLink]="['/actividad/'+item.seo_url]"><i class="fa fa-eye"></i></a></td>
                                <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                            <tr *ngIf="item.relacionadas && item.relacionadas.length">
                                <td colspan="20" style="padding: 0;padding-left:30px;background-color:#F2F2F2;">
                                    <tr class="cursor {{item.borrado ? 'bg-tr-borrado' : null}}" *ngFor="let item of item.relacionadas" style="width: 100%;display: table;background-color:transparent;">
                                        <td style="padding:0">
                                            <i class="fa fa-chevron-right"></i>
                                        </td>
                                        <td class="text-center" (click)="Editar(item)">
                                            <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                                            <i *ngIf="!item.imagen || item.imagen == ''" class="icofont-opposite font-25"></i>
                                        </td>
                                        <td class="" (click)="Editar(item)">
                                            <div class="font-bold text-primary">{{item.titulo}}</div>
                                            <div class="font-10">ID: {{item.id}} <a class="btn btn-default btn-xs" (click)="Portapapeles($event, item)"><i class="fa fa-clipboard "></i></a></div>
                                        </td>
                                        <td>
                                            <div class="text-primary">{{item.fecha_format}}</div>
                                            <div class="font-11" *ngIf="item.fecha_inicio_reservas_format && item.fecha_fin_reservas_format">
                                                Inscripciones:<br>
                                                <div class="font-bold text-secondary">{{item.fecha_inicio_reservas_format}}</div>
                                                <div class="font-bold text-secondary">{{item.fecha_fin_reservas_format}}</div>
                                            </div>
                                        </td>
                                        <td>{{item.categoria}}</td>
                                        <td>
                                            <div class="font-italic">{{item.localidad}}</div>
                                            <div>{{item.ubicacion}}</div>
                                        </td>
                                        <td><div>{{item.empresa}}</div></td>
                                        <td class="breaktd">
                                            <span *ngIf="item.confirmadas"><b>{{item.confirmadas}}</b> de </span>{{item.aforo}} Pers.<br>
                                            <div class="progress" *ngIf="item.confirmadas">
                                                <div class="progress-bar" style="width:{{item.confirmadas*100/item.aforo}}%" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </td>
                                        <td class="text-center btntd"><a class="btn btn-default btn-xs" (click)="Duplicar(item)"><i class="fa fa-clone"></i></a></td>
                                        <td class="text-center btntd"><a class="btn btn-default btn-xs" target="_blank" [routerLink]="['/actividad/'+item.seo_url]"><i class="fa fa-eye"></i></a></td>
                                        <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                        <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                    </tr>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div class="dataTables_paginate paging_simple_numbers m-r-10">
                <ul class="pagination justify-content-end">
                    <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                    <a (click)="Paging('prev')" class="page-link">Anterior</a>
                    </li>
                    <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                    <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                    </li>
                    <li class="paginate_button page-item active">
                    <a class="page-link cursor">{{params.page_index}}</a>
                    </li>
                    <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                    <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                    </li>
                    <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                    <a (click)="Paging('next')" class="page-link">Siguiente</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>

        <app-footer></app-footer>
    </div>

</div>