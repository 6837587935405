<div class="top-navigation">
    <div id="wrapper">
      <div id="page-wrapper" class="white-bg">

        <app-cabecera [titulo]="titulo"></app-cabecera>

        <div class="texto-legal wrapper wrapper-content animated fadeInDown">
          <div class="container">
              <div [innerHTML]="texto"></div>
              <!-- <div class="m-t-30 m-b-40 text-center">
                <a class="btn btn-primary" style="min-width:200px;" onclick="window.history.back()"><i class="fa fa-arrow-left"></i> Volver</a>
              </div> -->
          </div>
        </div>
    
      </div>
    </div>
</div>