<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
      <app-header></app-header>

      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-6 col-md-8 col-xs-12">
          <h2>
            <i class="icofont-tasks"></i>
            Colaboradores
            <button class="btn btn-xs" (click)="CargarDatos()"><i class="fa fa-refresh"></i></button>
          </h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard">Inicio</a>
            </li>
            <li class="breadcrumb-item active">
                <strong>Colaboradores</strong>
            </li>
          </ol>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 my-auto text-right">
          <a class="btn btn-default btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo colaborador</a>
        </div>
      </div>
      <div class="row animated fadeInRight m-t-20">
        <div class="col-lg-3 col-md-4 col-sm-12">
          <div class="ibox border">
            <div class="ibox-title block font-bold">Fecha de inscripción anticipadas</div>
            <div class="ibox-content">

              <div class="input-group date">
                <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                <input id="txtFechaInscripciones" type="text" class="form-control" autocomplete="off" [(ngModel)]="data.fecha_inicio_reservas_format">
              </div>

              <div class="text-right">
                <a id="btn-guardar-fechas" class="btn btn-default btn-success" (click)="GuardarFechas()"> Guardar fechas</a>
              </div>
            </div>
          </div>
          <ul class="list-group list-colaboradores" style="max-height:56vh;overflow:auto">
            <li class="list-group-item {{item.active ? 'active' : null}} {{!item.acceso ? 'no-activo' : null}}"
              style="line-height:1.2;"
              *ngFor="let item of colaboradores"
              (click)="SelColaborador(item)">
              <div>{{item.empresa}}</div>
              <div class="font-14" *ngIf="!item.acceso">Pendiente de confirmar</div>
              <div class="badge-top-right" *ngIf="item.total_reservas">
                <span class="badge-numero">{{item.total_reservas}}</span> res.
              </div>
              <div class="font-12 font-normal" *ngIf="item.ubicacion">
                <span class="circulo-color xs {{item.id_ubicacion == '1' ? 'monfrague' : 'tajo'}}"></span>
                {{item.ubicacion}}
              </div>
              <div *ngIf="globals.me.id_rol == 0" style="line-height:1.1;">
                <small class="font-11" style="word-break:break-all;" (click)="Portapapeles($event, item)">{{item.id}}</small>
              </div>
            </li>
            <li class="list-group-item list-item-btn">
              <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir colaborador</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-12">
          <ng-container *ngIf="colaborador">

            <div class="ibox border panel-colaborador">
              <div class="ibox-title block font-bold font-18">
                {{colaborador.empresa}}
                <span class="m-l-10 font-14 text-muted" *ngIf="!colaborador.acceso">Pendiente de confirmar</span>
                <span class="m-l-30" *ngIf="colaborador.total_reservas">
                  <span class="font-normal font-14 m-r-5">Total reservas anticipadas:</span> {{colaborador.total_reservas}}
                </span>
                <span class="m-l-30" *ngIf="colaborador.total_visitantes">
                  <span class="font-normal font-14 m-r-5">Total visitantes:</span> {{colaborador.total_visitantes}}
                </span>
              </div>
              <div class="ibox-content">

                <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <img [src]="colaborador.imagen" [alt]="colaborador.empresa" *ngIf="colaborador.imagen" class="qr img-fluid m-b-10">
                    <img [src]="colaborador.qr" *ngIf="colaborador.qr && !colaborador.imagen" class="qr img-fluid m-b-10">
                  </div>
                  <div class="col-md-8 col-sm-12">
                    <div class="m-b-20">
                      <a class="btn btn-primary m-r-20" (click)="Editar(colaborador)"><i class="fa fa-edit text-white"></i> Editar</a>
                      <a class="btn btn-danger text-white m-r-20" (click)="Borrar(colaborador)"><i class="fa fa-trash text-white"></i> Borrar</a>
                      <a class="btn btn-success" (click)="EnviarConfirmacion(colaborador)"><i class="fa fa-envelope text-white"></i> Enviar confirmación</a>
                    </div>
                    <h6 class="font-16" *ngIf="colaborador.codpostal">{{colaborador.codpostal}} {{colaborador.localidad}}, {{colaborador.provincia}}</h6>
                    <h6 class="font-16" *ngIf="colaborador.email">{{colaborador.email}}</h6>
                    <h6 class="font-16" *ngIf="colaborador.telefono">{{colaborador.telefono}}</h6>
                    <h6 class="font-16" *ngIf="colaborador.ubicacion"><span class="circulo-color {{colaborador.id_ubicacion == '1' ? 'monfrague' : 'tajo'}}"></span> {{colaborador.ubicacion}}</h6>
                    <h6 class="font-16" *ngIf="colaborador.servicios">
                      Servicios:<br>
                      <div class="font-14" style="font-weight:normal;line-height:1.1em;">{{colaborador.servicios}}</div>
                    </h6>
                    <h6 class="font-16">
                      <a class="btn btn-success" target="_blank"
                        href="https://app.mesdelareservabiosfera.es/seleccion-actividad?keycol2022034123tkaHTplk={{colaborador.id}}">
                        <i class="fa fa-external-link"></i> Enlace para reservar
                      </a>
                    </h6>
                    <img [src]="colaborador.qr" *ngIf="colaborador.qr && colaborador.imagen" class="m-t-10" style="height:110px;object-fit:contain;">
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12" *ngIf="colaborador.reservas && colaborador.reservas.length">
                <div class="feed-activity-list">
                  <div class="ibox border panel-colaborador">
                    <div class="ibox-title block font-bold font-18">Reservas anticipadas</div>
                    <div class="ibox-content">

                      <div class="feed-element cursor" *ngFor="let item of colaborador.reservas" (click)="EditarReserva(item)">
                        <div class="row">
                          <div class="col-md-6 col-sm-12">
                            <div class="pull-right">
                              <img [src]="'https://quickchart.io/chart?cht=qr&chs=50x50&chld=M%7C0&chl='+item.referencia" class="m-r-10" style="height:50px;vertical-align:top;">
                            </div>
                            <div class="media-body">
                              <strong class="text-secondary">{{item.referencia}}</strong><br>
                              <strong>{{item.nombre}} {{item.apellidos}}</strong><br>
                              <div>{{item.localidad}}, {{item.provincia}}</div>
                              <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                              <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                              <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                            </div>
                            <div class="m-l-30 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                                <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                                <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                    <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                                </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <img [src]="item.imagen" alt="" *ngIf="item.imagen" class="block m-t-10 m-b-10" style="height:80px;border-radius:8px;">
                            <h3>{{item.titulo}}</h3>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12" *ngIf="colaborador.reservas_visitas && colaborador.reservas_visitas.length">
                <div class="feed-activity-list">
                  <div class="ibox border panel-colaborador">
                    <div class="ibox-title block font-bold font-18">Visitantes</div>
                    <div class="ibox-content">

                      <div class="feed-element cursor" *ngFor="let item of colaborador.reservas_visitas">
                        <div class="row">
                          <div class="col-md-6 col-sm-12">
                            <div class="pull-right">
                              <img [src]="'https://quickchart.io/chart?cht=qr&chs=50x50&chld=M%7C0&chl='+item.referencia" class="m-r-10" style="height:50px;vertical-align:top;">
                            </div>
                            <div class="media-body">
                              <strong class="text-secondary">{{item.referencia}}</strong><br>
                              <strong>{{item.nombre}} {{item.apellidos}}</strong><br>
                              <div>{{item.localidad}}, {{item.provincia}}</div>
                              <div><i class="fa fa-envelope"></i> {{item.email}}</div>
                              <div><i class="fa fa-phone"></i> {{item.telefono}}</div>
                              <div *ngIf="item.turno">Turno: {{item.turno}}</div>
                              <div *ngIf="item.fecha_visita">Fecha visita: <b>{{item.fecha_visita}}</b></div>
                            </div>
                            <div class="m-l-30 m-t-5 font-12" *ngIf="item.personas && item.personas.length">
                                <div>Acompa&ntilde;antes ({{item.personas.length}})</div>
                                <div class="border-top-light m-t-5 p-t-5 p-b-5" *ngFor="let elem of item.personas">
                                    <strong>{{elem.nombre}} {{elem.apellidos}}</strong>
                                </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <img [src]="item.imagen" alt="" *ngIf="item.imagen" class="block m-t-10 m-b-10" style="height:80px;border-radius:8px;">
                            <h3>{{item.titulo}}</h3>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <app-footer></app-footer>
    </div>

</div>

<div class="modal fade" id="modal-edit-colaborador" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title font-20 text-primary">{{data.title}}</h4>
              <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          </div>
          <div class="modal-body" style="background-color:#fafafa;">

            <div class="form-group">
              <label>Logo del negocio <span class="text-danger">*</span></label>
              <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                <i class="icofont-camera-alt"></i>
              </div>
              <div class="img-preview-container cursor relative" (click)="SelecImagen()" *ngIf="data.imagen && data.imagen != ''">
                <img [src]="data.imagen">
                <button class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label>Nombre del negocio <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                  </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="form-group">
                  <label>Territorio <span class="text-danger">*</span></label>
                  <select class="form-control" [(ngModel)]="data.id_ubicacion" [disabled]="globals.me.id_rol == 2">
                    <option value="1">Monfragüe</option>
                    <option value="2">Tajo - Tejo Internacional</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label>Nombre persona de contacto</label>
                    <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                  </div>
              </div>
              <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label>Apellidos persona de contacto</label>
                    <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 col-xs-12">
                  <div class="form-group">
                      <label>C&oacute;digo postal <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="8" autocomplete="off"
                          (change)="DatosLocalidad()">
                  </div>
              </div>
              <div class="col-sm-4 col-xs-12">
                <div class="form-group">
                    <label>Localidad <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80" autocomplete="off">
                </div>
              </div>
              <div class="col-sm-4 col-xs-12">
                  <div class="form-group">
                      <label>Provincia <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80" autocomplete="off">
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label>Teléfono <span class="text-danger">*</span></label>
                    <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                  </div>
              </div>
              <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label>Email <span class="text-danger">*</span></label>
                    <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="80">
                  </div>
              </div>
            </div>
            <div class="form-group">
              <label>Web</label>
              <input type="url" class="form-control" [(ngModel)]="data.web" maxlength="200">
            </div>
            <div class="form-group">
              <label>Actividades o servicios que realiza</label>
              <textarea class="form-control" [(ngModel)]="data.servicios" maxlength="2000" rows="4"></textarea>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btn-guardar-colaborador" type="button" class="btn btn-primary" (click)="Guardar()">Guardar</button>
          </div>
      </div>
  </div>
</div>
<svg style="display:none;">
  <style>
    .list-colaboradores .no-activo {
      opacity: .6;
    }
  </style>
</svg>