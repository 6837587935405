<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
        <app-header></app-header>

        <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-4 col-md-8 col-xs-12">
            <h2>
            <i class="icofont-opposite"></i>
            Actividades Complementarias
            <button class="btn btn-xs" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
            </h2>
            <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard">Inicio</a>
            </li>
            <li class="breadcrumb-item active">
                <strong>Actividades Complementarias</strong>
            </li>
            </ol>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-12 my-auto text-center no-wrap">
            <label class="col-form-label inline-block m-r-10">Mostrar</label>
            <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            </select>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12 my-auto text-center">
            <div class="input-group">
            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
            <div class="input-group-append cursor" (click)="Buscar()">
                <span class="input-group-addon" style="font-size:20px;padding-top:6px;"><i class="fa fa-search"></i></span>
            </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
            <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva actividad</a>
        </div>
        </div>
        <div class="row animated fadeInRight">
        <div class="table-responsive table-grid ibox-content padding-0">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th></th>
                    <th order="titulo">Actividad</th>
                    <th order="fecha" style="width:120px">Fecha</th>
                    <th order="lugar" style="width:230px">Lugar</th>
                    <th style="width:50px">Editar</th>
                    <th style="width:50px">Borrar</th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of datagrid" class="cursor {{item.borrado ? 'bg-tr-borrado' : null}}">
                    <td class="text-center" (click)="Editar(item)">
                      <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                      <i *ngIf="!item.imagen || item.imagen == ''" class="icofont-opposite font-25"></i>
                    </td>
                    <td class="" (click)="Editar(item)">
                        <div class="font-bold text-primary">{{item.titulo}}</div>
                        <div class="font-10">ID: {{item.id}} <a class="btn btn-default btn-xs" (click)="Portapapeles($event, item)"><i class="fa fa-clipboard "></i></a></div>
                    </td>
                    <td>
                        <div class="text-primary">{{item.fecha_format}}</div>
                    </td>
                    <td>{{item.lugar}}</td>
                    <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                    <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                </tr>
                </tbody>
            </table>
            <div class="dataTables_paginate paging_simple_numbers m-r-10">
            <ul class="pagination justify-content-end">
                <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                <a (click)="Paging('prev')" class="page-link">Anterior</a>
                </li>
                <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                </li>
                <li class="paginate_button page-item active">
                <a class="page-link cursor">{{params.page_index}}</a>
                </li>
                <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                </li>
                <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                <a (click)="Paging('next')" class="page-link">Siguiente</a>
                </li>
            </ul>
            </div>
        </div>
        </div>

        <app-footer></app-footer>
    </div>

</div>

<div class="modal fade" id="modal-editar-complementaria" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title font-20 text-primary">{{data.title}}</h4>
                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            </div>
            <div class="modal-body" style="background-color:#fafafa;">
                <div class="row">
                    <div class="col-md-4 col-xs-12">
                        <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                            <i class="icofont-camera-alt"></i>
                        </div>
                        <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                            <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                            <small class="block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                            <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                        </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                        <div class="tabs-container">
                            <ul class="nav nav-tabs" role="tablist">
                                <li><a class="nav-link active" data-toggle="tab" href="#tab-es"><img src="assets/img/flags/16/Spain.png" alt=""> ES</a></li>
                                <li><a class="nav-link" data-toggle="tab" href="#tab-pt"><img src="assets/img/flags/16/Portugal.png" alt=""> PT</a></li>
                            </ul>
                            <div class="tab-content">
                            <div role="tabpanel" id="tab-es" class="tab-pane active">
                                <div class="panel-body">
            
                                    <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group">
                                        <label class="text-primary">Título *</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.titulo" maxlength="80">
                                        </div>
                                    </div>
                                    </div>
                                    <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group">
                                        <label class="text-primary">Descripci&oacute;n *</label>
                                        <textarea id="txtdescripcion" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion" maxlength="5000" rows="10"></textarea>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" id="tab-pt" class="tab-pane">
                                <div class="panel-body">
            
                                    <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group">
                                        <label class="text-primary">Título *</label>
                                        <input type="text" class="form-control" [(ngModel)]="data_pt.titulo" maxlength="80">
                                        </div>
                                    </div>
                                    </div>
                                    <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group">
                                        <label class="text-primary">Descripci&oacute;n *</label>
                                        <textarea id="txtdescripcionpt" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data_pt.descripcion" maxlength="5000" rows="10"></textarea>
                                        </div>
                                    </div>
                                    </div>
            
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        <div class="row m-t-20">
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                <label>Fecha</label>
                                <div id="txtFechaComplementaria" class="input-group date">
                                    <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                                    <input type="text" class="form-control" autocomplete="off" [(ngModel)]="data.fecha_format">
                                </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                <label>Tel&eacute;fono de contacto</label>
                                <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="form-group">
                                <label>Lugar</label>
                                <input type="text" class="form-control" [(ngModel)]="data.lugar" maxlength="200">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <div class="form-group">
                                <label>URL</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url" maxlength="400">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                <button id="btnGuardarComplementaria" type="button" class="btn btn-primary" (click)="GuardarComplementaria()">Guardar</button>
            </div>
        </div>
    </div>
</div>