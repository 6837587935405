<div id="wrapper">
  <app-menu></app-menu>
  
  <div id="page-wrapper" class="gray-bg dashbard-1">
    <app-header></app-header>

    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-md-8 col-sm-6 col-xs-12">
          <h2>
            <i class="{{iconclass}}"></i>
            {{title}}
            <small class="m-l-20 font-12" *ngIf="data.alta_format">Alta: {{data.alta_format}}</small>
          </h2>
          <ol class="breadcrumb m-l-10">
              <li class="breadcrumb-item">
                  <a routerLink="/dashboard">Inicio</a>
              </li>
              <li class="breadcrumb-item">
                  <a (click)="Cancelar()">Usuarios</a>
              </li>
              <li class="breadcrumb-item active">
                  <strong>{{title}}</strong>
              </li>
          </ol>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 col-botones">
          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
          <a id="btnGuardar" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
      </div>
    </div>
    <div class="row animated fadeInRight">
      <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist" *ngIf="!globals.config || !globals.config.tipoapp || globals.config.tipoapp == 'general'">
            <li><a class="nav-link active" data-toggle="tab" href="#tab-general">Datos generales</a></li>
          </ul>
          <ul class="nav nav-tabs" role="tablist" *ngIf="globals.config && globals.config.tipoapp == 'gimnasio'">
              <li><a class="nav-link active" data-toggle="tab" href="#tab-general">Datos generales</a></li>
              <li *ngIf="!monitor"><a class="nav-link" data-toggle="tab" href="#tab-gimnasio" *ngIf="!monitor"><i class="icofont-muscle"></i> Gimnasio</a></li>
          </ul>
          <div class="tab-content">
              <div role="tabpanel" id="tab-general" class="tab-pane active">
                  <div class="panel-body">
                    
                    <div class="row">
                      <div class="col-lg-4 col-md-12">
                        <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                          <i class="icofont-camera-alt"></i>
                        </div>
                        <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                          <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                          <small class="block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                          <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-12">
                        
                        <div class="row" *ngIf="globals.config && globals.config.tipoapp == 'actividades'">
                          <div class="col-md-8 col-xs-12">
                            <div class="form-group">
                              <label>Empresa / Organismo</label>
                              <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                            </div>
                          </div>
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label>NIF / CIF</label>
                              <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label class="text-primary">Nombre *</label>
                              <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                            </div>
                          </div>
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label class="text-primary">Apellidos *</label>
                              <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                            </div>
                          </div>
                          <div class="col-md-4 col-xs-12">
                            <label class="text-primary">Rol *</label>
                            <div class="form-group">
                              <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && globals.me.id_rol == 0">
                                <option *ngFor="let item of roles" [value]="item.id">{{item.rol}}</option>
                              </select>
                              <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && (globals.me.id_rol == 1 || globals.me.id_rol == 2)">
                                <option *ngFor="let item of roles" [value]="item.id" [disabled]="item.id == 0 || item.id == 1">{{item.rol}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="globals.config && globals.config.tipoapp == 'gimnasio'"
                          >
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label class="text-primary">Nº Usuario</label>
                              <input type="text" class="form-control" [(ngModel)]="data.referencia" maxlength="50"
                                [readonly]="globals.config && globals.config.tipoapp == 'gimnasio'">
                            </div>
                          </div>
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label>Alias</label>
                              <input type="text" class="form-control" [(ngModel)]="data.alias" maxlength="50">
                            </div>
                          </div>
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label class="text-primary">Fecha nacimiento *</label>
                              <div class="input-group date" id="fechanacimiento">
                                  <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                                  <input type="text" class="form-control">
                              </div>
                              <label *ngIf="data.edad">Edad: {{data.edad}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 col-xs-12">
                            <div class="form-group">
                              <label>Direcci&oacute;n</label>
                              <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                            </div>
                          </div>
                          <div class="col-md-3 col-xs-12">
                            <div class="form-group">
                              <label>C&oacute;digo postal</label>
                              <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                                (change)="DatosLocalidad()">
                            </div>
                          </div>
                          <div class="col-md-4 col-xs-12">
                            <div class="form-group">
                              <label>Localidad</label>
                              <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3 col-xs-12">
                            <div class="form-group">
                              <label>Tel&eacute;fono</label>
                              <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                            </div>
                          </div>
                        </div>
                        <div class="ibox border">
                          <div class="ibox-title"><i class="fa fa-sign-in"></i> Acceso al sistema de reservas</div>
                          <div class="ibox-content">
                            <div class="row">
                              <div class="col-md-5 col-xs-12">
                                <div class="form-group">
                                  <label class="text-primary"><i class="fa fa-envelope"></i> Email *</label>
                                  <input type="email" class="form-control"[(ngModel)]="data.email" maxlength="80">
                                </div>
                              </div>
                              <div class="col-md-4 col-xs-12">
                                <div class="form-group" *ngIf="data.id == ''">
                                  <label class="text-primary"><i class="fa fa-lock"></i> Contraseña *</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                                  <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                                </div>
                                <div class="form-group" *ngIf="data.id != ''">
                                  <a class="btn btn-sm btn-warning text-white m-t-30" (click)="EditarClave()"><i class="fa fa-lock"></i> Cambiar contraseña</a>
                                </div>
                              </div>
                              <div class="col-md-3 col-xs-12">
                                <div class="form-group">
                                  <label class="text-primary">Acceso</label>
                                  <select class="form-control" [(ngModel)]="data.acceso">
                                    <option value="1">Sí</option>
                                    <option value="0">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    
                  </div>
              </div>
              <div role="tabpanel" id="tab-gimnasio" class="tab-pane" *ngIf="globals.config && globals.config.tipoapp == 'gimnasio'">
                  <div class="panel-body">

                    <div class="row">
                      <div class="col-md-6 col-sm-12">

                        <div class="ibox border">
                          <div class="ibox-title"><i class="fa fa-credit-card"></i> Tarifa</div>
                          <div class="ibox-content">
  
                            <div class="form-group">
                                <label>Tipo de tarifa</label>
                                <select id="cmbTarifas" class="form-control" [(ngModel)]="data.gimnasio.id_tarifa">
                                  <option *ngFor="let item of tarifas" [value]="item.id" 
                                    [attr.dias]="item.dias">{{item.tarifa}}</option>
                                </select>
                            </div>
                            <div class="row">
                              <div class="col-md-6 col-sm-12">

                                <div class="form-group">
                                  <label>Fecha de pago</label>
                                  <div class="input-group date" id="fechapago">
                                      <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                                      <input type="text" class="form-control">
                                  </div>
                                </div>

                              </div>
                              <div class="col-md-6 col-sm-12">
                                
                                <div class="form-group">
                                  <label>Fecha de caducidad</label>
                                  <div class="input-group date" id="fechacaducidad">
                                      <span class="input-group-addon" style="pointer-events:none;"><i class="fa fa-calendar"></i></span>
                                      <input id="txtfechacaducidad" type="text" class="form-control" disabled>
                                  </div>
                                </div>

                              </div>
                            </div>
  
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
              </div>
          </div>
      </div>
    </div>
    
    <div class="col-botones">
      <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
      <a id="btnGuardar2" class="btn btn-primary m-l-20" (click)="Guardar()">Guardar</a>
    </div>

    <app-footer></app-footer>
  </div>

</div>
<div class="modal inmodal fade" id="modal-editar-clave-usuario" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title">{{data.title}}</h4>
        </div>
        <div class="modal-body">
            <p>Nombre: <strong>{{data.nombre}}</strong></p>
            <p>Email: <strong>{{data.email}}</strong></p>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Nueva contrase&ntilde;a <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                    <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarClaveUsuarioModal" type="button" class="btn btn-primary" (click)="GuardarClaveUsuario()">Guardar</button>
        </div>
        </div>
    </div>
</div>