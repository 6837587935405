<div class="top-navigation">
    <div id="wrapper">
        <div id="page-wrapper">
            <app-cabecera></app-cabecera>
            <ng-container *ngIf="pagina == 'validar'">
                <div class="middle-box text-center animated fadeInDown" style="width:auto;max-width:600px;padding-left:15px;padding-right:15px;margin-top:0;">
                    <h2>{{globals.Literal('Validar visita')}}</h2>
                    <h3 *ngIf="colaborador" class="font-bold font-22">{{colaborador.empresa}}</h3>
                    <hr>
                    <h3 class="font-normal">{{globals.Literal('Introduce tu numero de reserva')}}</h3>
                    <div class="form-group">
                        <input type="text" class="form-control text-center font-bold font-22" [(ngModel)]="referencia" maxlength="20" 
                            (keydown)="KeyDown($event)" 
                            (keyup)="KeyDown($event)" 
                            (input)="KeyDown($event)">
                    </div>
                    <a class="btn btn-primary btn-block font-22" (click)="Validar()">{{globals.Literal('Validar')}}</a>
                </div>
            </ng-container>
            <ng-container *ngIf="pagina == 'confirmacion'">
                <div class="middle-box text-center animated fadeInDown" style="width:auto;max-width:600px;padding-left:15px;padding-right:15px;margin-top:0;">
                    <h2>{{data.referencia}}</h2>
                    <h3 class="font-18">{{data.titulo}}</h3>
                    <img [src]="data.imagen" alt="" *ngIf="data.imagen" style="height:80px">
                    <ul class="list-group list-colaboradores text-left m-t-30 m-b-20">
                        <li class="list-group-item">
                            <b>{{data.nombre}} {{data.apellidos}}</b>
                        </li>
                        <li class="list-group-item" *ngFor="let elem of data.personas">
                            <span class="font-normal">{{elem.nombre}} {{elem.apellidos}}</span>
                        </li>
                    </ul>
                    <a class="btn btn-primary btn-block font-22" (click)="Confirmar()">{{globals.Literal('Confirmar')}}</a>
                </div>
            </ng-container>
        </div>
    </div>
</div>