import { Component, OnInit, NgZone, ApplicationRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let swal: any;

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html'
})
export class CabeceraComponent implements OnInit {
  @Input() titulo:string = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public route: ActivatedRoute,
    public globals: Globals
  ) {}

  ngOnInit(): void {
  }
  SetIdioma(idioma:string) {
    this.globals.idioma = idioma;
    localStorage.setItem('idioma', idioma);
    window.location.reload();
  }

}
