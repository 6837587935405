<div class="middle-box text-center loginscreen animated fadeInDown">
  <div>
    <img src="assets/img/logo-2024.png" class="logo-login" />
  </div>

  <div class="bg-login {{!recuperarclave ? '' : 'hidden'}} animated fadeIn">
    <p>Acceso al sistema de reservas</p>
    <div class="form-group">
        <input id="txtEmail" type="email" class="form-control" placeholder="Email" [(ngModel)]="email" autocomplete="off" required>
    </div>
    <div class="form-group">
      <div class="input-group input-group-clave">
        <input id="txtClave" type="password" class="form-control" placeholder="Contrase&ntilde;a" [(ngModel)]="clave"  autocomplete="off" required>
        <div class="input-group-append cursor" (click)="VerClave()">
          <span class="input-group-text">
            <i class="fa fa-eye d-none"></i>
            <i class="fa fa-eye-slash"></i>
          </span>
        </div>
      </div>
    </div>
    <a id="btnAcceder" class="btn btn-login block full-width m-b font-18" (click)="Login()">ENTRAR</a>
    <a class="cursor" (click)="recuperarclave = true"><small>¿Has olvidado tu contrase&ntilde;a?</small></a>
  </div>
  <div class="bg-login {{recuperarclave ? '' : 'hidden'}} animated fadeIn">
    <p>Introduce tu correo y te enviaremos tu nueva contrase&ntilde;a</p>
    <div class="form-group">
        <input id="txtEmailRecuperar" type="email" class="form-control" placeholder="Email" [(ngModel)]="email" required>
    </div>
    <div class="text-center">
        <a id="btnRecuperarClave" class="btn btn-login btn-block cursor" (click)="RecuperarClave()">Enviar correo recuperaci&oacute;n</a><br>
        <button type="button" class="btn btn-secondary m-t-30" (click)="recuperarclave = false">Atr&aacute;s</button>
    </div>
  </div>

</div>