import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-checkpoint',
  templateUrl: './checkpoint.component.html'
})
export class CheckpointComponent implements OnInit {
  public idcolaborador:any = this.globals.GetQueryString('v');
  public colaborador:any = null;
  public data:any = null;
  public referencia:string = '';
  public pagina:string = 'validar';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public route: ActivatedRoute,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    if (this.globals.GetQueryString('lang')) {
      this.globals.idioma = this.globals.GetQueryString('lang');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
    } else {
      this.globals.idioma = localStorage.getItem('idioma');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
    }
    this.title.setTitle(this.globals.Literal('Validar visita'));
    // $('body').addClass('white-bg');
    $('body').css('background', '#f3f3f4');
    if (!this.idcolaborador || this.idcolaborador == '') {
      this.ngZone.run(() => this.router.navigateByUrl('/')).then();
      return;
    }
    this.globals.Api('/act-colaborador-publico', {id: this.idcolaborador}).subscribe(data => {
      if (!data) {
        this.ngZone.run(() => this.router.navigateByUrl('/')).then();
        return;
      }
      this.colaborador = data;
      this.appref.tick();
    });
  }

  KeyDown(event:any) {
    if (event.target.value) event.target.value = event.target.value.toString().toUpperCase().trim();
    this.appref.tick();
  }
  Validar() {
    if (!this.referencia || this.referencia.trim() == '') return;
    this.globals.Api('/act-reserva-publico', {referencia: this.referencia, id_estado: 2, idioma: this.globals.idioma}).subscribe(data => {
      if (!data) {
        swal(this.globals.Literal('Error'), this.globals.Literal('Mensaje error'), 'error');return;
      }
      this.data = data;
      this.pagina = 'confirmacion';
      this.appref.tick();
    });
  }
  Confirmar() {
    if (!this.data || !this.data.id) return;
    this.globals.Api('/act-confirmar-visita', {id_reserva: this.data.id, id_colaborador: this.idcolaborador}).subscribe(data => {
      if (!data || data.error) {
        if (data.value == 'Ya registrado') {
          swal(this.globals.Literal('Ya registrada'), this.globals.Literal('Reserva ya registrada'), 'info');return;
        }
        swal(this.globals.Literal('Error'), this.globals.Literal('Mensaje error'), 'error');return;
      }
      this.pagina = 'validar';
      this.referencia = '';
      this.appref.tick();
      swal(this.globals.Literal('Enviado'), this.globals.Literal('Datos enviados correctamente'), 'success');return;
    });
  }

}
