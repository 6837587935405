import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-cancelacion',
  templateUrl: './cancelacion.component.html'
})
export class CancelacionComponent implements OnInit {
  public data:any = null;
  public cancelado:boolean = false;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public route: ActivatedRoute,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Cancelar reserva');
    // $('body').addClass('white-bg');
    $('body').css('background', '#f3f3f4');
    if (!this.route.snapshot.params.data) {
      this.ngZone.run(() => this.router.navigateByUrl('/')).then();
      return;
    }
    this.data = JSON.parse(atob(this.route.snapshot.params.data));
    if (this.data && this.data.idioma) this.globals.idioma = this.data.idioma;

    if (this.globals.config && this.globals.config.tipoapp == 'actividades') {
      if (!this.data.id || !this.data.email) {
        this.ngZone.run(() => this.router.navigateByUrl('/')).then();
      }
    }
  }

  Cancelar() {
    if (this.globals.config && this.globals.config.tipoapp == 'actividades') {
      swal({
        title: `¿${this.globals.Literal('Cancelar reserva')}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: this.globals.Literal('Si'),
        cancelButtonText: this.globals.Literal('No'),
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },() => {
        this.globals.Api('/act-cancelar-reserva', {id: this.data.id, email: this.data.email}).subscribe(data => {
          if (!data || data.error) {
            if (data.value == 'Ya cancelada') {
              swal(`${this.globals.Literal('Reserva ya cancelada')}`, `${this.globals.Literal('Mensaje ya cancelada')}`, 'info');return;
            }
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          this.cancelado = true;
          setTimeout(() => {
            swal(`${this.globals.Literal('Reserva cancelada')}`, `${this.globals.Literal('Reserva cancelada correctamente')}`, 'success');
          }, 200);
        }, error => {
          swal('Error', 'Se ha producido un error', 'error');return;
        });
      });
    }
  }

}
