<div id="wrapper">
    <app-menu></app-menu>

    <div id="page-wrapper" class="gray-bg dashbard-1">
        <app-header></app-header>

        <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-lg-8 col-md-8 col-xs-12">
                <h2>
                <i class="icofont-calendar"></i>
                    Mis Reservas
                    <button class="btn btn-xs" (click)="Actualizar()"><i class="fa fa-refresh"></i></button>
                </h2>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a routerLink="/dashboard">Inicio</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <strong>Mis Reservas</strong>
                    </li>
                </ol>
            </div>
            <div class="col-lg-4 col-md-6 col-xs-12 my-auto text-center">
                <!-- <a class="btn btn-default btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a> -->
            </div>
        </div>
        <div class="row animated fadeInRight">
            
            <div class="clearfix"></div>
        </div>

        <app-footer></app-footer>
    </div>

</div>