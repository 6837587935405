import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let literales: any;

@Component({
  selector: 'app-actividad',
  templateUrl: './actividad.component.html'
})
export class ActividadComponent implements OnInit {
  public titulo:string = '';
  public url:string = '';
  public id_colaborador:string = this.globals.GetQueryString('keycol2022034123tkaHTplk');
  public data:any = null;
  public reservado:boolean = false;
  public localidades:any = [];

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public route: ActivatedRoute,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.url = this.route.snapshot.params.url;
    // if (!this.globals.GetQueryString('v') || this.globals.GetQueryString('v') == '') {
    //   window.location.href = window.location.href + (window.location.href.indexOf('?') == -1 ? '?' : '&') + 'v=' + this.globals.GenerateRandomString(5).toLowerCase();
    //   return;
    // }
    if (this.globals.GetQueryString('lang')) {
      this.globals.idioma = this.globals.GetQueryString('lang');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
      this.titulo = this.globals.Literal('Reservar actividad');
      this.title.setTitle(this.titulo);
    } else {
      this.globals.idioma = localStorage.getItem('idioma');
      if (!this.globals.idioma || this.globals.idioma == '') this.globals.idioma = 'es';
      this.titulo = this.globals.Literal('Reservar actividad');
      this.title.setTitle(this.titulo);
    }
    this.globals.Api('/act-actividades-publico', {url: this.url, idioma: this.globals.idioma}).subscribe(data => {
      // console.log(data);
      if (!data || !data.length) return;
      this.data = data[0];
      this.data.telefono = '';
      this.data.sexo = 'F';
      this.data.reservas = [
        {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: ''},
        {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: ''},
        {nombre: '', apellidos: '', dni: '', sexo: 'F', codpostal: '', provincia: '', localidad: '', telefono: '', email: '', adulto: ''}
      ];
      this.data.prefijo_telefono = '+34';
      this.title.setTitle(this.data.titulo);
      this.meta.updateTag({name: 'description', content: this.data.description});
      if (this.data.turnos) this.data.turno = this.data.turnos.split(',')[0];
      if (this.id_colaborador && this.id_colaborador != '') {
        this.data.disponible = true;
        this.data.disponible_fecha = true;
        this.data.id_usuario_colaborador = this.id_colaborador;
        if (!this.data.disponible_vip) {
          this.data.disponible = false;
          this.data.disponible_fecha = true;
        }
      }
    });
    this.globals.Api('/localidades-pt').subscribe(data => {
      if (!data || !data.length) return;
      this.localidades = data;
      if (this.globals.idioma == 'pt') {
        // setTimeout(() => {
        //   $(".chosen-select").chosen({no_results_text: "Não encontrado", search_contains: true, width: '100%'}); 
        // }, 50);
        $.autocomplete($('#lbllocalidad')[0], this.localidades);
        for (let i = 2; i <= 4;i++) {
          $.autocomplete($('#lbllocalidad'+i)[0], this.localidades);
        }
      }
    });
    
    var thatgeneral = this;
    $.autocomplete = function(inp, arr) {
        var currentFocus;
        var that = this;
        var typingTimer;                //timer identifier
        var doneTypingInterval = 300; 
        inp.addEventListener("keyup", function(e) {
          clearTimeout(typingTimer);
          var a, b, i, val = this.value;
          if (!val) { return false;}
          if (val.length < 4) { return false; }
          a = document.createElement("DIV");
          a.setAttribute("id", this.id + "autocomplete-list");
          a.setAttribute("class", "autocomplete-items");
          this.parentNode.appendChild(a);
          typingTimer = setTimeout(function() {
            val = inp.value;
            that.closeAllLists();
            currentFocus = -1;
            let max = 7;
            let index = 0;
            for (let item of arr) {
              let titulo_limpio = thatgeneral.globals.CleanedString(item.localidad).toLowerCase().trim();
              let prov_limpio = thatgeneral.globals.CleanedString(item.provincia).toLowerCase().trim();
              let val_limpio = thatgeneral.globals.CleanedString(val).toLowerCase().trim();
              if (titulo_limpio.substr(0, val_limpio.length) == val_limpio) {
                b = document.createElement("DIV");
                b.className = 'localidad-autocomplete';
                var html = `
                  <span>${item.provincia} - <strong>${item.localidad.substr(0, val.length)}</strong>${item.localidad.substr(val.length)}</span>
                  <input type='hidden' value="${item.provincia}">
                  <input type='hidden' value="${item.localidad}">
                `;
                b.innerHTML = html;
                b.setAttribute('data', `${item.provincia} - ${item.localidad}`);
                b.addEventListener("click", function(e) {
                  inp.value = $(this).attr('data');
                  that.closeAllLists();
                });
                a.appendChild(b);
                index++;
              }
              if (index > max) break;
            }
            var div = document.createElement('span');
            div.className = 'clearfix';
            a.appendChild(div);
          }, doneTypingInterval);
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function(e) {
          clearTimeout(typingTimer);
          var x:any = document.getElementById(this.id + "autocomplete-list");
          if (x) x = x.getElementsByTagName("div");
          if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            that.addActive(x);
          } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            that.addActive(x);
          } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
              /*and simulate a click on the "active" item:*/
              if (x) x[currentFocus].click();
            }
          }
        });
        this.addActive = function(x) {
          /*a function to classify an item as "active":*/
          if (!x) return false;
          /*start by removing the "active" class on all items:*/
          that.removeActive(x);
          if (currentFocus >= x.length) currentFocus = 0;
          if (currentFocus < 0) currentFocus = (x.length - 1);
          /*add class "autocomplete-active":*/
          x[currentFocus].classList.add("autocomplete-active");
        }
        this.removeActive = function(x) {
          /*a function to remove the "active" class from all autocomplete items:*/
          for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
          }
        }
        this.closeAllLists = function(elmnt) {
          /*close all autocomplete lists in the document,
          except the one passed as an argument:*/
          // var x = document.getElementsByClassName("autocomplete-items");
          //   for (var i = 0; i < x.length; i++) {
          //     if (elmnt != x[i] && elmnt != inp) {
          //     x[i].parentNode.removeChild(x[i]);
          //   }
          // }
          $('.localidad-autocomplete').remove();
          $('.provincia-autocomplete').remove();
        }
    };
  }
  
  SetIdioma(idioma:string) {
    this.globals.idioma = idioma;
    localStorage.setItem('idioma', idioma);
    window.location.reload();
  }
  
  DatosLocalidad(event:any = null, idtxt:string = null) {
    let codpostal = '';
    codpostal = (event ? event.target.value : this.data.codpostal);
    if (!codpostal || codpostal == '') return;
    this.globals.Api('/localidad', {codpostal: codpostal, idioma: this.globals.idioma}).subscribe(data => {
      if (!data || !data.localidad) {
        if (idtxt && idtxt != '') {
          $('#'+idtxt).html('<span class="text-danger">'+this.globals.Literal('Código postal no valido')+'</span>');
          this.appref.tick();
          return;
        }
        // this.data.localidad = '<span class="text-danger">'+this.globals.Literal('Código postal no valido')+'</span>';
        // this.data.provincia = '';
        $('#lbllocalidad').html('<span class="text-danger">'+this.globals.Literal('Código postal no valido')+'</span>');
        this.appref.tick();
        return;
      }
      if (idtxt && idtxt != '') {
        $('#'+idtxt).html(data.localidad+', '+data.provincia);
        this.appref.tick();
        return;
      }
      $('#lbllocalidad').html(data.localidad+', '+data.provincia);
      this.appref.tick();
    });
  }

  AforoTurno(turno:any) {
    if (!this.data.aforo_turnos || !this.data.aforo_turnos.length) return {};
    return this.data.aforo_turnos.find(el => el.turno == turno);
  }

  AddPersonas() : boolean {
    let ok = true;
    let i = 2;
    for (let item of this.data.reservas) {
      if (this.globals.idioma == 'es') {
        item.provincia = $('#lbllocalidad'+i).text().split(', ')[1];
        item.localidad = $('#lbllocalidad'+i).text().split(', ')[0];
      } else {
        if ($('#lbllocalidad'+i).length && $('#lbllocalidad'+i).val().toString().indexOf(' - ') != -1) {
          item.provincia = $('#lbllocalidad'+i).val().split(' - ')[0];
          item.localidad = $('#lbllocalidad'+i).val().split(' - ')[1];
        }
      }
      if (item.adulto == '' || item.adulto == '0') {
        item.dni = '';
        item.codpostal = '';
        item.telefono = '';
        item.email = '';
        item.provincia = '';
        item.localidad = '';
      }
      if (
        item.nombre == '' && item.apellidos == '' && item.adulto == ''
        ) {
        continue;
      }
      if (
           item.nombre == ''
        || item.apellidos == ''
        ) {
        swal(this.globals.Literal('Complete acompañante')+' '+(i-1), this.globals.Literal('Mensaje Rellene datos'), 'error');
        return false;
      }
      if (item.adulto == '1') {
        if (
          !item.dni || item.dni == ''
        ) {
          swal(this.globals.Literal('Complete acompañante')+' '+(i-1), this.globals.Literal('Mensaje Rellene datos'), 'error');
          return false;
        }
        
        //Validar DNI
        item.dni = item.dni.toString().toUpperCase().trim();
        $('#txtdni'+i).val(item.dni);
        let valdni = /^[XYZ]?\d{5,8}[A-Z]$/;
        let valdnipt = /^[\d]{8}[-]?\d([A-Z]{2}\d)?$/;
        if (!valdni.test(item.dni) && this.globals.idioma == 'es') {
          swal(this.globals.Literal('Error'), this.globals.Literal('Error DNI')+' '+this.globals.Literal('acompañante')+' '+(i-1), 'error');
          return false;
        }
        if (!valdnipt.test(item.dni) && this.globals.idioma == 'pt') {
          swal(this.globals.Literal('Error'), this.globals.Literal('Error DNI')+' '+this.globals.Literal('acompañante')+' '+(i-1), 'error');
          return false;
        }
        if (item.dni.toString().toLowerCase().trim() == this.data.dni.toString().toLowerCase().trim()) {
          swal(this.globals.Literal('DNI repetido acompañante')+' '+(i-1), this.globals.Literal('Mensaje repetido acompañante DNI'), 'error');
          return false;
        }
        let elems = this.data.reservas.filter(el => el.dni.toString().toLowerCase().trim() == item.dni.toString().toLowerCase().trim());
        if (elems.length > 1) {
          swal(this.globals.Literal('DNI repetido acompañante')+' '+(i-1), this.globals.Literal('Mensaje repetido acompañante DNI'), 'error');
          return false;
        }

        if (item.email != '') {
          if (!this.globals.ValidateEmail(item.email)) {
            swal(this.globals.Literal('Email acompañante')+' '+(i-1), this.globals.Literal('Mensaje acompañante'), 'error');
            return false;
          }
          if (item.email.toString().toLowerCase().trim() == this.data.email.toString().toLowerCase().trim()) {
            swal(this.globals.Literal('Email repetido acompañante')+' '+(i-1), this.globals.Literal('Mensaje repetido acompañante'), 'error');
            return false;
          }
          let elems = this.data.reservas.filter(el => el.email.toString().toLowerCase().trim() == item.email.toString().toLowerCase().trim());
          if (elems.length > 1) {
            swal(this.globals.Literal('Email repetido acompañante')+' '+(i-1), this.globals.Literal('Mensaje repetido acompañante'), 'error');
            return false;
          }
        }
      }

      i++;
    }
    return ok;
  }
  KeyDownTel(item:any = null) {
    if (item) {
      if (!item.telefono) item.telefono = '';
      item.telefono = item.telefono.toString().replace(/ /g, '');
      item.telefono = item.telefono.toString().replace(/\+/g, '');
      item.telefono = item.telefono.toString().replace(/\D/g, '');
    } else {
      if (!this.data.telefono) this.data.telefono = '';
      this.data.telefono = this.data.telefono.toString().replace(/ /g, '');
      this.data.telefono = this.data.telefono.toString().replace(/\+/g, '');
      this.data.telefono = this.data.telefono.toString().replace(/\D/g, '');
    }
  }

  Reservar() {
    if (this.globals.idioma == 'es') {
      this.data.provincia = $('#lbllocalidad').text().split(', ')[1];
      this.data.localidad = $('#lbllocalidad').text().split(', ')[0];
    } else {
      this.data.provincia = $('#lbllocalidad').val().split(' - ')[0];
      this.data.localidad = $('#lbllocalidad').val().split(' - ')[1];
    }
    if (
         this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.codpostal == ''
      || this.data.localidad == ''
      || this.data.provincia == ''
      || this.data.telefono == ''
      || this.data.email == ''
      || this.data.dni == ''
      || this.data.sexo == ''
      || !this.data.nombre
      || !this.data.apellidos
      || !this.data.codpostal
      || !this.data.localidad
      || !this.data.provincia
      || !this.data.telefono
      || !this.data.email
      || !this.data.dni
      || !this.data.sexo
      ) {
      swal(this.globals.Literal('Complete campos'), this.globals.Literal('Mensaje Rellene datos'), 'error');return;
    }
    if (this.globals.idioma == 'es') {
      if (this.data.codpostal.length != 5) {
        swal(this.globals.Literal('Código postal no valido'), '', 'error');return;
      }
    } else {
      if (this.data.codpostal.length != 8) {
        swal(this.globals.Literal('Código postal no valido'), '', 'error');return;
      }
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal(this.globals.Literal('Email no valido'), this.globals.Literal('Mensaje no valido'), 'error');return;
    }
    //Validar DNI
    this.data.dni = this.data.dni.toString().toUpperCase().trim();
    let valdni = /^[XYZ]?\d{5,8}[A-Z]$/;
    let valdnipt = /^[\d]{8}[-]?\d([A-Z]{2}\d)?$/;
    if (!valdni.test(this.data.dni) && this.globals.idioma == 'es') {
      swal(this.globals.Literal('Error'), this.globals.Literal('Error DNI'), 'error');return;
    }
    // if (!valdnipt.test(this.data.dni) && this.globals.idioma == 'pt') {
    //   swal(this.globals.Literal('Error'), this.globals.Literal('Error DNI'), 'error');return;
    // }

    if (!this.AddPersonas()) return;

    if (!this.data.consentimiento_fotos) {
      swal(this.globals.Literal('Consentimiento de imagen'), this.globals.Literal('Mensaje consentimiento de imagen'), 'error');return;
    }
    if (!this.data.aceptar_politica) {
      swal(this.globals.Literal('Aceptar condiciones'), this.globals.Literal('Mensaje aceptar condiciones'), 'error');return;
    }

    if (this.data.prefijo_telefono && this.data.prefijo_telefono != '') {
      this.data.telefono = this.data.prefijo_telefono + this.data.telefono;
      for (let item of this.data.reservas) {
        if (!item.telefono || item.telefono == '') continue;
        item.telefono = this.data.prefijo_telefono + item.telefono;
      }
    }

    this.data.id_actividad = this.data.id;
    this.data.idioma = this.globals.idioma;
    
    $.buttonloading('btnReservar', 'Enviando');
    this.globals.Api('/act-reservar', this.data).subscribe(data => {
      this.ReestablecerTelefonos();
      $.buttoncancelloading('btnReservar');
      if (!data || data.error) {
        if (data.value == 'Email repetido') {
          swal(this.globals.Literal('Email existente'), this.globals.Literal('Mensaje existente'), 'error');return;
        }
        if (data.value == 'DNI repetido') {
          swal(this.globals.Literal('DNI existente'), this.globals.Literal('Mensaje existente DNI'), 'error');return;
        }
        if (data.value == '1 email repetido') {
          swal(this.globals.Literal('Email existente acompañante')+' 1', this.globals.Literal('Mensaje existente'), 'error');return;
        }
        if (data.value == '2 email repetido') {
          swal(this.globals.Literal('Email existente acompañante')+' 2', this.globals.Literal('Mensaje existente'), 'error');return;
        }
        if (data.value == '3 email repetido') {
          swal(this.globals.Literal('Email existente acompañante')+' 3', this.globals.Literal('Mensaje existente'), 'error');return;
        }
        if (data.value == '4 email repetido') {
          swal(this.globals.Literal('Email existente acompañante')+' 4', this.globals.Literal('Mensaje existente'), 'error');return;
        }
        if (data.value == '1 dni repetido') {
          swal(this.globals.Literal('DNI existente')+' '+this.globals.Literal('acompañante')+' 1', this.globals.Literal('Mensaje existente DNI'), 'error');return;
        }
        if (data.value == '2 dni repetido') {
          swal(this.globals.Literal('DNI existente')+' '+this.globals.Literal('acompañante')+' 2', this.globals.Literal('Mensaje existente DNI'), 'error');return;
        }
        if (data.value == '3 dni repetido') {
          swal(this.globals.Literal('DNI existente')+' '+this.globals.Literal('acompañante')+' 3', this.globals.Literal('Mensaje existente DNI'), 'error');return;
        }
        if (data.value == '4 dni repetido') {
          swal(this.globals.Literal('DNI existente')+' '+this.globals.Literal('acompañante')+' 4', this.globals.Literal('Mensaje existente DNI'), 'error');return;
        }
        if (data.value == 'Max reservas') {
          swal(this.globals.Literal('Max reservas'), this.globals.Literal('Mensaje max reservas'), 'error');return;
        }
        if (data.value == 'Max reservas acompanante') {
          swal(this.globals.Literal('Max reservas'), this.globals.Literal('Mensaje max reservas acompanante'), 'error');return;
        }
        if (data.value == 'Aforo completado') {
          swal(this.globals.Literal('Aforo completo'), this.globals.Literal('Mensaje aforo completo'), 'error');return;
        }
        swal(this.globals.Literal('Error'), this.globals.Literal('Mensaje error'), 'error');return;
      }
      this.reservado = true;
      this.appref.tick();
    }, error => {
      this.ReestablecerTelefonos();
      swal(this.globals.Literal('Error'), this.globals.Literal('Mensaje error'), 'error');return;
      $.buttoncancelloading('btnReservar');
    });
  }

  ReestablecerTelefonos() {
    if (this.data.prefijo_telefono && this.data.prefijo_telefono != '') {
      this.data.telefono = this.data.telefono.toString().replace(this.data.prefijo_telefono, '');
      for (let item of this.data.reservas) {
        if (!item.telefono || item.telefono == '') continue;
        item.telefono = item.telefono.toString().replace(this.data.prefijo_telefono, '');
      }
    }
  }
}